import React, { SyntheticEvent, useEffect, useState } from 'react';
import { produce } from 'immer';
import { ITicket } from '@soluticket/shared';
import {
  DEFAULT_GRID_COLUMNS,
  DEFAULT_GRID_ROWS,
  emptySeat,
  generateLetters,
  generateDuplicatedLetters,
  generateNumbers,
  initialGrid,
  ALPHABET,
} from './grid.utils';
import GridActions, { RowsRangeType, SeatsDirectionType } from './GridActions';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Modal,
  TextField,
  Typography,
} from '@mui/material';
import RowActionsModal from './RowActions';

type CreateGridProps = {
  existingGrid?: Partial<ITicket>[][];
  onSave: (grid: Partial<ITicket>[][]) => void;
};

const MAX_ZOOM = 2;
const MIN_ZOOM = 0.4;
const ZOOM_SPEED_BTN = 0.1;

const CreateGrid: React.FC<CreateGridProps> = ({ onSave, existingGrid }) => {
  const [rowIdToEdit, setRowIdToEdit] = useState<number | null>(null);
  const [dragging, setDragging] = useState(false);
  const [startPos, setStartPos] = useState({ x: 0, y: 0 });
  const [translate, setTranslate] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [[numRows, numCols], setRowCols] = useState<[number, number]>(() => {
    if (existingGrid?.length) {
      return [existingGrid.length, existingGrid[0].length];
    } else {
      return [DEFAULT_GRID_ROWS, DEFAULT_GRID_COLUMNS];
    }
  });
  const [grid, setGrid] = useState<Partial<ITicket>[][]>(() => {
    if (existingGrid?.length) {
      return existingGrid;
    } else {
      return initialGrid(numRows, numCols);
    }
  });
  const [rowsIdentifiers, setRowsIdentifiers] = useState<
    Array<string | number>
  >(() => {
    if (existingGrid?.length) {
      return existingGrid.map((row) => row[0].label || '');
    } else {
      return [];
    }
  });
  // Will handle right click to edit single row
  const [rowColSelected, setRowColSelected] = useState<[number, number]>([
    0, 0,
  ]);

  // Update grid
  useEffect(() => {
    setGrid((prevGrid) => {
      const nextGrid = produce(prevGrid, (draftGrid) => {
        // column is defined by [[].length]
        // row is defined by [].length

        // if numCols > [[].length] , push another element
        // else if numCols < [[].length], pop an element
        const currentCols = draftGrid[0].length;
        if (numCols > currentCols) {
          draftGrid.forEach((row) => {
            row.push(emptySeat);
          });
        } else if (numCols < currentCols) {
          draftGrid.forEach((row) => {
            row.pop();
          });
        }

        // if numRows > [].length , push
        // else if numRows < [].length, pop
        const currentRows = draftGrid.length;
        if (numRows > currentRows) {
          draftGrid.push(Array.from(Array(numCols), () => emptySeat));
        } else if (numRows < currentRows) {
          draftGrid.pop();
        }
      });

      return nextGrid;
    });
  }, [numRows, numCols]);

  /**
   * TODO EV: Enable actions to update grid
   * Maybe add buttons to add columns and rows or debounce input
   */

  /**
   * Reset reset grid
   */
  const resetGrid = () => {
    setRowCols([DEFAULT_GRID_ROWS, DEFAULT_GRID_COLUMNS]);
    setGrid(initialGrid(DEFAULT_GRID_ROWS, DEFAULT_GRID_COLUMNS));
  };

  /**
   * Assign names of each row.
   * e.g.: A,B,C,D,E,F
   */
  const assignIdentifierToRows = (rowsRange: RowsRangeType) => {
    let rowsContent: Array<string | number> = [];

    if (rowsRange === 'abc') {
      rowsContent = generateLetters(0, grid.length);
    } else if (rowsRange === '123') {
      rowsContent = generateNumbers(1, grid.length);
    } else {
      rowsContent = generateDuplicatedLetters(0, grid.length);
    }

    // Reverse because the scenario is on the bottom.
    setRowsIdentifiers(rowsContent.reverse());

    const newGrid = produce(grid, (draftGrid) => {
      grid.forEach((rows, row) => {
        rows.forEach(() => {
          draftGrid[row][0] = {
            ...draftGrid[row][0],
            label: `${rowsContent[row] || ''}`,
          };
        });
      });
    });

    setGrid(newGrid);
  };

  /**
   * Asign each seat number and letter
   * e.g.: A-1, A-2, A-3, A-4...
   */
  const assignSeatsNumber = (seatsDirection: SeatsDirectionType) => {
    const isLeftToRight = seatsDirection === 'leftToRight';
    const numOfColumns = grid[0].length - 1;
    const newGrid = produce(grid, (draftGrid) => {
      grid.forEach((rows, row) => {
        // This will skip not seat columns. Use {col} to not skip.
        let startNumber = 1;
        rows.forEach((_, col) => {
          const columnPosition = isLeftToRight ? col : numOfColumns - col;
          const item = draftGrid[row][columnPosition];

          if (item.isSeat) {
            const colLabel = isLeftToRight ? startNumber : startNumber + 1;
            item.label = `${rowsIdentifiers[row] || ''}-${colLabel}`;
            startNumber++;
          }
        });
      });
    });

    setGrid(newGrid);
  };

  const toggleSeat = (row: number, col: number) => {
    const newGrid = produce(grid, (gridCopy) => {
      gridCopy[row][col] = grid[row][col].isSeat
        ? { ...grid[row][col], isSeat: false }
        : { ...grid[row][col], isSeat: true };
    });
    setGrid(newGrid);
  };

  const addOrRemoveRow = (add: boolean = true) => {
    const newRowsLength = add ? numRows + 1 : numRows - 1;
    if (!add && newRowsLength <= 0) {
      return;
    }
    setRowCols([newRowsLength, numCols]);
  };

  const addOrRemoveColumn = (add: boolean = true) => {
    const newColumnsLength = add ? numCols + 1 : numCols - 1;
    if (!add && newColumnsLength <= 0) {
      return;
    }
    setRowCols([numRows, newColumnsLength]);
  };

  const assignIdentifiersToRow = (params: {
    seatsDirection: SeatsDirectionType;
    startNumber: number;
    rowColSelected: [number, number];
    fullRow: boolean; // If true, will assign all seats in the row. else, will skip not seat columns.
  }) => {
    let [rowSelected, colSelected] = rowColSelected;
    const isLeftToRight = params.seatsDirection === 'leftToRight';

    if (params.fullRow) {
      colSelected = isLeftToRight ? 1 : grid[0].length - 1;
    }

    const newGrid = produce(grid, (gridDraft) => {
      // This cover the scenario when the users modified a row indetifier.
      gridDraft[rowSelected][0] = {
        ...gridDraft[rowSelected][0],
        label: `${rowsIdentifiers[rowSelected] || ''}`,
      };

      while (
        isLeftToRight ? colSelected < grid[rowSelected].length : colSelected > 0
      ) {
        const item = gridDraft[rowSelected][colSelected];

        if (params.fullRow) {
          item.isSeat = true;
        }

        if (item.isSeat) {
          item.label = `${rowsIdentifiers[rowSelected] || ''}-${
            params.startNumber
          }`;
          params.startNumber++;
        } else {
          item.label = '';
        }

        colSelected = isLeftToRight ? colSelected + 1 : colSelected - 1;
      }
    });

    setGrid(newGrid);
    setRowColSelected([0, 0]); // reset
  };

  const editRowIdentifier = (evt: any) => {
    evt.preventDefault();
    if (rowIdToEdit === null) return;

    const form = evt.target;
    const formData = new FormData(form);
    const formJson = Object.fromEntries(formData.entries());

    if (formJson.name === '') return;

    const startIdentifier = formJson.name.toString().toUpperCase();
    const fullColumn = formJson.fullColumn === 'on';

    const newRowIdentifiers = produce(rowsIdentifiers, (drafState) => {
      if (!fullColumn) {
        drafState[rowIdToEdit] = startIdentifier;
      } else {
        const letterIndex = ALPHABET.indexOf(startIdentifier);
        const nextLetters = generateLetters(
          letterIndex,
          letterIndex + rowIdToEdit + 1
        );
        nextLetters.forEach((letter, index) => {
          drafState[rowIdToEdit - index] = letter;
        });
      }
    });

    setRowsIdentifiers(newRowIdentifiers);

    const newGrid = produce(grid, (draftGrid) => {
      newRowIdentifiers.forEach((letter, index) => {
        const currentRow = draftGrid[index];
        // [0] is the first column, where the row identifier is.
        currentRow[0] = {
          ...draftGrid[index][0],
          label: `${letter}`,
        };

        for (let i = 1; i < currentRow.length; i++) {
          if (currentRow[i].isSeat) {
            currentRow[i].label = `${letter}-${i}`;
          }
        }
      });
    });
    setGrid(newGrid);
    setRowIdToEdit(null);
  };

  const handleZoomIn = () => {
    if (zoom < MAX_ZOOM) {
      setZoom(zoom + ZOOM_SPEED_BTN);
    }
  };

  const handleZoomOut = () => {
    if (zoom > MIN_ZOOM) {
      setZoom(zoom - ZOOM_SPEED_BTN);
    }
  };

  const handleMouseDown = (e: any) => {
    setDragging(true);
    setStartPos({ x: e.clientX, y: e.clientY });
  };

  const handleMouseMove = (e: any) => {
    if (!dragging) return;
    setTranslate({
      x: translate.x + e.clientX - startPos.x,
      y: translate.y + e.clientY - startPos.y,
    });
    setStartPos({ x: e.clientX, y: e.clientY });
  };

  const handleMouseUp = () => {
    setDragging(false);
  };

  const handleMouseLeave = () => {
    setDragging(false);
  };

  return (
    <Grid container direction='column' rowSpacing={5}>
      <GridActions
        numRows={numRows}
        numCols={numCols}
        onAssignIdentifierToRows={assignIdentifierToRows}
        onAssignSeatsNumber={assignSeatsNumber}
        addOrRemoveColumn={addOrRemoveColumn}
        addOrRemoveRow={addOrRemoveRow}
        onReset={resetGrid}
        onSave={() => onSave(grid)}
      />
      <Grid item>
        <Typography component='p'>
          Dar click derecho a cualquier elemento en la sección de asientos, para
          habilitar más opciones.
        </Typography>
        <Typography component='p'>
          Dar click izquierdo sobre primera columna(color rojo) para modificar
          el nombre de la fila.
        </Typography>
        <button onClick={handleZoomIn}>Zoom In</button>
        <button onClick={handleZoomOut}>Zoom Out</button>
        <button
          onClick={() => {
            setZoom(1);
          }}
        >
          Reiniciar zoom
        </button>
        <button
          onClick={() => {
            setTranslate({
              x: 0,
              y: 0,
            });
          }}
        >
          Centrar Mapa
        </button>
      </Grid>
      <div
        style={{
          width: '100%',
          height: '500px',
          overflow: 'hidden',
          position: 'relative',
        }}
      >
        <div
          style={{
            width: '100%',
            height: '100%',
            position: 'absolute',
            transform: `scale(${zoom}) translate(${translate.x}px, ${translate.y}px)`,
            transformOrigin: 'top left',
            cursor: dragging ? 'grabbing' : 'grab',
          }}
          className='p-2'
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onMouseLeave={handleMouseLeave}
        >
          <svg
            style={{
              transform: `scale(${zoom})`,
              transformOrigin: '0 0', // this fixes the scroll position
              transition: 'ease .3s',
              height: '100%',
              width: 'auto',
            }}
            viewBox={`0 0 ${grid[0].length * 40} ${grid.length * 40}`}
          >
            {grid.map((rows, row) => (
              <g key={row}>
                {rows.map((ticket, col) => {
                  const seatId = `${row}${col}`;
                  const x = col * 40;
                  const y = row * 40;
                  const width = 25;
                  const height = 25;
                  return (
                    <g key={seatId}>
                      <rect
                        style={{
                          fill: ticket.isSeat ? '#60a5fa' : '#fff',
                        }}
                        stroke='#ccc'
                        x={x}
                        y={y}
                        width={width}
                        height={height}
                        rx='4'
                        onContextMenu={(event: SyntheticEvent) => {
                          event.preventDefault();

                          if (col > 0) {
                            setRowColSelected([row, col]);
                          } else {
                            setRowIdToEdit(row);
                          }
                        }}
                        onMouseDown={(event: any) => {
                          if (event.nativeEvent.which !== 1) {
                            return;
                          }
                          // 1 === left click
                          if (col !== 0 && !dragging) {
                            toggleSeat(row, col);
                          }
                        }}
                      />
                      <text
                        x={x + width / 2}
                        y={y + height / 2}
                        style={{
                          cursor: 'pointer',
                          userSelect: 'none',
                          dominantBaseline: 'middle',
                          textAnchor: 'middle',
                          fontSize: '10px',
                        }}
                        fill='black'
                        onContextMenu={(event: SyntheticEvent) => {
                          event.preventDefault();

                          if (col > 0) {
                            setRowColSelected([row, col]);
                          } else {
                            setRowIdToEdit(row);
                          }
                        }}
                        onMouseDown={(event: any) => {
                          if (event.nativeEvent.which !== 1) {
                            return;
                          }
                          // 1 === left click
                          if (col !== 0 && !dragging) {
                            toggleSeat(row, col);
                          }
                        }}
                      >
                        {ticket.label}
                      </text>
                    </g>
                  );
                })}
              </g>
            ))}
          </svg>
        </div>
      </div>

      <RowActionsModal
        open={rowColSelected[1] > 0}
        onClose={() => setRowColSelected([0, 0])}
        aria-labelledby='update seats'
        row={rowsIdentifiers[rowColSelected[0]]}
        col={rowColSelected[1]}
        onAssignIdentifiersToRow={(params) =>
          assignIdentifiersToRow({ ...params, rowColSelected })
        }
      />
      <Modal open={rowIdToEdit !== null} onClose={() => setRowIdToEdit(null)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
          }}
        >
          <Grid container spacing={2}>
            <form
              onSubmit={editRowIdentifier}
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: '1rem',
                width: '100%',
              }}
            >
              <TextField
                fullWidth
                name='name'
                label='Nuevo nombre de la fila'
              />
              <FormControlLabel
                control={<Checkbox name='fullColumn' />}
                label='Asignar toda la fila'
              />
              <div style={{ display: 'flex', gap: '1rem' }}>
                <Button variant='contained' color='primary' type='submit'>
                  Actualizar
                </Button>
                <Button
                  variant='contained'
                  color='secondary'
                  onClick={() => {
                    setRowIdToEdit(null);
                  }}
                >
                  Cerrar
                </Button>
              </div>
            </form>
          </Grid>
        </Box>
      </Modal>
    </Grid>
  );
};

export default CreateGrid;
