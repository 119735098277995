import { TransactionStatus } from '../enums';
import { z } from 'zod';

export const requestTransferDTO = z.object({
  amount: z.number().min(1),
  type: z.enum(['DEPOSIT', 'CASH']),
  bankAccountId: z.number().min(1).optional(),
});
export type requestTransferType = z.infer<typeof requestTransferDTO>;

export const filterSalesDTO = z.object({
  startDate: z.string(),
  endDate: z.string(),
  status: z.nativeEnum(TransactionStatus),
  limit: z.string().min(1),
  page: z.string().min(1),
});
export type filterSalesType = z.infer<typeof filterSalesDTO>;
