import { FieldProps, getIn } from 'formik';
import React from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from '@mui/material';

const SelectFormField: React.FC<
  FieldProps & {
    label?: string;
    options: Array<{ label: string; value: string }>;
  }
> = ({ field, form, label, options, ...props }) => {
  const errorText =
    getIn(form.touched, field.name) && getIn(form.errors, field.name);

  // style={{ backgroundColor: 'white' }}. Fixes label overlapping border!
  return (
    <FormControl
      fullWidth
      error={!!errorText}
      style={{ marginTop: '16px', marginBottom: '8px' }}
    >
      {label && (
        <InputLabel style={{ backgroundColor: 'white' }}>{label}</InputLabel>
      )}
      <Select {...field} {...props}>
        <MenuItem key='emptyKey' value='0'>
          Selecciona una opción
        </MenuItem>
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{errorText}</FormHelperText>
    </FormControl>
  );
};

export default SelectFormField;
