import SimpleTabs, { SingleSimpleTab } from '../../components/SimpleTabs';
import Coupons from './Coupons';
import ModifyTickets from './ModifyTickets';
import Reservations from './ReservedTickets';
import CreateTicketType from './CreateTicketType';
import Courtesies from './Courtesies';

const tabs: Array<SingleSimpleTab> = [
  {
    label: 'Crear boletos',
    component: <CreateTicketType />,
  },
  {
    label: 'Cupones',
    component: <Coupons />,
  },
  {
    label: 'Modificar boletos',
    component: <ModifyTickets />,
  },
  {
    label: 'Boletos reservados',
    component: <Reservations />,
  },
  {
    label: 'Cortesias',
    component: <Courtesies />,
  },
];

const Tickets: React.FC<{}> = () => {
  return <SimpleTabs tabs={tabs} />;
};

export default Tickets;
