import { createTheme } from '@mui/material/styles';
// it could be your App.tsx file or theme file that is included in your tsconfig.json
import { Theme } from '@mui/material/styles';

// @ts-ignore
declare module '@mui/styles/defaultTheme' {
  // @ts-ignore
  interface DefaultTheme extends Theme {}
}

export const theme = createTheme({
  palette: {
    primary: {
      main: '#2b2d42',
    },
    secondary: {
      main: '#edf2f4',
    },
  },
});
