import React, { useRef } from 'react';
import { Field, Form, Formik, FormikProps } from 'formik';
import {
  Button,
  Grid,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
} from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import { getZonesIds } from '@soluticket/shared';
import { parse } from 'svg-parser';
import LoadingButton from '@mui/lab/LoadingButton';
import InfoIcon from '@mui/icons-material/Info';
import { Link } from '@tanstack/react-location';
import { Card } from '../../components';
import { UI_MESSAGES } from '../../constants';
import { venueSchema } from '../../forms/validation.schemas';
import { createVenue } from '../../http/Mutations';
import { fetchVenues, queriesKeys, queryClient } from '../../http/Queries';
import { useNotificationStore } from '../../store/notification.store';
import { formatHttpError } from '../../utils';
import TextFormField from '../../components/Form/TextFormField';
import FileFormField from '../../components/Form/FileFormField';

const CreateVenue: React.FC = () => {
  const { handleNotification } = useNotificationStore((state) => state);
  const [zonesFoundInSVG, setZonesFoundInSVG] = React.useState<string[]>([]);
  const mCreate = useMutation(createVenue, {
    onSuccess: () => {
      handleNotification(UI_MESSAGES.SUCCESS, 200);
      queryClient.invalidateQueries(queriesKeys.fetchVenues());
      formRef.current?.resetForm();
    },
    onError: (error: any) => {
      const message = formatHttpError(error);
      handleNotification(message, 400);
    },
  });
  const qVenues = useQuery(queriesKeys.fetchVenues(), fetchVenues);

  // const mRemove = useMutation(removeVenueById);

  const formRef = useRef<FormikProps<{}>>(null);

  const submitHandler = async (values: any) => {
    console.log(values);
    const payload = new FormData();
    for (const key in values) {
      payload.append(key, values[key]);
    }
    mCreate.mutate(payload);
  };

  const cancelHandler = () => {
    setZonesFoundInSVG([]);
    formRef.current?.resetForm();
  };

  // const removeVenueHandler = async () => {
  //   setShowConfirmDialog(false);
  //   await mRemove.mutateAsync(selectedVenueId!);
  //   queryClient.invalidateQueries(queriesKeys.fetchVenues());
  // };

  return (
    <Card title='Recintos'>
      <Grid container columnSpacing={2}>
        <Grid item xs={12} md={4}>
          <Formik
            initialValues={{
              name: '',
              imageUrl: '',
            }}
            innerRef={formRef}
            onSubmit={submitHandler}
            validationSchema={venueSchema}
          >
            {({ isValid }) => (
              <Form>
                <Field
                  name='name'
                  label='Nombre del recinto'
                  component={TextFormField}
                />
                <Field
                  name='imageUrl'
                  label='Recinto SVG'
                  component={FileFormField}
                  onChangeCustom={(event: any) => {
                    const file = event.target.files[0];
                    const reader = new FileReader();
                    reader.addEventListener('load', (event) => {
                      const svgString = event.target?.result as string;
                      if (!svgString) return;
                      const parsed = parse(svgString) as any;
                      const zones = getZonesIds(parsed);
                      setZonesFoundInSVG(zones);
                    });
                    reader.readAsText(file);
                  }}
                />
                <Grid container item mt={2} columnSpacing={3} xs={12}>
                  <Grid item>
                    <Button
                      variant='contained'
                      color='secondary'
                      onClick={cancelHandler}
                    >
                      Cancelar
                    </Button>
                  </Grid>
                  <Grid item>
                    <LoadingButton
                      loading={mCreate.isLoading}
                      disabled={!isValid}
                      variant='contained'
                      color='primary'
                      type='submit'
                    >
                      Guardar
                    </LoadingButton>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
          {zonesFoundInSVG.length > 0 && (
            <div>
              <div>Zonas encontradas en el SVG</div>
              <div>
                {zonesFoundInSVG.map((zone, idx) => {
                  return (
                    <div key={zone}>
                      {idx + 1} - {zone}
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </Grid>
        <Grid item xs={12} md={8}>
          <ImageList>
            {!!qVenues.data &&
              qVenues.data.map((item) => (
                <ImageListItem key={item.id}>
                  <img
                    src={item.imageUrl}
                    srcSet={item.imageUrl}
                    alt={item.name}
                    loading='lazy'
                  />
                  <ImageListItemBar
                    title={item.name}
                    actionIcon={
                      <Link to={`edit/${item.id}`}>
                        <IconButton
                          sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                          aria-label={`info about ${item.name}`}
                        >
                          <InfoIcon />
                        </IconButton>
                      </Link>
                    }
                  />
                </ImageListItem>
              ))}
          </ImageList>
        </Grid>
      </Grid>
    </Card>
  );
};

export default CreateVenue;
