import { Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';

type ThumbProps = {
  file: File;
  existingFileUrl?: string;
  onRemove: () => void;
};

const useStyles = makeStyles(() => ({
  container: {
    position: 'relative',
  },
  delete: {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 1,
    cursor: 'pointer',
    padding: '5px',
    backgroundColor: 'rgba(175, 171, 171, .75)',
    borderRadius: '7px',
    color: 'white',
  },
  thumb: {
    // transform: 'scale(0.2)',
    width: '50%',
    border: '5px solid lightgray',
  },
}));

const Thumb: React.FC<ThumbProps> = ({ file, existingFileUrl, onRemove }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [thumb, setThumb] = useState<string | ArrayBuffer | null>(null);

  useEffect(() => {
    if (file) {
      setLoading(true);
      const reader = new FileReader();

      reader.onload = () => {
        setThumb(reader.result);
        setLoading(false);
      };

      reader.readAsDataURL(file);
    }
  }, [file]);

  if (!file && !existingFileUrl) return null;

  const imageSrc = thumb ? String(thumb) : existingFileUrl;

  return (
    <Grid width='100%' height='100%' className={classes.container}>
      <div className={classes.delete} onClick={onRemove}>
        X
      </div>
      {loading && <Typography>Cargando</Typography>}
      <img src={imageSrc} alt='mainImage' className={classes.thumb} />
    </Grid>
  );
};

export default Thumb;
