import { z } from 'zod';

export const createBankAccountDTO = z.object({
  id: z.number().nullish().or(z.literal(0)),
  businessName: z.string().min(5).max(50).trim(),
  fiscalAddress: z.string().min(5).max(50).trim(),
  clabe: z.string().min(5).max(50).trim(),
  accountNumber: z.string().min(5).max(50).trim(),
  name: z.string().min(5).max(50).trim(),
});

export type createBankAccountType = z.infer<typeof createBankAccountDTO>;
