import { InputAdornment, TextField } from '@mui/material';
import { FieldProps, getIn } from 'formik';

const CurrencyFormField: React.FC<FieldProps> = ({ field, form, ...props }) => {
  const errorText =
    getIn(form.touched, field.name) && getIn(form.errors, field.name);

  return (
    <TextField
      fullWidth
      margin='normal'
      helperText={errorText}
      error={!!errorText}
      {...field}
      {...props}
      InputProps={{
        startAdornment: <InputAdornment position='start'>$</InputAdornment>,
      }}
      onChange={(event) => {
        let value: string | number = Number(event.target.value);
        // is Ok to display invalid value..
        // yup validation will catch it.
        if (isNaN(value)) {
          value = event.target.value;
        }
        form.setFieldValue(field.name, value);
      }}
    />
  );
};
export default CurrencyFormField;
