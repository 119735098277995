import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { useNotificationStore } from '../store/notification.store';
import 'react-toastify/dist/ReactToastify.min.css';

const toastConstants = {
  position: toast.POSITION.TOP_CENTER,
};

const NotificationHandler: React.FC = () => {
  const { notification, removeNotification } = useNotificationStore(
    (state) => state
  );

  React.useEffect(() => {
    if (notification.code === 200) {
      toast.success(notification.msg, toastConstants);
      removeNotification();
    } else if (
      notification.code &&
      notification.code >= 400 &&
      notification.code < 500
    ) {
      toast.error(notification.msg, toastConstants);
      removeNotification();
    }
  }, [notification, removeNotification]);

  return <ToastContainer autoClose={1000} />;
};

export default NotificationHandler;
