import { FieldProps, getIn } from 'formik';
import React from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Checkbox,
  ListItemText,
  OutlinedInput,
} from '@mui/material';
import { Option } from '@soluticket/shared';

const formControlStyle = { marginTop: '16px', marginBottom: '8px' };
// style={{ backgroundColor: 'white' }}. Fixes label overlapping border!
const inputLabelStyle = { backgroundColor: 'white' };

/**
 * Multi select component, the value returned to formik is a string of labels
 * be carefull with this, because is not the value(the ids!)
 */
const MultiSelectFormField: React.FC<
  FieldProps & {
    label?: string;
    options: Array<Option>;
  }
> = ({ field, form, label, options, ...props }) => {
  const errorText =
    getIn(form.touched, field.name) && getIn(form.errors, field.name);

  return (
    <FormControl fullWidth error={!!errorText} style={formControlStyle}>
      {label && (
        <InputLabel id={field.name} style={inputLabelStyle}>
          {label}
        </InputLabel>
      )}
      <Select
        {...field}
        {...props}
        multiple
        labelId={field.name}
        value={field.value}
        input={<OutlinedInput label={label} />}
        renderValue={(selected: Array<string | number>) => {
          // El error son los tipos de datos.
          // si recibes integers, formik los va a cambiar a strings.
          if (selected.length === 0) {
            return '';
          }
          // selected = [number, number] // option.value
          const selectedValues = new Set(selected);
          const filtered = options
            .filter((option) => selectedValues.has(option.value))
            .map((option) => option.label);
          return filtered.join(', ');
        }}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            <Checkbox checked={field.value.indexOf(option.value) > -1} />
            <ListItemText primary={option.label} />
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{errorText}</FormHelperText>
    </FormControl>
  );
};

export default MultiSelectFormField;
