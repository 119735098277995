import { z } from 'zod';

export const readGiftIdDTO = z.object({
  owner: z.string().min(3).max(50),
  urlGiftId: z.string().min(3).max(50),
});
export type readGiftIdType = z.infer<typeof readGiftIdDTO>;

export const readQRDTO = z.object({
  QRCode: z.string().min(3).max(150),
  ticketTypesAllowed: z.array(z.number()),
});
export type readQRType = z.infer<typeof readQRDTO>;

export const workerLoginDesktopDTO = z.object({
  username: z.string().min(3).max(50),
  password: z.string().min(6).max(20),
});
export type workerLoginDesktopType = z.infer<typeof workerLoginDesktopDTO>;

export const workerLoginAppDTO = z.object({
  accessCode: z.string(),
});
export type workerLoginAppType = z.infer<typeof workerLoginAppDTO>;

export const cashClosingDTO = z.object({
  cash: z.number().min(0),
  cashClosingId: z.number().min(1).optional(),
});
export type cashClosingType = z.infer<typeof cashClosingDTO>;

export const updateTicketDTO = z.object({
  ticketId: z.number().min(1),
  updatedAt: z.string().min(1),
  exceptionDetail: z.string().nullish().or(z.literal('')),
});
export type updateTicketType = z.infer<typeof updateTicketDTO>;

export const updateTicketsDTO = z.object({
  tickets: z.array(updateTicketDTO),
});
export type updateTicketsType = z.infer<typeof updateTicketsDTO>;

export const createVenueDTO = z.object({
  name: z.string().min(1),
});
export type createVenueType = z.infer<typeof createVenueDTO>;

export const fetchCourtesyDTO = z.object({
  giftCode: z.string().min(1),
});
export type fetchCourtesyDTO = z.infer<typeof fetchCourtesyDTO>;

export const claimCourtesyDTO = z.object({
  giftCode: z.string().min(1),
  cashClosingId: z.number().min(1),
});
export type claimCourtesyDTO = z.infer<typeof claimCourtesyDTO>;

export const fetchSalesOperationsDTO = z.object({
  cashClosingId: z.number().min(1),
  transactionId: z.number().min(1).optional(),
});
export type fetchSalesOperationsType = z.infer<typeof fetchSalesOperationsDTO>;

export const fetchWithdrawalsOperationsDTO = z.object({
  cashClosingId: z.number().min(1),
});
export type fetchWithdrawalsOperationsType = z.infer<
  typeof fetchWithdrawalsOperationsDTO
>;

export const cancelTransactionDTO = z.object({
  transactionId: z.number().min(1),
});
export type cancelTransactionType = z.infer<typeof cancelTransactionDTO>;

export const cashWithdrawalDTO = z.object({
  amount: z.number().min(1),
  description: z.string().min(1),
  cashClosingId: z.number().min(1),
});
export type cashWithdrawalType = z.infer<typeof cashWithdrawalDTO>;

export const reprintTicketDTO = z.object({
  reprintCode: z.string().min(1),
});
