import React, { useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import usePrevious from '../hooks/usePrevious';
import { useMatch, useNavigate } from '@tanstack/react-location';

type TabPanelProps = {
  children: React.ReactNode;
  index: number;
  value: number;
};

const TabPanel: React.FC<TabPanelProps> = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box style={{ backgroundColor: '#fafafa', padding: '10px 0' }} p={3}>
          {children}
        </Box>
      )}
    </div>
  );
};

const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

export type SingleSimpleTab = {
  label: string;
  component: JSX.Element;
  icon?: JSX.Element;
};

export type SimpleTabsProps = {
  tabs: Array<SingleSimpleTab>;
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  wrapped: {
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  labelIcon: {
    minHeight: 0,
  },
  textColorSecondary: {
    color: '#BCBDC3',
  },
}));

const tabPattern = new RegExp(/\/[0-9]/);
const domainPattern = new RegExp(/[/[a-z]*]*/);

const SimpleTabs: React.FC<SimpleTabsProps> = ({ tabs }) => {
  const classes = useStyles();
  const [tabIndex, setTabIndex] = React.useState(0);
  const navigate = useNavigate();
  const { pathname } = useMatch();
  const prevPath = usePrevious<string>(pathname);

  useEffect(() => {
    const tabIndexRoute = +pathname.replace(domainPattern, '');
    setTabIndex(isNaN(tabIndexRoute) ? 0 : tabIndexRoute);
  }, [pathname]);

  const handleChange = (_: React.ChangeEvent<{}>, newValue: number) => {
    setTabIndex(newValue);
    const relativeRoute = pathname.replace(tabPattern, ''); //event/sales + newvalue
    const nextRoute = `${relativeRoute}/${newValue}`;
    if (nextRoute !== prevPath) {
      navigate({
        to: `${relativeRoute}/${newValue}`,
      });
    }
  };

  return (
    <div className={classes.root}>
      <AppBar position='static'>
        <Tabs
          value={tabIndex}
          onChange={handleChange}
          aria-label='simple tabs example'
          variant='scrollable'
          scrollButtons='auto'
          textColor='secondary'
          indicatorColor='secondary'
        >
          {tabs.map((tab, idx) => (
            <Tab
              key={tab.label}
              classes={{
                wrapped: classes.wrapped,
                labelIcon: classes.labelIcon,
                textColorSecondary: classes.textColorSecondary,
              }}
              icon={tab.icon}
              label={tab.label}
              {...a11yProps(idx)}
            />
          ))}
        </Tabs>
      </AppBar>

      {tabs.map((tab, idx) => (
        <TabPanel
          key={tab.label}
          value={tabIndex}
          index={idx}
          children={tab.component}
        />
      ))}
    </div>
  );
};

export default SimpleTabs;
