import React from 'react';
import { IconButton, List, ListItem, ListItemText } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import DeleteIcon from '@mui/icons-material/Delete';
import { UI_MESSAGES } from '../constants';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    overflowY: 'auto',
  },
}));

type Item = {
  idx: number;
  primary: string;
  secondary: string;
};

export type SimpleListProps = {
  data: Array<Item>;
  maxHeight?: string;
  onRemove?: (idx: number) => void;
  onSelect?: (idx: number) => void;
};

const SimpleList: React.FC<SimpleListProps> = ({
  data,
  maxHeight,
  onRemove,
  onSelect,
}) => {
  const classes = useStyles();

  return (
    <List
      className={classes.root}
      style={{ maxHeight: maxHeight ?? undefined }}
    >
      {!data.length && <p>{UI_MESSAGES.NO_RECORDS}</p>}
      {data.map((item) => (
        <ListItem key={item.idx}>
          <ListItemText
            onClick={onSelect ? () => onSelect(item.idx) : undefined}
            style={{ cursor: 'pointer' }}
            primary={item.primary}
            secondary={item.secondary}
          />
          {onRemove && (
            <IconButton
              color='primary'
              aria-label='Borrar registro'
              component='span'
              onClick={() => {
                if (onRemove) {
                  onRemove(item.idx);
                }
              }}
              size='large'
            >
              <DeleteIcon />
            </IconButton>
          )}
        </ListItem>
      ))}
    </List>
  );
};

export default SimpleList;
