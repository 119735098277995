import React from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { fetchCourtesies, queriesKeys } from '../../http/Queries';
import { Button, Card, CardActions, CardContent, Grid } from '@mui/material';
import { Courtesy as CourtesyType } from '@soluticket/shared';
import { LoadingButton } from '@mui/lab';
import { cancelCourtesy, generateCourtesyLink } from '../../http/Mutations';
import { useNotificationStore } from '../../store/notification.store';
import { formatHttpError } from '../../utils';

type CourtesyProps = {
  courtesy: CourtesyType;
  isLoading: boolean;
  onCancel: (code: string) => void;
  onGenerateLink: (code: string) => void;
};
const Courtesy: React.FC<CourtesyProps> = ({
  courtesy,
  onCancel,
  onGenerateLink,
  isLoading,
}) => {
  const link = courtesy.tickets[0].link;

  let reservedFor = courtesy.tickets[0].reservedFor;
  const ticketTypeName = courtesy.tickets[0].TicketType.name;
  const isNumbered = courtesy.tickets[0].isSeat;
  const isTable = courtesy.tickets[0].TicketType.isTable;
  // remove repeated values
  const labelsSet = new Set(
    courtesy.tickets.map((ticket) =>
      isTable ? ticket.tableLabel || '' : ticket.label
    )
  );
  const labels = Array.from(labelsSet);

  return (
    <Grid item maxWidth='300px'>
      <Card>
        <CardContent>
          <h3>Código: {courtesy.giftCode}</h3>
          <div>
            <div>Cantidad de boletos: {courtesy.tickets?.length}</div>
            <div>Zona: {ticketTypeName}</div>
            {!!reservedFor && (
              <div>
                Cortesía para:{' '}
                <span style={{ fontWeight: 'bolder' }}>{reservedFor}</span>{' '}
              </div>
            )}
            {isNumbered && (
              <ul>
                {labels.map((label, idx) => (
                  <li key={idx}>
                    {isTable ? 'Mesa' : 'Asiento'}: {label}
                  </li>
                ))}
              </ul>
            )}
          </div>
        </CardContent>
        <CardActions>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '5px',
            }}
          >
            {!!link ? (
              <>
                <span>{link}</span>
                <Button
                  variant='contained'
                  onClick={() => {
                    navigator.clipboard.writeText(link);
                  }}
                >
                  Copiar enlance
                </Button>
              </>
            ) : (
              <LoadingButton
                onClick={() => void onGenerateLink(courtesy.giftCode)}
                variant='contained'
                loading={isLoading}
              >
                Generar enlance
              </LoadingButton>
            )}
            <LoadingButton
              variant='outlined'
              loading={isLoading}
              onClick={() => void onCancel(courtesy.giftCode)}
            >
              Cancelar
            </LoadingButton>
          </div>
        </CardActions>
      </Card>
    </Grid>
  );
};

const Courtesies: React.FC = () => {
  const handleNotification = useNotificationStore(
    (state) => state
  ).handleNotification;
  const queryClient = useQueryClient();
  const { mutate: mutateLink, isLoading: isLoadingLink } = useMutation(
    generateCourtesyLink,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queriesKeys.fetchCourtesies());
      },
      onError: (error: any) => {
        const message = formatHttpError(error);
        handleNotification(message, 400);
      },
    }
  );
  const { mutate, isLoading: isLoadingCancel } = useMutation(cancelCourtesy, {
    onSuccess: () => {
      queryClient.invalidateQueries(queriesKeys.fetchCourtesies());
    },
    onError: (error: any) => {
      const message = formatHttpError(error);
      handleNotification(message, 400);
    },
  });
  const { data, isLoading } = useQuery(
    queriesKeys.fetchCourtesies(),
    fetchCourtesies
  );

  return (
    <div>
      <h2>Cortesias</h2>
      <Grid container columnGap={2} rowGap={2}>
        {isLoading && <div>Cargando...</div>}
        {data?.map((courtesy) => (
          <Courtesy
            key={courtesy.giftCode}
            courtesy={courtesy}
            isLoading={isLoadingCancel || isLoadingLink}
            onCancel={(code: string) => void mutate({ code })}
            onGenerateLink={(code: string) => mutateLink(code)}
          />
        ))}
      </Grid>
    </div>
  );
};

export default Courtesies;
