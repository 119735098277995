import React, { useCallback, useRef, useState } from 'react';
import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { ITicket, ITicketType } from '@soluticket/shared';
import { Field, Form, Formik, FormikProps } from 'formik';
import TextFormField from '../../components/Form/TextFormField';
import {
  Alert,
  SimpleList,
  MyConfirmDialog,
  Card,
  CreateGrid,
} from '../../components';
import CurrencyFormField from '../../components/Form/CurrencyFormField';
import {
  createTicketType,
  duplicateTicketType,
  updateTicketType,
} from '../../http/Mutations';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  fetchTicketsTypes,
  fetchTicketTypeById,
  queriesKeys,
  queryClient,
} from '../../http/Queries';
import { createTicketTypeSchema } from '../../forms/validation.schemas';
import { UI_MESSAGES } from '../../constants';
import CheckboxFormField from '../../components/Form/CheckboxFormField';
import { useNotificationStore } from '../../store/notification.store';
import { LoadingButton } from '@mui/lab';
import { useSelector } from 'react-redux';
import { MainState } from '../../store';
import NumberFormField from '../../components/Form/NumberFormField';
import { formatHttpError } from '../../utils';

// TODO EV: Is event confirmed validation

const CreateTicketType: React.FC<{}> = () => {
  const { isEventConfirmed } = useSelector((state: MainState) => state.event);
  const formRef = useRef<FormikProps<ITicketType>>(null);
  const { handleNotification } = useNotificationStore((state) => state);
  const { data: ticketTypeList = [], isLoading: isLoadingTicketTypes } =
    useQuery(queriesKeys.fetchTicketsTypes(), fetchTicketsTypes);
  const [isNumbered, setIsNumbered] = useState(false);
  const [gridUpdate, setGridUpdate] = useState(false);
  const [grid, setGrid] = useState<Partial<ITicket>[][]>([]);
  const [error, setError] = useState<string | null>(null);
  const [selectedTicketTypeId, setSelectedTicketTypeId] = useState(0);
  const [ticketTypeIdToDuplicate, setTicketTypeIdToDuplicate] = useState<
    number | null
  >(null);

  const handleSuccess = () => {
    // Update UI. Invalidates query and make it call again.
    formRef.current?.resetForm();
    queryClient.invalidateQueries(queriesKeys.fetchTicketsTypes());
    queryClient.invalidateQueries(
      queriesKeys.fetchTicketTypeById(selectedTicketTypeId)
    );
    setError(null);
    handleNotification(UI_MESSAGES.SUCCESS, 200);
    setGrid([]);
    setIsNumbered(false);
    setSelectedTicketTypeId(0);
    setGridUpdate(false);
  };

  const handleError = (error: any) => {
    const message = formatHttpError(error);
    handleNotification(message, 400);
  };

  const { mutate: mutateCreate, isLoading: isLoadingCreate } = useMutation(
    createTicketType,
    { onSuccess: handleSuccess, onError: handleError }
  );
  const { mutate: mutateUpdate, isLoading: isLoadingUpdate } = useMutation(
    updateTicketType,
    { onSuccess: handleSuccess, onError: handleError }
  );
  const mDuplicateTicketType = useMutation(duplicateTicketType, {
    onSuccess: () => {
      handleSuccess();
      setTicketTypeIdToDuplicate(null);
    },
    onError: handleError,
  });
  const {
    data: fetchedTicketType,
    isLoading,
    isFetching,
  } = useQuery(
    queriesKeys.fetchTicketTypeById(selectedTicketTypeId),
    () => fetchTicketTypeById(selectedTicketTypeId),
    { enabled: selectedTicketTypeId > 0 && isNumbered }
  );

  const selectedTicketType = ticketTypeList.find(
    (item) => item.id === selectedTicketTypeId
  );

  const initialValues = (() => {
    let values = {} as ITicketType;
    let numberKeys = ['price']; // TODO EV: How to avoid this?
    if (selectedTicketType) {
      Object.entries(selectedTicketType).forEach(([key, value]) => {
        const newKey = key as keyof ITicketType;
        if (numberKeys.includes(key)) {
          value = Number(value);
        }
        values = { ...values, [newKey]: value };
      });
    } else {
      values = {
        name: '',
        price: 0,
        totalTicketsAvailable: 0,
        isTable: false,
        bundle: 1,
      };
    }
    return values;
  })();

  const selectListItemHandler = (idx: number) => {
    const selectedTicketType = ticketTypeList.find((item) => item.id === idx);
    setIsNumbered(selectedTicketType?.isNumbered ?? false);
    setSelectedTicketTypeId(idx);
  };

  const onDuplicate = useCallback((idx: number) => {
    setTicketTypeIdToDuplicate(idx);
  }, []);

  const saveGridHandler = useCallback((grid: Partial<ITicket>[][]) => {
    // Verify there are no duplicated numbers.
    const labels = new Set();
    const duplicatedLabels: Array<string> = [];

    grid.forEach((rows) => {
      rows.forEach((item) => {
        if (item.label && labels.has(item.label)) {
          duplicatedLabels.push(item.label);
        }
        labels.add(item.label);
      });
    });

    if (duplicatedLabels.length > 0) {
      const message = `Los siguientes números están duplicados: ${duplicatedLabels.join(
        ', '
      )}`;
      setError(message);
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
      return;
    } else {
      setError(null);
    }

    // Calculate number of seats.
    let numberOfSeats = 0;
    grid.forEach((rows, row) => {
      numberOfSeats += rows.filter((_, col) => grid[row][col].isSeat).length;
    });
    formRef.current?.setFieldValue('totalTicketsAvailable', numberOfSeats);
    setGrid(grid);
    setGridUpdate(true);
  }, []);

  const ticketTypeValidations = (
    ticketTypes: Array<ITicketType>,
    ticketType: ITicketType,
    isUpdate: boolean
  ) => {
    const typeName = ticketType.name.toUpperCase();

    let result = { isValid: true, error: '' };

    if (isUpdate) {
      const isInvalid = ticketTypes.find(
        (item) =>
          item.name.toUpperCase() === typeName && item.id !== ticketType.id
      );
      if (isInvalid) {
        result = { isValid: false, error: 'Nombre de ticket duplicado' };
      }
    } else {
      if (ticketTypes.find((type) => type.name.toUpperCase() === typeName)) {
        return { isValid: false, error: 'Nombre de ticket duplicado' };
      }
    }

    return result;
  };

  const onSubmit = (data: ITicketType) => {
    const payload = { ...data, isNumbered };
    if (isNumbered) {
      payload.grid = gridUpdate ? grid : fetchedTicketType?.grid;
    }
    const { isValid, error } = ticketTypeValidations(
      ticketTypeList,
      payload,
      selectedTicketTypeId > 0
    );

    if (!isValid) {
      setError(error!);
      return;
    }

    if (selectedTicketTypeId > 0) {
      mutateUpdate(payload);
    } else {
      mutateCreate(payload);
    }
  };

  const onDuplicateTicketType = (): void => {
    mDuplicateTicketType.mutate(ticketTypeIdToDuplicate!);
  };

  if (isEventConfirmed) {
    return (
      <Grid>
        <Typography variant='h5' component='h5'>
          Una vez confirmado el evento, no se pueden agregar o modificar los
          boletos.
        </Typography>
        <Typography variant='h5' component='h5'>
          En la pestaña "Modificar boletos" podrás editar los boletos
          existentes.
        </Typography>
      </Grid>
    );
  }

  return (
    <Grid container spacing={3}>
      <Grid item container direction='column' md={6}>
        {error && <Alert severity='error'>{error}</Alert>}
        <Card>
          <Grid item xs={12}>
            <FormControl component='fieldset' fullWidth>
              <FormLabel component='legend'>Boletos numerados</FormLabel>
              <RadioGroup
                row
                aria-label='radiobutton'
                value={isNumbered}
                onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
                  void setIsNumbered(evt.target.value === 'true')
                }
              >
                {[
                  { label: 'Si', value: true },
                  { label: 'No', value: false },
                ].map((option) => (
                  <FormControlLabel
                    key={`${option.value}`}
                    value={option.value}
                    label={option.label}
                    control={<Radio color='primary' />}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Grid>
          <Formik
            innerRef={formRef}
            initialValues={initialValues}
            validationSchema={createTicketTypeSchema}
            enableReinitialize={true}
            onSubmit={(data) => onSubmit(data)}
          >
            {({ resetForm }) => {
              return (
                <Form>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Field
                        name='name'
                        label='Nombre del boleto/zona'
                        component={TextFormField}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        name='price'
                        id='price'
                        label='Precio'
                        component={CurrencyFormField}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        name='totalTicketsAvailable'
                        label='Cantidad de boletos disponibles'
                        disabled={isNumbered}
                        component={NumberFormField}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControlLabel
                        name='isTable'
                        label='Es mesa?'
                        disabled={!isNumbered}
                        control={<Field component={CheckboxFormField} />}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        name='bundle'
                        label='Multiplos'
                        disabled={!isNumbered}
                        component={NumberFormField}
                      />
                    </Grid>
                    <Grid container item spacing={3} md={12}>
                      <Grid item>
                        <Button
                          variant='contained'
                          color='secondary'
                          type='button'
                          onClick={() => {
                            setSelectedTicketTypeId(0);
                            resetForm();
                            setError(null);
                            setIsNumbered(false);
                            setGrid([]);
                          }}
                        >
                          Cancelar
                        </Button>
                      </Grid>
                      <Grid item>
                        <LoadingButton
                          loading={isLoadingCreate || isLoadingUpdate}
                          variant='contained'
                          color='primary'
                          type='submit'
                        >
                          {selectedTicketTypeId > 0 ? 'Actualizar' : 'Guardar'}
                        </LoadingButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </Form>
              );
            }}
          </Formik>
        </Card>
      </Grid>
      <Grid item container direction='column' md={6}>
        <Card>
          <Grid container direction='column'>
            <h2>Boletos</h2>
            {isLoadingTicketTypes && <div>Cargando...</div>}
            <SimpleList
              data={ticketTypeList.map((ticket) => ({
                idx: ticket.id!,
                primary: ticket.name,
                secondary: `$${ticket.price} | #Boletos: ${ticket.totalTicketsAvailable}`,
              }))}
              maxHeight='233px'
              onSelect={selectListItemHandler}
              onRemove={onDuplicate}
            />
          </Grid>
        </Card>
      </Grid>
      {isNumbered && (
        <Grid item xs={12}>
          <Card>
            <h2>Boletos númerados</h2>
            {isLoading && isFetching ? (
              <p>Cargando...</p>
            ) : (
              <CreateGrid
                key={selectedTicketTypeId}
                onSave={saveGridHandler}
                existingGrid={fetchedTicketType?.grid}
              />
            )}
          </Card>
        </Grid>
      )}
      <MyConfirmDialog
        title={UI_MESSAGES.CONFIRM_ALERT_TITLE}
        description={UI_MESSAGES.DUPLICATE_RECORD}
        onApproved={onDuplicateTicketType}
        onClose={() => setTicketTypeIdToDuplicate(null)}
        open={ticketTypeIdToDuplicate !== null}
      />
    </Grid>
  );
};

export default CreateTicketType;
