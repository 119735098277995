import axios, { AxiosResponse } from 'axios';

export const CustomAxiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_API}`,
  timeout: 60 * 1000, // 1min
});

CustomAxiosInstance.interceptors.request.use((config) => {
  // Add token if exists.
  const token = localStorage.getItem('token') ?? '';
  if (token) {
    // @ts-ignore
    config.headers.authorization = `Bearer ${token}`;
  }

  // Add eventId if exists.
  const eventId = localStorage.getItem('eventid') ?? '';
  if (eventId) {
    // @ts-ignore
    config.headers.eventid = eventId;
  }

  return config;
});

CustomAxiosInstance.interceptors.response.use(
  (response: AxiosResponse) => response,
  (error: any) => {
    const { status } = error.response;
    if (status === 401) {
      // React Hook "useDispatch" cannot be called at the top level
      console.log('Unauthorize', status);
    }
    return Promise.reject(error);
  }
);

// TODO EV: Consider having response interceptor for showing errors or loading.
// TODO EV: Add interceptor to get 401 responses and show "LOGIN AGAIN".
// TODO EV: Review if react-query has a global interceptor.
