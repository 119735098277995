import { ITicketType } from '@soluticket/shared';
import React, { useEffect, useRef } from 'react';
import { Tooltip as Tooltip2 } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

const COLOR_LIGHT_GRAY = '#C4C4C4';

/**
 * Create HTML elements of tooltip
 * @param zone Data of the zone
 * @returns
 */
const buildTooltip = (zone: ITicketType) => {
  const zoneElement = document.createElement('div');
  zoneElement.innerHTML = zone.name;
  const priceElement = document.createElement('div');
  priceElement.innerHTML = `$${zone.price}`;
  const seatsElement = document.createElement('div');
  const zoneType = zone.isTable ? 'mesas' : 'boletos';
  seatsElement.innerHTML = `${zone.totalTicketsAvailable} ${zoneType} disponibles`;

  const div = document.createElement('div');
  div.appendChild(zoneElement);
  div.appendChild(priceElement);
  div.appendChild(seatsElement);

  div.style.display = 'flex';
  div.style.flexDirection = 'column';

  return div;
};

type VenueProps = {
  onSelectTicketType: (ticketType: ITicketType) => void;
  ticketTypes: Array<ITicketType>;
  venueUrl: string;
};

const VenueDisplay: React.FC<VenueProps> = (props: VenueProps) => {
  const venueContainerRef = useRef<HTMLDivElement>(null);
  const zonesRef = useRef<Array<Element>>([]);

  const highlightZone = () => {
    zonesRef.current.forEach((zoneRef) => {
      // @ts-ignore
      if (!!zoneRef) {
        if (zoneRef?.getAttribute('hasMouseListener') !== 'true') {
          zoneRef.setAttribute('fill', COLOR_LIGHT_GRAY);
        } else {
          zoneRef.setAttribute('fill', zoneRef.getAttribute('data-fill') || '');
        }
      }
    });
  };

  const hideHighlight = () => {
    zonesRef.current.forEach((zoneRef) => {
      // @ts-ignore
      if (!!zoneRef) {
        zoneRef.setAttribute('fill', zoneRef.getAttribute('data-fill') || '');
      }
    });
  };

  /**
   * Add event listeners to svg
   */
  useEffect(() => {
    const fetchVenue = async () => {
      const response = await fetch(props.venueUrl);
      const data = await response.text();

      if (venueContainerRef.current) {
        venueContainerRef.current.innerHTML = data;
        const svg = venueContainerRef.current.querySelector('svg');
        // svg?.classList.add('w-auto', 'h-96');
        if (svg) {
          svg.style.width = '100%';
        }

        setTimeout(() => {
          // SVG must group zones by <g id='zones'>
          // all the inner zones must be grouped, because all children will be matched with ticket types.
          // and ticket type must be lowercase no spaces
          // e.g <g id='zones'><rect id="zona1"/></g>
          // const zones = document.getElementById('zones');
          const zones =
            document.querySelector<SVGGElement>('#zones') ??
            document.querySelector<SVGGElement>('#ZONES');

          let i = 0;
          if (!zones) {
            console.log('Zones not found');
            return;
          }

          // @ts-ignore
          for (const zone of zones.children) {
            const typedZone = zone as SVGGElement;
            const ticketType = props.ticketTypes.find(
              (ticketType) => ticketType.name.toUpperCase() === typedZone.id
            );
            if (ticketType) {
              // Add cursor pointer to all,
              // if zone is numbered show GRID, else focus <SELECT />
              typedZone.style.cursor = 'pointer';
              // Add click listener.
              typedZone.addEventListener('click', () => {
                props.onSelectTicketType(ticketType);
              });

              // Save proper color
              // sometimes the first children has the color
              let currentZone = zone;
              if (typedZone.childElementCount > 1) {
                currentZone = typedZone.children[0]!;
              }
              // I'll use this array to highlight the zone
              zonesRef.current[i] = currentZone;

              currentZone.setAttribute(
                'data-fill',
                currentZone.getAttribute('fill') ?? ''
              );

              typedZone.setAttribute('data-tooltip-id', 'my-tooltip2');
              typedZone.setAttribute(
                'data-tooltip-html',
                buildTooltip(ticketType).outerHTML
              );
              typedZone.setAttribute('data-tooltip-place', 'top');
              typedZone.setAttribute('data-tooltip-float', 'true');

              // Add tooltip.
              // @ts-ignore
              typedZone.addEventListener('mouseover', (evt: any) => {
                const hasMouseListener =
                  !!zone.getAttribute('hasMouseListener');
                if (!hasMouseListener) {
                  typedZone.setAttribute('hasMouseListener', 'true');
                  // Needed to hightlight zone
                  if (typedZone.childElementCount > 1) {
                    typedZone.children[0]!.setAttribute(
                      'hasMouseListener',
                      'true'
                    );
                  }
                }

                // TODO: For some reason this code is not working
                // also notice that
                // 1. Names in svg allow spaces
                // 2. Names in SVG must be uppercase
                // 3. Names always match ticketTypeName = SVG{id}
                highlightZone();
              });
              typedZone.addEventListener('mouseout', (evt: any) => {
                typedZone.removeAttribute('hasMouseListener');
                // Needed to hide hightlighted zone
                if (typedZone.childElementCount > 1) {
                  typedZone.children[0]!.removeAttribute('hasMouseListener');
                }

                hideHighlight();
              });
              i++;
            }
          }
        }, 50);
      }
    };

    fetchVenue().catch((error) => {
      console.log('Venue::error', error);
    });

    // TODO EV: Verify if I have to clean up. Investigate how much memory my app uses.
    return () => {
      const zones = document.getElementById('zones') ?? {
        children: [],
      };
      // @ts-ignore
      for (const zone of zones.children) {
        zone.removeEventListener('click', () => {});
        zone.removeEventListener('mouseover', () => {});
        zone.removeEventListener('mouseout', () => {});
      }
    };
  }, [props]);

  return (
    <>
      <Tooltip2 id='my-tooltip2' />
      <div
        id='venueContainer'
        style={{
          display: 'flex',
          height: 'auto',
          alignItems: 'center',
          justifyContent: 'center',
          overflow: 'hidden',
          width: '100%',
        }}
        ref={venueContainerRef}
      ></div>
    </>
  );
};

export default React.memo(VenueDisplay);
