import React from 'react';
import SimpleTabs from '../../components/SimpleTabs';
import Balance from './Balance';
import BankAccounts from './BankAccounts';

const Sales: React.FC = () => {
  const tabs = [
    {
      label: 'Saldos',
      component: <Balance />,
    },
    {
      label: 'Cuentas bancarias',
      component: <BankAccounts />,
    },
  ];

  return <SimpleTabs tabs={tabs} />;
};

export default Sales;
