import React, { SyntheticEvent, useEffect, useState } from 'react';
import { ITicket, TicketStatus } from '@soluticket/shared';
import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import produce from 'immer';

// Moved to here because it seems not be working on a separate file when deploying
const gridStyles = makeStyles(() => ({
  grid: {
    display: 'grid',
    gap: '5px',
  },
  cell: {
    width: 40,
    height: 40,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '15px',
    cursor: 'pointer',
    userSelect: 'none',
  },
}));

export const TICKET_COLORS_BG = new Map<TicketStatus, string>([
  [TicketStatus.AVAILABLE, '#EFFCE8'], // light gray
  [TicketStatus.BLOCKED, '#f87171'], // light orange
  [TicketStatus.PENDING, '#D3D3D3'], // gray
  [TicketStatus.PENDING_PAYMENT, '#E7A1A1'], // red
  [TicketStatus.SOLD, '#fcd34d'], // cream?
  [TicketStatus.RESERVED, '#D2C3C3'], // dark red
  [TicketStatus.VERIFIED, '#9DE1FF'], // dark blue
  [TicketStatus.COURTESY, '#10b981'], // dark orange
]);

export const TRANSLATIONS = new Map<TicketStatus, string>([
  [TicketStatus.AVAILABLE, 'Disponible'],
  [TicketStatus.BLOCKED, 'Bloqueado'],
  [TicketStatus.PENDING, 'Pendiente'],
  [TicketStatus.PENDING_PAYMENT, 'Pendiente de pago'],
  [TicketStatus.SOLD, 'Vendido'],
  [TicketStatus.RESERVED, 'Reservado'],
  [TicketStatus.VERIFIED, 'Verificado'],
  [TicketStatus.COURTESY, 'Cortesía'],
]);

const getBackgroundColor = (ticket: ITicket) => {
  if (ticket.isSelected) {
    return '#1e293b';
  } else {
    const bgColor = TICKET_COLORS_BG.get(ticket.status!);
    return ticket.isSeat ? bgColor : '';
  }
};

const getBorder = (ticket: ITicket) => {
  return ticket.isSeat ? 'solid 1px black' : '';
};

const getColor = (ticket: ITicket) => {
  if (ticket.isSelected) {
    return 'white';
  } else {
    return 'black';
  }
};

const getCursor = (ticket: ITicket) => {
  return ticket.isSeat ? 'pointer' : '';
};

type GridTicketProps = {
  existingGrid: ITicket[][];
  onTicketSelected?: (ticket: Array<ITicket>) => void;
  enableActions?: boolean;
};

// light colors
// https://www.december.com/html/spec/colorshades.html
const GridModifyTicket: React.FC<GridTicketProps> = ({
  existingGrid = [[]],
  onTicketSelected,
  enableActions = true,
}) => {
  const [clicked, setClicked] = useState(false);
  const [grid, setGrid] = useState<ITicket[][]>(existingGrid);
  const numCols = grid.length ? grid[0].length : 0;
  const classes = gridStyles();

  useEffect(() => {
    setGrid(existingGrid);
  }, [existingGrid]);

  const toggleSeat = (row: number, col: number) => {
    if (enableActions) {
      const newGrid = produce(grid, (gridDraft) => {
        gridDraft[row][col].isSelected = !gridDraft[row][col].isSelected;
      });
      setGrid(newGrid);
    }
  };

  const updateSelectedTickets = () => {
    const selectedTickets = grid.flat().filter((ticket) => ticket.isSelected);
    if (onTicketSelected) {
      onTicketSelected(selectedTickets);
    }
  };

  return (
    <Grid id='wrapper' item overflow='scroll' style={{ width: '100%' }}>
      <div
        id='grid'
        style={{
          gridTemplateColumns: `repeat(${numCols}, 50px)`,
        }}
        className={classes.grid}
      >
        {grid.map((rows, row) =>
          rows.map((_, col) => {
            const ticket = grid[row][col];
            return (
              <div
                key={`${row}-${col}`}
                className={classes.cell}
                style={{
                  backgroundColor: getBackgroundColor(ticket),
                  border: getBorder(ticket),
                  color: getColor(ticket),
                  width: 50,
                  height: 50,
                  cursor: enableActions ? getCursor(ticket) : 'auto',
                  fontSize: '12px',
                }}
                onMouseDown={(event: any) => {
                  let { isSeat, status } = ticket;
                  if (
                    isSeat &&
                    (status === TicketStatus.AVAILABLE ||
                      status === TicketStatus.BLOCKED) &&
                    event.nativeEvent.which === 1 &&
                    col !== 0
                  ) {
                    setClicked(true);
                    toggleSeat(row, col);
                  }
                }}
                onMouseUp={() => {
                  setClicked(false);
                }}
                onMouseEnter={() => {
                  let { isSeat, status } = ticket;
                  // Avoid setting first col.
                  if (
                    clicked &&
                    col !== 0 &&
                    isSeat &&
                    (status === TicketStatus.AVAILABLE ||
                      status === TicketStatus.BLOCKED)
                  ) {
                    toggleSeat(row, col);
                  }
                }}
                onContextMenu={(event: SyntheticEvent) => {
                  event.preventDefault();

                  if (col > 0 && ticket.isSeat) {
                    // TODO EV: Get proper row and column
                    updateSelectedTickets();
                  }
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <span>{ticket.label}</span>
                  <span>{ticket.price}</span>
                </div>
              </div>
            );
          })
        )}
      </div>
    </Grid>
  );
};

export default React.memo(GridModifyTicket);
