import {
  Courtesy,
  IBalance,
  IBankAccount,
  ICoupon,
  IDashboardModel,
  IEventDetail,
  IEventList,
  ISelectModel,
  ITicket,
  ITicketType,
  ITransfer,
  IVenue,
  IWorker,
  NotNumberedTicketsTotals,
  ReservedTicket,
  SalesByDayType,
  SalesSummary,
} from '@soluticket/shared';
import { QueryClient } from '@tanstack/react-query';
import { CustomAxiosInstance } from './my-axios';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0, // or number of retries e.g: retry: 2
      staleTime: 1000 * 20, // As long as the query is fresh, data will always be read from the cache only.
      refetchOnMount: true,
      refetchOnWindowFocus: true, // Options not needed on web-admin?
      refetchOnReconnect: true,
      refetchInterval: false,
      // cacheTime: 0, // Time to be query to cache
    },
  },
});

// Is this the best way to do it?
// it think so(for now)..
const eventId = () => {
  return localStorage.getItem('eventid') || '';
};

const getToken = () => {
  return localStorage.getItem('token') || '';
};

export const queriesKeys = {
  fetchUserById: () => ['fetchUserById', getToken()] as const,
  fetchEventDetails: () => ['fetchEventDetails', eventId()] as const,
  fetchEvents: () => ['fetchEvents', getToken()] as const,
  fetchTicketsTypes: () => ['fetchTicketsTypes', eventId()] as const,
  fetchTicketTypeById: (ticketTypeId: number) =>
    ['fetchTicketTypeById', eventId(), ticketTypeId] as const,
  fetchCoupons: () => ['fetchCoupons', eventId()] as const,
  fetchCouponById: (couponId: number) => ['fetchCouponById', couponId] as const,
  fetchBankAccounts: () => ['fetchBankAccounts', eventId()] as const,
  fetchBalances: () => ['fetchBalances', eventId()] as const,
  fetchTransfers: () => ['fetchTransfers', eventId()] as const,
  fetchWorkers: () => ['fetchWorkers', eventId()] as const,
  fetchWorkerById: (workerId: number) => ['fetchWorkerById', workerId] as const,
  fetchVenues: () => ['fetchVenues'] as const,
  fetchSeats: (venueId: number) => ['fetchSeats', venueId] as const,
  fetchZonesByVenue: (seatId: number) => ['fetchZonesByVenue', seatId] as const,
  canModify: () => ['canModify', eventId()] as const,
  fetchVenueUrl: () => ['fetchVenueUrl', eventId()] as const,
  fetchGrid: (ticketTypeId?: number) =>
    ticketTypeId
      ? (['fetchGrid', eventId(), ticketTypeId] as const)
      : (['fetchGrid', eventId()] as const),
  fetchDashboard: () => ['fetchDashboard', eventId()] as const,
  fetchSalesByDay: () => ['fetchSalesByDay', eventId()] as const,
  fetchSalesByTicketType: () => ['fetchSalesByTicketType', eventId()] as const,
  fetchSalesByType: () => ['fetchSalesByType', eventId()] as const,
  fetchReservedTickets: () => ['fetchReservedTickets', eventId()] as const,
  fetchCourtesies: () => ['fetchCourtesies', eventId()] as const,
  // No need to EVENT ID
  fetchTicketTypesOptions: () => ['fetchTicketTypesOptions'] as const,
  fetchVenuesAsOptions: () => ['fetchVenuesAsOptions'] as const,
};

export const fetchUserById = async (): Promise<{
  name: string;
  email: string;
}> => {
  return (await CustomAxiosInstance.get('/users/fetch-user-by-id')).data;
};

export const fetchEvents = async (): Promise<Array<IEventList>> => {
  return (await CustomAxiosInstance.get('/events')).data;
};

export const fetchEventDetails = async (): Promise<IEventDetail> => {
  return (await CustomAxiosInstance.get<IEventDetail>('events/id')).data;
};

export const canModify = async (): Promise<boolean> => {
  const { data } = await CustomAxiosInstance.get<boolean>(`/events/can-modify`);
  return data;
};

// ---------------------- TICKET TYPES ----------------------
export const fetchTicketsTypes = async (): Promise<Array<ITicketType>> => {
  return (await CustomAxiosInstance.get('/ticketstypes')).data;
};

export const fetchTicketTypeById = async (id: number): Promise<ITicketType> => {
  const { data } = await CustomAxiosInstance.get(`/ticketstypes/${id}`);
  return data;
};

// ---------------------- COUPONS ----------------------
export const fetchCoupons = async (): Promise<Array<ICoupon>> => {
  return (await CustomAxiosInstance.get('/coupons')).data;
};

export const fetchCouponById = async (idx: number): Promise<ICoupon> => {
  return (await CustomAxiosInstance.get(`/coupons/${idx}`)).data;
};

// ---------------------- BANK ACCOUNTS ----------------------
export const fetchBankAccounts = async (): Promise<IBankAccount[]> => {
  return (await CustomAxiosInstance.get(`/bank-account`)).data;
};

export const fetchBankAccountById = async (
  idx: number
): Promise<IBankAccount[]> => {
  return (await CustomAxiosInstance.get(`/bank-account/${idx}`)).data;
};

// ---------------------- TRANSFER(saldos) --------------------
export const fetchBalances = async (): Promise<IBalance> => {
  return await (
    await CustomAxiosInstance.get(`/balance/totals`)
  ).data;
};

export const fetchTransfers = async (): Promise<ITransfer[]> => {
  return await (
    await CustomAxiosInstance.get(`/balance`)
  ).data;
};

// ---------------------- Workers --------------------
export const fetchWorkers = async (): Promise<IWorker[]> => {
  return await (
    await CustomAxiosInstance.get('/workers')
  ).data;
};

export const fetchWorkerById = async (idx: number): Promise<IWorker> => {
  return await (
    await CustomAxiosInstance.get(`/workers/${idx}`)
  ).data;
};

// ---------------------- Venue --------------------
export const fetchVenues = async (): Promise<IVenue[]> => {
  const { data } = await CustomAxiosInstance.get(`/venues`);
  return data;
};

type Zones = {
  venueName: string;
  zones: {
    id: number;
    name: string;
    grid: Partial<ITicket>[][];
  }[];
};

export const fetchZonesByVenue = async (venueId: number): Promise<Zones> => {
  const { data } = await CustomAxiosInstance.get(`/venues/${venueId}/zones`);
  return data;
};

// ---------------------- Tickets --------------------

export const fetchGrid = async (
  ticketTypeId: number
): Promise<ITicket[][] | NotNumberedTicketsTotals> => {
  const { data } = await CustomAxiosInstance.get(
    `tickets/grid/${ticketTypeId}`
  );
  return data;
};

// ---------------------- SELECT and MULTISELECT --------------------
export const fetchTicketTypesOptions = async (): Promise<
  Array<ISelectModel>
> => {
  const { data } = await CustomAxiosInstance.get<Array<ISelectModel>>(
    `workers/ticket-types`
  );
  return data;
};

export const fetchVenuesAsOptions = async (): Promise<Array<ISelectModel>> => {
  const { data } = await CustomAxiosInstance.get<Array<ISelectModel>>(
    `/venues/options`
  );
  return data;
};

// ---------------------- D A S H B O A R D --------------------
export const fetchDashboard = async (): Promise<IDashboardModel> => {
  const { data } = await CustomAxiosInstance.get<IDashboardModel>(
    `/reports/dashboard`
  );
  return data;
};

export const fetchSalesByDay = async () => {
  const { data } = await CustomAxiosInstance.get<Array<SalesByDayType>>(
    `/reports/sales-by-day`
  );
  return data;
};

export const fetchSalesByTicketType = async () => {
  const { data } = await CustomAxiosInstance.get(
    `/reports/sales-by-ticket-type`
  );
  return data as SalesSummary;
};

export const fetchSalesByType = async () => {
  const { data } = await CustomAxiosInstance.get(`/reports/sales-by-type`);
  return data as {
    salesWeb: number;
    salesBoxOffice: number;
    salesBoxOfficeCash: number;
    salesBoxOfficeCard: number;
  };
};

// ---------------------- T I C K E T S --------------------

export const fetchReservedTickets = async () => {
  const { data } = await CustomAxiosInstance.get<Array<ReservedTicket>>(
    `/tickets/reserved`
  );
  return data;
};

export const fetchCourtesies = async () => {
  const { data } = await CustomAxiosInstance.get<Array<Courtesy>>(
    `/tickets/courtesies`
  );
  return data;
};

export const fetchVenueUrl = async (): Promise<string> => {
  const { data } = await CustomAxiosInstance.get<string>(
    `/tickets/fetch-venue-url`
  );
  return data;
};
