import { WorkerType } from '@soluticket/shared';
import { array, mixed, number, object, string } from 'yup';

// TODO EV: Add i18 to translate messages from backend.

const VALIDATION_TEXT = {
  NUMBER: 'Valor debe ser un número',
  URL: 'Debe ser una URL válida',
  MIN_1: 'Valor debe ser mayor o igual a 1',
  EMAIL: 'Debe ser un email válido',
  REQUIRED: 'Campo requerido',
  MIN_LENGTH: (min: number) => `Minimo número de caracteres es ${min}`,
  MAX_LENGTH: (max: number) => `Máximo número de caracteres es ${max}`,
  PHONE_LENGTH: 'El número de caracteres debe ser 10',
};

const urlPattern = new RegExp(
  '^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$',
  'i'
);

export const generalDataSchema = object({
  eventName: string(),
  startDate: string().required(VALIDATION_TEXT.REQUIRED),
  eventType: string().required(VALIDATION_TEXT.REQUIRED),
  // availableTickets: number() // Not longer needed, just an example of integer validation
  //   .integer('El número no debe tener decimales')
  //   .typeError(VALIDATION_TEXT.NUMBER)
  //   .min(1, VALIDATION_TEXT.MIN_1),
  description: string(),
  // mapCoordinates: string(),
  state: string().required(VALIDATION_TEXT.REQUIRED),
  city: string().required(VALIDATION_TEXT.REQUIRED),
  address: string().required(VALIDATION_TEXT.REQUIRED),
  videoUrl: string().matches(urlPattern, VALIDATION_TEXT.URL).optional(),
});

export const contactSchema = object({
  organizer: string().required(VALIDATION_TEXT.REQUIRED),
  email: string()
    .email(VALIDATION_TEXT.EMAIL)
    .required(VALIDATION_TEXT.REQUIRED),
  phone: string()
    .length(10, VALIDATION_TEXT.PHONE_LENGTH)
    .required(VALIDATION_TEXT.REQUIRED),
  celphone: string().length(10, VALIDATION_TEXT.PHONE_LENGTH),
  webpage: string().matches(urlPattern, VALIDATION_TEXT.URL),
  facebook: string().matches(urlPattern, VALIDATION_TEXT.URL),
  twitter: string().matches(urlPattern, VALIDATION_TEXT.URL),
  instagram: string().matches(urlPattern, VALIDATION_TEXT.URL),
});

export const sponsorSchema = object().shape({
  sponsors: array().of(
    object().shape({
      name: string().required(VALIDATION_TEXT.REQUIRED),
      link: string()
        .matches(urlPattern, VALIDATION_TEXT.URL)
        .required(VALIDATION_TEXT.REQUIRED),
    })
  ),
});

export const loginSchema = object({
  email: string()
    .email(VALIDATION_TEXT.EMAIL)
    .required(VALIDATION_TEXT.REQUIRED),
  password: string()
    .min(6, VALIDATION_TEXT.MIN_LENGTH(6))
    .required(VALIDATION_TEXT.REQUIRED),
});

export const registerSchema = object({
  name: string().required(VALIDATION_TEXT.REQUIRED),
  lastName: string().required(VALIDATION_TEXT.REQUIRED),
  phone: string()
    .length(10, VALIDATION_TEXT.PHONE_LENGTH)
    .required(VALIDATION_TEXT.REQUIRED),
  email: string()
    .email(VALIDATION_TEXT.EMAIL)
    .required(VALIDATION_TEXT.REQUIRED),
  password: string()
    .min(6, VALIDATION_TEXT.MIN_LENGTH(6))
    .required(VALIDATION_TEXT.REQUIRED),
});

export const verifyCodeSchema = object({
  verificationCode: string().required(VALIDATION_TEXT.REQUIRED),
  email: string()
    .email(VALIDATION_TEXT.EMAIL)
    .required(VALIDATION_TEXT.REQUIRED),
});

export const createTicketTypeSchema = object({
  name: string()
    .trim()
    .max(20, 'Máximo número de caracteres es 20')
    .required('Requerido'),
  price: number()
    .typeError('El valor debe ser un número')
    .min(1, 'Valor debe ser mayor o igual 1')
    .required('Requerido'),
  totalTicketsAvailable: number()
    .typeError('El valor debe ser un número')
    .min(1, 'Valor debe ser mayor o igual 1')
    .integer('Solo número enteros')
    .required('Requerido'),
  bundle: number()
    .typeError('El valor debe ser un número')
    .min(1, 'Valor debe ser mayor o igual 1')
    .max(30, 'Valor debe maximo debe ser menor o igual 30')
    .integer('Solo número enteros')
    .required('Requerido'),
});

export const couponSchema = object({
  code: string()
    .trim()
    .max(20, VALIDATION_TEXT.MAX_LENGTH(20))
    .required(VALIDATION_TEXT.REQUIRED),
  ticketTypeId: string().required(VALIDATION_TEXT.REQUIRED),
  discountType: string().required(VALIDATION_TEXT.REQUIRED),
  discount: number().min(1, VALIDATION_TEXT.MIN_1),
  numberOfCodes: number().min(1, VALIDATION_TEXT.MIN_1),
  startDate: string().required(VALIDATION_TEXT.REQUIRED),
});

export const workerSchema = object({
  username: string().trim().required(VALIDATION_TEXT.REQUIRED),
  password: string().min(6, VALIDATION_TEXT.MIN_LENGTH(6)),
  type: string().required(),
  // TODO EV: fix this, pass the helper text!
  ticketTypes: mixed().when('type', {
    is: WorkerType.VENDEDOR,
    then: array().of(string()).required(VALIDATION_TEXT.REQUIRED),
    //otherwise: string(),
  }),
});

export const venueSchema = object({
  name: string().trim().required(VALIDATION_TEXT.REQUIRED),
  imageUrl: mixed().required(VALIDATION_TEXT.REQUIRED),
});

export const paymentMethodSchema = object({
  EVO: number()
    .typeError(VALIDATION_TEXT.NUMBER)
    .required(VALIDATION_TEXT.REQUIRED),
  CONEKTA: number()
    .typeError(VALIDATION_TEXT.NUMBER)
    .required(VALIDATION_TEXT.REQUIRED),
  CARD: number()
    .typeError(VALIDATION_TEXT.NUMBER)
    .required(VALIDATION_TEXT.REQUIRED),
  CASH: number()
    .typeError(VALIDATION_TEXT.NUMBER)
    .required(VALIDATION_TEXT.REQUIRED),
});

export const requestTransferSchema = object().shape({
  amount: number()
    .min(1, VALIDATION_TEXT.MIN_1)
    .typeError(VALIDATION_TEXT.NUMBER)
    .required(VALIDATION_TEXT.REQUIRED),
  type: string().required(VALIDATION_TEXT.REQUIRED),
  bankAccountId: number()
    .min(1, VALIDATION_TEXT.MIN_1)
    .typeError(VALIDATION_TEXT.NUMBER)
    .required(VALIDATION_TEXT.REQUIRED)
    .when('type', {
      is: 'CASH',
      then: number().min(0).notRequired(),
    }),
});
