import React, { useRef } from 'react';
import { Button, Grid } from '@mui/material';
import { Field, Form, Formik, FormikProps } from 'formik';
import BalanceItem from './BalanceItem';
import { Card } from '../../components';
import { buildCurrency, IBalance } from '@soluticket/shared';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  fetchBalances,
  fetchBankAccounts,
  fetchTransfers,
  queriesKeys,
  queryClient,
} from '../../http/Queries';
import { requestTransfer } from '../../http/Mutations';
import { requestTransferSchema } from '../../forms/validation.schemas';
import { useNotificationStore } from '../../store/notification.store';
import { UI_MESSAGES } from '../../constants';
import { formatDate, formatHttpError } from '../../utils';
import { LoadingButton } from '@mui/lab';
import { SalesByType } from '../Reports/Dashboard/SalesByType';
import NumberFormField from '../../components/Form/NumberFormField';
import SelectFormField from '../../components/Form/SelectFormField';

type FormType = {
  amount: number;
  type: 'DEPOSIT' | 'CASH';
  bankAccountId: number;
};

/**
 * Saldos
 */
const Balance: React.FC = () => {
  const formRef = useRef<FormikProps<FormType>>(null);
  const handleNotification = useNotificationStore(
    (state) => state.handleNotification
  );
  const { data: balances = {} } = useQuery(
    queriesKeys.fetchBalances(),
    fetchBalances
  );
  const { data: transfers = [] } = useQuery(
    queriesKeys.fetchTransfers(),
    fetchTransfers
  );
  const mTransfer = useMutation(requestTransfer, {
    onSuccess: () => {
      handleNotification(UI_MESSAGES.SUCCESS, 200);
      formRef.current?.resetForm();
      queryClient.invalidateQueries(queriesKeys.fetchTransfers());
    },
    onError: (error: any) => {
      const message = formatHttpError(error);
      handleNotification(message, 400);
    },
  });
  const { data: bankAccounts = [] } = useQuery(
    queriesKeys.fetchBankAccounts(),
    fetchBankAccounts
  );

  const options = bankAccounts.map(({ id, accountNumber }) => ({
    value: `${id}`,
    label: `${accountNumber}`,
  }));

  const formHandler = async (payload: FormType) => {
    mTransfer.mutate({
      amount: Number(payload.amount),
      type: payload.type,
      ...(payload.type === 'DEPOSIT' && {
        bankAccountId: Number(payload.bankAccountId),
      }),
    });
  };

  return (
    <Grid container direction='column' spacing={3}>
      <Grid item container spacing={3} xs={12}>
        <Grid item md={6} sm={12}>
          <Card>
            <Formik
              innerRef={formRef}
              initialValues={{
                amount: 0,
                type: 'DEPOSIT',
                bankAccountId: 0,
              }}
              validationSchema={requestTransferSchema}
              enableReinitialize={true}
              onSubmit={formHandler}
            >
              {(props) => (
                <Form>
                  <Grid container spacing={3} alignItems='center'>
                    <Grid item xs={12}>
                      <Field
                        name='amount'
                        label='Cantidad a solicitar'
                        component={NumberFormField}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        name='type'
                        label='Tipo de solicitud'
                        options={[
                          { label: 'Depósito', value: 'DEPOSIT' },
                          { label: 'Efectivo', value: 'CASH' },
                        ]}
                        component={SelectFormField}
                      />
                    </Grid>
                    {props.values.type === 'DEPOSIT' && (
                      <Grid item xs={12}>
                        <Field
                          name='bankAccountId'
                          label='Cuenta bancaria'
                          options={options}
                          component={SelectFormField}
                        />
                      </Grid>
                    )}

                    <Grid container item spacing={3} md={12}>
                      <Grid item>
                        <Button
                          variant='contained'
                          color='secondary'
                          type='button'
                          onClick={() => formRef.current?.resetForm()}
                        >
                          Limpiar
                        </Button>
                      </Grid>
                      <Grid item>
                        <LoadingButton
                          variant='contained'
                          color='primary'
                          type='submit'
                          loading={mTransfer.isLoading}
                        >
                          Solicitar
                        </LoadingButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Card>
        </Grid>
        <Grid item md={6} xs={12}>
          <Card title='Transferencias solicitadas'>
            {transfers?.map((transfer) => (
              <div
                key={transfer.id}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginBottom: 10,
                  borderBottom: '1px solid #ccc',
                }}
              >
                <div>{formatDate(transfer.date)}</div>
                <div>Cantidad {buildCurrency(transfer.requestedAmount)}</div>
                <div>
                  Estatus:{' '}
                  <span
                    style={{
                      padding: '2px 5px',
                      color: 'white',
                      backgroundColor:
                        transfer.status === 'PENDIENTE' ? 'orchid' : 'green',
                    }}
                  >
                    {transfer.status}
                  </span>{' '}
                  | Tipo de solicitud:{' '}
                  <span
                    style={{
                      borderBottom: `1px solid ${
                        transfer.type === 'CASH' ? 'green' : 'blue'
                      }`,
                    }}
                  >
                    {transfer.type === 'CASH' ? 'Efectivo' : 'Depósito'}
                  </span>
                </div>
                {transfer.receiptUrl && (
                  <a
                    href={transfer.receiptUrl}
                    rel='noreferrer'
                    target='_blank'
                  >
                    Ver comprobante
                  </a>
                )}
              </div>
            ))}
          </Card>
        </Grid>
      </Grid>
      <Grid item container spacing={3} xs={12}>
        {Object.keys(balances).map((key) => {
          const typedKey = key as keyof IBalance;
          // @ts-ignore
          const amount: number = balances[typedKey] ?? 0;
          return <BalanceItem key={key} name={key} amount={amount} />;
        })}
        <Grid item md={6} sm={12} xs={12}>
          <Card>
            <SalesByType />
          </Card>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Balance;
