import { WorkerType } from '../enums';

export class IWorker {
  id?: number;
  username: string;
  password?: string;
  type: WorkerType;
  ticketTypes: Array<string>;
  isActive: boolean;
}
