import React, { useState } from 'react';
import { Grid, IconButton, Select, MenuItem, Button } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

export type RowsRangeType = 'abc' | '123' | 'aabbcc';
export type SeatsDirectionType = 'leftToRight' | 'rightToLeft';

export type GridActionsProps = {
  onAssignSeatsNumber: (seatsDirection: SeatsDirectionType) => void;
  addOrRemoveColumn: (add: boolean) => void;
  addOrRemoveRow: (add: boolean) => void;
  onAssignIdentifierToRows: (rowsRange: RowsRangeType) => void;
  onReset: () => void;
  onSave: () => void;
  numRows: number;
  numCols: number;
};

const GridActions: React.FC<GridActionsProps> = ({
  onAssignSeatsNumber,
  addOrRemoveColumn,
  addOrRemoveRow,
  onAssignIdentifierToRows,
  onReset,
  onSave,
  numCols,
  numRows,
}) => {
  const [rowsRange, setRowsRange] = useState<RowsRangeType>('abc');
  const [seatsDirection, setSeatsDirection] =
    useState<SeatsDirectionType>('leftToRight');

  return (
    <Grid id='actions' item container spacing={2}>
      <Grid item xs={6} sm={6} md={2} container direction='column'>
        <label htmlFor='rows'>Renglón/Fila</label>
        <Grid container>
          <IconButton
            color='primary'
            aria-label='quitar un fila'
            component='span'
            onClick={() => addOrRemoveRow(false)}
          >
            <RemoveCircleIcon />
          </IconButton>
          <p>{numRows}</p>
          <IconButton
            color='primary'
            aria-label='agrega fila'
            component='span'
            onClick={() => addOrRemoveRow(true)}
          >
            <AddCircleIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Grid item xs={6} sm={6} md={2} container direction='column'>
        <label htmlFor='cols'>Columnas</label>
        <Grid container>
          <IconButton
            color='primary'
            aria-label='quitar un columna'
            component='span'
            onClick={() => addOrRemoveColumn(false)}
          >
            <RemoveCircleIcon />
          </IconButton>
          <p>{numCols}</p>
          <IconButton
            color='primary'
            aria-label='agrega columna'
            component='span'
            onClick={() => addOrRemoveColumn(true)}
          >
            <AddCircleIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={6} md={3} container direction='column'>
        <label htmlFor='start'>Asignar Filas</label>
        <Select
          onChange={(evt) => setRowsRange(evt.target.value as RowsRangeType)}
          value={rowsRange}
        >
          <MenuItem selected value='abc'>
            A,B,C,..Z
          </MenuItem>
          <MenuItem value='123'>1,2,3,...4</MenuItem>
          <MenuItem selected value='aabbcc'>
            AA,BB,CC,..ZZ
          </MenuItem>
        </Select>
        <Button
          onClick={() => onAssignIdentifierToRows(rowsRange)}
          variant='contained'
          color='primary'
          type='button'
        >
          filas
        </Button>
      </Grid>
      <Grid item xs={12} sm={6} md={3} container direction='column'>
        <label htmlFor='start'>Dirección asientos</label>
        <Select
          onChange={(evt) =>
            setSeatsDirection(evt.target.value as SeatsDirectionType)
          }
          value={seatsDirection}
        >
          <MenuItem selected value='leftToRight'>
            Izquierda a derecha
          </MenuItem>
          <MenuItem value='rightToLeft'>Derecha a izquierda</MenuItem>
        </Select>
        <Button
          onClick={() => onAssignSeatsNumber(seatsDirection)}
          variant='contained'
          color='primary'
          type='button'
          style={{ marginBottom: '5px' }}
        >
          Asignar asientos
        </Button>
      </Grid>
      <Grid
        item
        xs={6}
        sm={6}
        md={2}
        container
        direction='column'
        justifyContent='center'
        alignItems='center'
      >
        <Button
          variant='outlined'
          onClick={onReset}
          style={{ marginBottom: '5px' }}
        >
          Reiniciar asientos
        </Button>
        <Button
          variant='contained'
          onClick={onSave}
          style={{ marginBottom: '5px' }}
        >
          Guardar asientos
        </Button>
      </Grid>
    </Grid>
  );
};

export default GridActions;
