import {
  EventContractType,
  contactType,
  createTicketTypeType,
  createCouponType,
  createBankAccountType,
  requestTransferType,
  workerType,
  reserveTicketByIdType,
  updateTicketPriceType,
  reserveTicketByAmountType,
  createCourtesiesByIdType,
  createCourtesiesByAmountType,
  cancelCourtesyType,
  blockTicketsByIdType,
  blockTicketsByAmountType,
  userRegisterType,
  verifyUserCodeType,
  ReservationEmailType,
} from '@soluticket/shared';
import { AxiosResponse } from 'axios';
import { CustomAxiosInstance } from './my-axios';

// ### Login
export const login = ({ email, password, verificationCode }: any) =>
  CustomAxiosInstance.post('/users/login', {
    email,
    password,
    verificationCode,
  });

export const register = (payload: userRegisterType) =>
  CustomAxiosInstance.post('/users/register', payload);

export const verifyCode = (payload: verifyUserCodeType) =>
  CustomAxiosInstance.post('/users/verify-code', payload);

export const forgotPassword = (email: string) =>
  CustomAxiosInstance.post('/users/forgot-password', {
    email,
  });
//### Login end.

///### Events
export const createEvent = (payload: EventContractType) =>
  CustomAxiosInstance.post('/events', payload);

export const updateEventDescription = (payload: FormData) =>
  CustomAxiosInstance.put('/events/description', payload, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });

export const updateEventContact = (payload: contactType) =>
  CustomAxiosInstance.put(`/events/contact`, payload);

export const updateEventSponsors = (payload: FormData) =>
  CustomAxiosInstance.post(`/events/sponsors`, payload, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });

///### Events end.

// ### Ticket types.

export const createTicketType = (
  payload: createTicketTypeType
): Promise<AxiosResponse<createTicketTypeType>> =>
  CustomAxiosInstance.post('/ticketstypes', payload);

export const updateTicketType = (
  payload: createTicketTypeType
): Promise<AxiosResponse<createTicketTypeType>> =>
  CustomAxiosInstance.put(`/ticketstypes/${payload.id}`, payload);

export const removeTicketType = (idx: number) =>
  CustomAxiosInstance.delete(`/ticketstypes/${idx}`);

export const duplicateTicketType = (idx: number) =>
  CustomAxiosInstance.put(`/ticketstypes/duplicate/${idx}`);

// ### Ticket types end.

// ### Coupons.
export const createCoupon = (payload: createCouponType) =>
  CustomAxiosInstance.post('/coupons', payload);

export const updateCoupon = (payload: createCouponType) =>
  CustomAxiosInstance.put(`/coupons/${payload.id}`, payload);

export const removeCoupon = (couponId: number) =>
  CustomAxiosInstance.delete(`/coupons/${couponId}`);
// ### Coupons end.

export const createOrUpdateBankAccount = (payload: createBankAccountType) =>
  CustomAxiosInstance.post('/bank-account', payload);

export const removeBankAccount = (idx: number) =>
  CustomAxiosInstance.delete(`/bank-account/${idx}`);

// // ---------------- TRANSFERS ---------------------
export const requestTransfer = (payload: Partial<requestTransferType>) =>
  CustomAxiosInstance.post(`/balance`, payload);

export const cancelTransfer = (idx: number) =>
  CustomAxiosInstance.delete(`/balance/${idx}`);

// ---------------------- Workers --------------------
// TODO EV: create type
export const createWorker = (payload: workerType) =>
  CustomAxiosInstance.post(`/workers`, payload);

export const updateWorker = (payload: workerType) =>
  CustomAxiosInstance.put(`/workers/${payload.id}`, payload);

export const toggleWorkerStatus = (idx: number) =>
  CustomAxiosInstance.patch(`/workers/${idx}`);

// ---------------------- Venues --------------------
export const createVenue = (payload: FormData) =>
  CustomAxiosInstance.post(`/venues`, payload, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });

export const removeVenueById = (idx: number) =>
  CustomAxiosInstance.delete(`/venues/${idx}`);

// ---------------------- Tickets --------------------
export const updateTicketPrice = (payload: updateTicketPriceType) =>
  CustomAxiosInstance.put(`/tickets/update-ticket-price`, payload);

export const reserveTicketsById = (payload: reserveTicketByIdType) =>
  CustomAxiosInstance.put(`/tickets/reserve-tickets-by-id`, payload);

export const reserveTicketByAmount = (payload: reserveTicketByAmountType) =>
  CustomAxiosInstance.put(`/tickets/reserve-tickets-by-amount`, payload);

export const createCourtesiesById = (payload: createCourtesiesByIdType) =>
  CustomAxiosInstance.put(`/tickets/create-courtesies-by-id`, payload);

export const blockOrUnblockTicketsById = (
  payload: blockTicketsByIdType & { block: boolean }
) => {
  if (payload.block) {
    return CustomAxiosInstance.put(`/tickets/block-tickets-by-id`, payload);
  } else {
    return CustomAxiosInstance.put(`/tickets/unblock-tickets-by-id`, payload);
  }
};

export const createCourtesiesByAmount = (
  payload: createCourtesiesByAmountType
) => CustomAxiosInstance.put(`/tickets/create-courtesies-by-amount`, payload);

export const blockTicketsByAmount = (payload: blockTicketsByAmountType) =>
  CustomAxiosInstance.put(`/tickets/block-tickets-by-amount`, payload);

export const cancelReservation = (transactionId: number) => {
  return CustomAxiosInstance.post(`/tickets/cancel-reservation`, {
    transactionId,
  });
};

export const sendReservationByEmail = (payload: ReservationEmailType) => {
  return CustomAxiosInstance.post(
    `/tickets/send-reservation-by-email`,
    payload
  );
};

export const generateCourtesyLink = (giftCode: string) => {
  return CustomAxiosInstance.post(`/tickets/generate-courtesy-link`, {
    giftCode,
  });
};

export const cancelCourtesy = (payload: cancelCourtesyType) => {
  return CustomAxiosInstance.post(`/tickets/cancel-courtesy`, payload);
};

// ---------------------- Reports --------------------
export const downloadSalesByType = (): Promise<{ data: string }> => {
  return CustomAxiosInstance.post(`/reports/download-sales-by-type`);
};

export const downloadDetailSales = (): Promise<{ data: string }> => {
  return CustomAxiosInstance.post(`/reports/download-sales-detail`);
};

export const downloadDetailSalesTwo = (): Promise<{ data: string }> => {
  return CustomAxiosInstance.post(`/reports/download-sales-detail-2`);
};
