import { useCallback, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { fetchZonesByVenue, queriesKeys } from '../../http/Queries';
import { Card, CreateGrid } from '../../components';
import { useMatch } from '@tanstack/react-location';
import { ITicket } from '@soluticket/shared';

export default function EditZones() {
  const [grid, setGrid] = useState<Partial<ITicket>[][]>([]);
  const [selectedZoneId, setSelectedZoneId] = useState<number>();
  const match = useMatch();

  let venueId = +match.params.id;

  const qZones = useQuery(
    queriesKeys.fetchZonesByVenue(venueId),
    () => fetchZonesByVenue(venueId),
    { enabled: venueId > 0 }
  );

  const zones = qZones.data?.zones || [];
  const selectedZone = zones.find((zone) => zone.id === selectedZoneId);

  const { handleSubmit, control, setValue } =
    useForm<{ zone: string; availableSeats: number }>();

  const saveGridHandler = useCallback(
    (grid: Partial<ITicket>[][]) => {
      // Verify there are no duplicated numbers.
      const labels = new Set();
      const duplicatedLabels: Array<string> = [];

      grid.forEach((rows) => {
        rows.forEach((item) => {
          if (item.label && labels.has(item.label)) {
            duplicatedLabels.push(item.label);
          }
          labels.add(item.label);
        });
      });

      if (duplicatedLabels.length > 0) {
        const message = `Los siguientes números están duplicados: ${duplicatedLabels.join(
          ', '
        )}`;

        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
        prompt(message);
        return;
      }

      // Calculate number of seats.
      let numberOfSeats = 0;
      grid.forEach((rows, row) => {
        numberOfSeats += rows.filter((_, col) => grid[row][col].isSeat).length;
      });
      setValue('availableSeats', numberOfSeats);
      setGrid(grid);
    },
    [setValue]
  );

  return (
    <Card>
      <Grid container rowSpacing={2}>
        <Grid container item md={12}>
          <Grid item>{qZones.isLoading && <CircularProgress />}</Grid>
          <Typography variant='h6'>
            Este modulo aun no esta completo... Recinto:{' '}
            {qZones.data?.venueName}
          </Typography>
          <form
            style={{
              display: 'flex',
              width: '100%',
            }}
            onSubmit={handleSubmit((data) => {
              console.log(data);
            })}
          >
            <Grid container columnSpacing={1} rowSpacing={2}>
              <Grid item xs={12} md={7}>
                <FormControl fullWidth>
                  <InputLabel>Zonas</InputLabel>
                  <Controller
                    render={({ field }) => {
                      return (
                        <Select
                          name={field.name}
                          value={field.value}
                          ref={field.ref}
                          onBlur={field.onBlur}
                          onChange={(evt) => {
                            const selectedZone = evt.target.value as string;
                            setSelectedZoneId(+selectedZone);
                            field.onChange(selectedZone);
                          }}
                        >
                          {zones.map((zone) => {
                            return (
                              <MenuItem key={zone.id} value={zone.id}>
                                {zone.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      );
                    }}
                    name='zone'
                    control={control}
                    defaultValue=''
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <Controller
                    name='availableSeats'
                    control={control}
                    defaultValue={0}
                    render={({ field }) => {
                      return (
                        <TextField
                          {...field}
                          fullWidth
                          label='Cantidad de asientos'
                        />
                      );
                    }}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} md={1}>
                <Button type='submit' variant='contained'>
                  Guardar
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
        <Grid item md={12}>
          <CreateGrid
            onSave={saveGridHandler}
            existingGrid={selectedZone?.grid || []}
          />
        </Grid>
      </Grid>
    </Card>
  );
}
