import React from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import { FieldProps } from 'formik';

const CheckboxFormField: React.FC<
  FieldProps & {
    label: string;
  }
> = ({ field, label, form, ...props }) => {
  return (
    <FormControlLabel
      label={label}
      control={<Checkbox checked={field.value} {...field} {...props} />}
    />
  );
};

export default CheckboxFormField;
