import React from 'react';
import SimpleTabs, { SingleSimpleTab } from '../../components/SimpleTabs';
import Contact from './Contact';
import GeneralData from './GeneralData';
import Sponsors from './Sponsors';

const tabs: Array<SingleSimpleTab> = [
  {
    label: 'Datos generales',
    component: <GeneralData />,
  },
  {
    label: 'Contacto',
    component: <Contact />,
  },
  {
    label: 'Patrocinadores',
    component: <Sponsors />,
  },
];
const Events: React.FC<{}> = () => {
  return <SimpleTabs tabs={tabs} />;
};

export default Events;
