import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  SlideProps,
} from '@mui/material';
import { createPortal } from 'react-dom';

export type CustomDialogProps = {
  open: boolean;
  title: string;
  description: string;
  onApproved: () => void;
  onClose?: () => void;
};

const Transition = React.forwardRef<unknown, SlideProps>((props, ref) => (
  <Slide direction='up' ref={ref} {...props} />
));

const MyConfirmDialog: React.FC<CustomDialogProps> = ({
  open,
  title,
  description,
  onApproved,
  onClose,
}) => {
  if (!open) {
    return null;
  }

  return createPortal(
    <Dialog
      open={open}
      onClose={onClose}
      disableEscapeKeyDown
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      TransitionComponent={Transition}
    >
      <DialogTitle id='alert-dialog-title'>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>
          {description}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancelar</Button>
        <Button onClick={onApproved}>Confirmar</Button>
      </DialogActions>
    </Dialog>,
    document.getElementById('my-confirm-dialog')!
  );
};

export default MyConfirmDialog;
