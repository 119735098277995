import React from 'react';
import MuiAlert, { AlertColor } from '@mui/material/Alert';

type Props = {
  severity: AlertColor;
};

const Alert: React.FC<Props> = ({ severity, ...props }) => {
  return (
    <MuiAlert severity={severity} elevation={6} variant='filled' {...props} />
  );
};

export default Alert;
