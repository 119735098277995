import React, { useEffect, useRef } from 'react';
import { LoadingButton } from '@mui/lab';
import { Button, Grid } from '@mui/material';
import { IContact } from '@soluticket/shared';
import { Form, Formik, FormikProps } from 'formik';
import { useMutation } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { Card } from '../../components';
import FieldsBuilder from '../../components/Form/FieldsBuilder';
import { UI_MESSAGES } from '../../constants';
import { contactFormFields } from '../../forms';
import { contactSchema } from '../../forms/validation.schemas';
import { updateEventContact } from '../../http/Mutations';
import { queriesKeys, queryClient } from '../../http/Queries';
import { MainState } from '../../store';
import { useNotificationStore } from '../../store/notification.store';
import { formatHttpError } from '../../utils';

const Contact: React.FC<{}> = () => {
  const formRef = useRef<FormikProps<{}>>(null);
  const { handleNotification } = useNotificationStore((state) => state);
  const { contact } = useSelector((state: MainState) => state.event);
  const { mutateAsync, isLoading } = useMutation(updateEventContact);

  useEffect(() => {
    if (contact) {
      Object.keys(contact).forEach((key) => {
        const typedKey = key as keyof IContact;
        if (contact[typedKey]) {
          formRef.current?.setFieldValue(key, contact[typedKey]);
        }
      });
    }
  }, [contact]);

  const submitHandler = async (values: any) => {
    try {
      await mutateAsync(values);
      queryClient.invalidateQueries(queriesKeys.fetchEventDetails());
      handleNotification(UI_MESSAGES.SUCCESS, 200);
    } catch (error: any) {
      const message = formatHttpError(error);
      handleNotification(message, 400);
    }
  };

  return (
    <Card>
      <Formik
        innerRef={formRef}
        initialValues={{
          organizer: '',
          email: '',
          phone: '',
          celphone: '',
          webpage: '',
          facebook: '',
          twitter: '',
          instagram: '',
        }}
        validationSchema={contactSchema}
        onSubmit={submitHandler}
      >
        {({ isValid }) => (
          <Form>
            <Grid container spacing={3}>
              <FieldsBuilder formFields={contactFormFields} />
              <Grid container item spacing={3} md={12}>
                <Grid item>
                  <Button variant='contained' color='secondary' type='submit'>
                    Cancelar
                  </Button>
                </Grid>
                <Grid item>
                  <LoadingButton
                    loading={isLoading}
                    disabled={!isValid}
                    variant='contained'
                    color='primary'
                    type='submit'
                  >
                    Guardar
                  </LoadingButton>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Card>
  );
};

export default Contact;
