import React from 'react';
import { Field, Form, Formik } from 'formik';
import TextFormField from '../../components/Form/TextFormField';
import { useMutation } from '@tanstack/react-query';
import { register } from '../../http/Mutations';
import { registerSchema } from '../../forms/validation.schemas';
import { useNotificationStore } from '../../store/notification.store';
import { LoadingButton } from '@mui/lab';

const Signup: React.FC = () => {
  const handleNotification = useNotificationStore(
    (state) => state.handleNotification
  );
  const { mutate: mSignup, isLoading } = useMutation(register, {
    onSuccess: () => {
      handleNotification(
        'Tu cuenta se creo con éxito, nuestro equipo revisara la solicitud y se pondrá en contacto contigo para confirmar tu cuenta',
        200
      );
    },
    onError: (err: any) => {
      handleNotification(
        err?.response?.data?.message ?? 'Favor de intentar de nuevo',
        400
      );
    },
  });

  return (
    <div>
      <Formik
        initialValues={{
          email: '',
          name: '',
          lastName: '',
          password: '',
          phone: '',
        }}
        onSubmit={(values) => {
          mSignup(values);
        }}
        validationSchema={registerSchema}
      >
        {() => (
          <Form>
            <Field name='email' label='Correo' component={TextFormField} />
            <Field name='name' label='Nombre(s)' component={TextFormField} />
            <Field
              name='lastName'
              label='Apellido(s)'
              component={TextFormField}
            />
            <Field name='phone' label='Télefono' component={TextFormField} />
            <Field
              name='password'
              label='Contraseña'
              type='password'
              component={TextFormField}
            />
            <LoadingButton
              loading={isLoading}
              color='primary'
              variant='contained'
              type='submit'
            >
              Crear cuenta
            </LoadingButton>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default Signup;
