import React, { useState } from 'react';
import { isInteger, ITicket } from '@soluticket/shared';
import {
  Button,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

type ModifyTicketFormProps = {
  tickets: Array<ITicket>;
  isLoading: boolean;
  onCloseForm: () => void;
  onModifyPrice: (newPrice: number) => void;
  onReserve: (payload: { willReserve: boolean; reservedFor: string }) => void;
  onGenerateCourtesyCode: (reservedFor: string) => void;
  onBlockTickets: () => void;
  onUnblockTickets: () => void;
};

type ACTION =
  | 'NONE'
  | 'UPDATE_PRICE'
  | 'RESERVE'
  | 'GENERATE_COURTESY_CODE'
  | 'BLOCK'
  | 'UNBLOCK';

const ModifyTicketForm: React.FC<ModifyTicketFormProps> = ({
  tickets,
  isLoading,
  onModifyPrice,
  onReserve,
  onCloseForm,
  onGenerateCourtesyCode,
  onBlockTickets,
  onUnblockTickets,
}) => {
  const [action, setAction] = useState<ACTION>('NONE');
  const [price, setPrice] = useState(tickets[0].price);
  const [reservedFor, setReservedFor] = useState('');
  const labels = tickets.map((ticket) => ticket.label).join(',');

  const updateHandler = () => {
    switch (action) {
      case 'UPDATE_PRICE':
        onModifyPrice(price!);
        break;
      case 'RESERVE':
        onReserve({ willReserve: true, reservedFor });
        break;
      case 'GENERATE_COURTESY_CODE':
        onGenerateCourtesyCode(reservedFor);
        break;
      case 'BLOCK':
        onBlockTickets();
        break;
      case 'UNBLOCK':
        onUnblockTickets();
        break;
      default:
        break;
    }
  };

  return (
    <>
      <span style={{ maxWidth: '250px' }}>Boleto seleccionado: {labels}</span>
      <Grid container flexDirection='column'>
        <Grid item container flexDirection='column'>
          <Select
            value={action}
            onChange={(event: SelectChangeEvent<ACTION>) => {
              const action = event.target.value as ACTION;
              setAction(action);
            }}
          >
            <MenuItem value='NONE'>Selecciona una opción</MenuItem>
            <MenuItem value='UPDATE_PRICE'>Actualizar precio</MenuItem>
            <MenuItem value='RESERVE'>Reservar</MenuItem>
            <MenuItem value='GENERATE_COURTESY_CODE'>
              Generar código de cortesía
            </MenuItem>
            <MenuItem value='BLOCK'>Bloquear</MenuItem>
            <MenuItem value='UNBLOCK'>Desbloquear</MenuItem>
          </Select>
        </Grid>
        {action === 'UPDATE_PRICE' && (
          <Grid item container flexDirection='column'>
            <label htmlFor='priceTextField'>Precio</label>
            <TextField
              name='priceTextField'
              value={price}
              onChange={(evt) => {
                const { value } = evt.target;
                if (value.trim() === '') {
                  setPrice(0);
                }
                if (isInteger(value)) {
                  setPrice(parseInt(value));
                }
              }}
            />
          </Grid>
        )}
        {(action === 'RESERVE' || action === 'GENERATE_COURTESY_CODE') && (
          <Grid item container flexDirection='column'>
            <label htmlFor='reservedFor'>Reservar para</label>
            <TextField
              name='reservedFor'
              value={reservedFor}
              onChange={(evt) => {
                const { value } = evt.target;
                setReservedFor(value);
              }}
            />
          </Grid>
        )}

        <Grid container item mt={2} gap={2}>
          <Button variant='contained' onClick={onCloseForm}>
            Cerrar
          </Button>
          <LoadingButton
            loading={isLoading}
            variant='outlined'
            onClick={() => {
              updateHandler();
            }}
          >
            Actualizar
          </LoadingButton>
        </Grid>
      </Grid>
    </>
  );
};

export default ModifyTicketForm;
