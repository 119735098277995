import CreateEvent from '../screens/CreateEvent';
import Admin from '../screens/Admin';
import Login from '../screens/Login/Login';
import { Navigate, Route } from '@tanstack/react-location';
import { MainState } from '../store';
import { useSelector } from 'react-redux';
import ScreensRouter from './ScreensRouter';
import Reports from '../screens/Reports/Reports';
import ReportsRouter from './ReportsRouter';

type GuardRouteProps = { children: JSX.Element };
const GuardRoute: React.FC<GuardRouteProps> = ({ children }) => {
  const { isAuth } = useSelector((state: MainState) => state.ui);

  if (!isAuth) {
    return <Navigate to='/login' />;
  }
  return children;
};

const MainRouter: Array<Route> = [
  {
    path: 'create',
    element: (
      <GuardRoute>
        <CreateEvent />
      </GuardRoute>
    ),
  },
  {
    path: 'admin',
    element: (
      <GuardRoute>
        <Admin />
      </GuardRoute>
    ),
    children: ScreensRouter,
  },
  {
    path: 'reports',
    element: (
      <GuardRoute>
        <Reports />
      </GuardRoute>
    ),
    children: ReportsRouter,
  },
  { path: '', element: <Login /> },
  { path: '*', element: <Navigate to='/' /> },
];

export default MainRouter;
