import { z } from 'zod';
import { WORKER_PERMISSIONS, WorkerType } from '../enums';

export const userLoginDTO = z.object({
  email: z.string().email(),
  password: z.string().min(6).max(30),
});
export type userLoginType = z.infer<typeof userLoginDTO>;

export const workerDTO = z.object({
  id: z.number().optional(),
  username: z.string().min(3).max(50),
  password: z.string().max(20).optional(),
  type: z.nativeEnum(WorkerType),
  ticketTypes: z.string().array().optional(),
  permissions: z.array(z.nativeEnum(WORKER_PERMISSIONS)).optional(),
});
export type workerType = z.infer<typeof workerDTO>;

export const userRegisterDTO = z.object({
  name: z.string().min(3).max(60),
  lastName: z.string().min(3).max(60),
  phone: z.string().max(10).min(10),
  email: z.string().email(),
  password: z.string().min(6).max(20),
});
export type userRegisterType = z.infer<typeof userRegisterDTO>;

export const verifyUserCodeDTO = z.object({
  verificationCode: z.string().min(1),
  email: z.string().email(),
});
export type verifyUserCodeType = z.infer<typeof verifyUserCodeDTO>;
