import React from 'react';
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import { FieldProps, getIn } from 'formik';

export interface Option {
  label: string;
  value: string;
}

const RadioFormField: React.FC<
  FieldProps & {
    label: string;
    options: Array<Option>;
  }
> = ({ field, form, label, options, ...props }) => {
  const errorText =
    getIn(form.touched, field.name) && getIn(form.errors, field.name);

  return (
    <FormControl component='fieldset' fullWidth error={!!errorText}>
      <FormLabel component='legend'>{label}</FormLabel>
      <RadioGroup row aria-label='radiobutton' {...field} {...props}>
        {options.map((option) => (
          <FormControlLabel
            key={option.value}
            value={option.value}
            label={option.label}
            control={<Radio color='primary' />}
          />
        ))}
      </RadioGroup>
      <FormHelperText>{errorText}</FormHelperText>
    </FormControl>
  );
};

export default RadioFormField;
