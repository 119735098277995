import { z } from 'zod';
import { EventTypes, PaymentMethod } from '../enums';

const eventName = z.string().min(2).max(50).trim();

export const eventDTO = z.object({
  eventName: eventName,
});
export type EventContractType = z.infer<typeof eventDTO>;

export const contactDTO = z.object({
  organizer: z.string().min(1).max(50),
  email: z.string().email(),
  phone: z.string().min(10).max(10),
  celphone: z.string().min(10).max(10),
  webpage: z.string().url().nullish().or(z.literal('')), // TODO: modify because rightnow I need to put https://
  facebook: z.string().url().nullish().or(z.literal('')),
  twitter: z.string().url().nullish().or(z.literal('')),
  instagram: z.string().url().nullish().or(z.literal('')),
});
export type contactType = z.infer<typeof contactDTO>;

export const sponsorDTO = z.object({
  id: z.number().nullish().or(z.literal(0)),
  name: z.string().min(3).max(50),
  link: z.string().url().nullish().or(z.literal('')),
  logo: z.any(), // No need to validate on incoming request
});
export type sponsorType = z.infer<typeof sponsorDTO>;

export const createGeneralDataDTO = z.object({
  eventName: eventName.optional(),
  startDate: z.string(),
  startDateFormatted: z.string(),
  eventType: z.nativeEnum(EventTypes),
  description: z.string().max(15000).nullish().or(z.literal('')),
  venueId: z
    .string()
    .regex(/^[1-9]\d*$/)
    .or(z.number())
    .or(z.literal(''))
    .or(z.literal('null'))
    .nullish(),
  state: z.string().max(50),
  city: z.string().max(50),
  address: z.string().max(100),
  videoUrl: z.string().url().nullish().or(z.literal('')),
});
export type createGeneralDataType = z.infer<typeof createGeneralDataDTO>;

export const createLocalPaymentDTO = z.object({
  eventId: z.number().min(1),
  tickets: z.number().array().min(1),
  cashClosingId: z.number().min(1),
  paymentMethod: z.nativeEnum(PaymentMethod),
  isCourtesy: z.boolean().optional(),
  reservedFor: z.string().optional(),
  couponCode: z.string().optional(),
  client: z
    .object({
      email: z.string(),
      name: z.string(),
      phone: z.string().min(10).max(10),
      countryCode: z.string(),
    })
    .optional(),
  deliverMethod: z.enum(['email', 'print']),
});
export type createLocalPaymentType = z.infer<typeof createLocalPaymentDTO>;

export const sendEmailDTO = z.object({
  eventName: eventName,
  client: z.object({
    id: z.string().min(1),
    name: z.string().optional(),
    email: z.string().email().optional(),
  }),
  ticketsIds: z.number().array().min(1),
  overrideEmail: z.string().email().optional(),
});
export type sendEmailType = z.infer<typeof sendEmailDTO>;

export const createPaymentDTO = z.object({
  tickets: z.number().array().min(1),
  email: z.string().email(),
  fullName: z.string().min(3).max(60),
  clientId: z.string().optional(),
});
export type createPaymentType = z.infer<typeof createPaymentDTO>;

export const forgotPasswordDTO = z.object({
  to: z.string().email(),
  url: z.string().url(),
});
export type forgotPasswordType = z.infer<typeof forgotPasswordDTO>;
