import React from 'react';
import { Grid } from '@mui/material';
import { buildCurrency } from '@soluticket/shared';
import { Card } from '../../components';

export type CurrentBalance = {
  name: string;
  amount: number;
};

const mapTitles = new Map();

mapTitles.set('total', 'Total');
mapTitles.set('witholding', 'Retenciones');
mapTitles.set('availableAmount', 'Cantidad disponible');
mapTitles.set('transfers', 'Transferencias');

const BalanceItem: React.FC<CurrentBalance> = ({ name, amount }) => {
  return (
    <Grid item md={6} sm={12} xs={12} style={{ height: '200px' }}>
      <Card style={{ height: '100%' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <h2>{mapTitles.get(name)}</h2>
          <p style={{ fontSize: '30px', margin: 0 }}>{buildCurrency(amount)}</p>
        </div>
      </Card>
    </Grid>
  );
};

export default BalanceItem;
