import React from 'react';
import { Button, Grid, IconButton } from '@mui/material';
import { Field, FieldArray, FieldArrayRenderProps, Form, Formik } from 'formik';
import DeleteIcon from '@mui/icons-material/Delete';
import { Card } from '../../components';
import TextFormField from '../../components/Form/TextFormField';
import { sponsorSchema } from '../../forms/validation.schemas';
import { useSelector } from 'react-redux';
import { MainState } from '../../store';
import { updateEventSponsors } from '../../http/Mutations';
import { useMutation } from '@tanstack/react-query';
import { queriesKeys, queryClient } from '../../http/Queries';
import { UI_MESSAGES } from '../../constants';
import { LoadingButton } from '@mui/lab';
import { useNotificationStore } from '../../store/notification.store';
import FileFormField from '../../components/Form/FileFormField';
import { formatHttpError } from '../../utils';

type Sponsor = {
  id?: number;
  name: string;
  link: string;
  logo: File | null;
};

const emptySponsor: Sponsor[] = [
  {
    name: '',
    link: '',
    logo: null,
  },
];

const Sponsors: React.FC<{}> = () => {
  const { handleNotification } = useNotificationStore((state) => state);
  const { sponsors = emptySponsor } = useSelector(
    (state: MainState) => state.event
  );
  const { mutate, isLoading } = useMutation(updateEventSponsors, {
    onSuccess: () => {
      queryClient.invalidateQueries(queriesKeys.fetchEventDetails());
      handleNotification(UI_MESSAGES.SUCCESS, 200);
    },
    onError: (error: any) => {
      const message = formatHttpError(error);
      handleNotification(message, 400);
    },
  });

  return (
    <Card id='sponsors-card'>
      <Formik
        initialValues={{
          sponsors: [...sponsors],
        }}
        validationSchema={sponsorSchema}
        onSubmit={(values) => {
          const formData = new FormData();
          // 1 put them in the form data with id "files"
          values.sponsors.forEach((sponsor) => {
            if (sponsor.logo instanceof File) {
              const { logo } = sponsor;
              const fileExtension = logo.name.split('.').pop();
              formData.append(
                'files',
                logo as File,
                `${sponsor.name}.${fileExtension}`
              );
            }
          });
          // 2 add to the form data the rest of elements as JSON.stringify()
          formData.append('sponsors', JSON.stringify(values.sponsors));
          // 3 parse the JSON in the server and validate on the SERVER again
          mutate(formData);
        }}
      >
        {({ values, isValid }) => (
          <Form>
            <Grid container>
              <FieldArray name='sponsors'>
                {(arrayHelpers: FieldArrayRenderProps) => (
                  <>
                    <Button
                      variant='outlined'
                      color='primary'
                      onClick={() => {
                        if (arrayHelpers.form.isValid) {
                          arrayHelpers.push({
                            name: '',
                            link: '',
                            logo: '',
                          });
                        }
                      }}
                    >
                      Agregar patrocinador
                    </Button>
                    {values.sponsors.map((sponsor, index) => {
                      return (
                        <Grid
                          container
                          spacing={3}
                          key={
                            sponsor.id ? `${sponsor.id}${sponsor.name}` : index
                          }
                          alignItems='center'
                        >
                          <Grid item md={4} sm={5} xs={12}>
                            <Field
                              label='Nombre del patrocinador'
                              name={`sponsors.${index}.name`}
                              component={TextFormField}
                            />
                          </Grid>
                          <Grid item md={4} sm={5} xs={12}>
                            <Field
                              label='Página del patrocinador'
                              name={`sponsors.${index}.link`}
                              component={TextFormField}
                            />
                          </Grid>
                          <Grid item md={3} sm={5} xs={12}>
                            <Field
                              label='Logo'
                              name={`sponsors.${index}.logo`}
                              component={FileFormField}
                            />
                          </Grid>
                          <Grid item md={1}>
                            <IconButton
                              onClick={() => void arrayHelpers.remove(index)}
                              aria-label='delete'
                              size='large'
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Grid>
                        </Grid>
                      );
                    })}
                  </>
                )}
              </FieldArray>
              <Grid container item spacing={3} md={12}>
                <Grid item>
                  <Button variant='contained' color='secondary' type='button'>
                    Cancelar
                  </Button>
                </Grid>
                <Grid item>
                  <LoadingButton
                    loading={isLoading}
                    disabled={!isValid}
                    variant='contained'
                    color='primary'
                    type='submit'
                  >
                    Guardar
                  </LoadingButton>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Card>
  );
};

export default Sponsors;
