import React, { useState } from 'react';
import { Button, Grid, LinearProgress } from '@mui/material';
import { Card } from '../../components';
import { useQuery } from '@tanstack/react-query';
import {
  fetchGrid,
  fetchTicketsTypes,
  fetchVenueUrl,
  queriesKeys,
} from '../../http/Queries';
import {
  ITicket,
  ITicketType,
  NotNumberedTicketsTotals,
  TicketStatus,
} from '@soluticket/shared';
import GridModifyTicket, {
  TRANSLATIONS,
  TICKET_COLORS_BG,
} from '../../components/GridModifyTicket';
import VenueDisplay from '../Tickets/VenueDisplay';

const getColorsIndicatorsContent = () => {
  const colors: React.ReactNode[] = [];
  // @ts-ignore
  for (const [key, value] of TICKET_COLORS_BG) {
    colors.push(
      <Grid item key={key} ml={1} px={1} style={{ backgroundColor: value }}>
        {TRANSLATIONS.get(key as TicketStatus)}
      </Grid>
    );
  }
  return <Grid container>{colors}</Grid>;
};

const TicketsMap: React.FC = () => {
  const [ticketTypeId, setTicketTypeId] = useState(0);
  const { data: ticketTypes = [] } = useQuery(
    queriesKeys.fetchTicketsTypes(),
    fetchTicketsTypes
  );
  const {
    data: fetchedGrid,
    isLoading: loadingGrid,
    isFetching,
  } = useQuery(
    queriesKeys.fetchGrid(ticketTypeId),
    () => fetchGrid(+ticketTypeId),
    { enabled: ticketTypeId > 0 }
  );
  const qVenueUrl = useQuery(queriesKeys.fetchVenueUrl(), fetchVenueUrl, {
    // refetchOnWindowFocus: false,
  });

  const handleChange = (ticketType: ITicketType) => {
    setTicketTypeId(ticketType.id!);
  };

  const ticketType = ticketTypes.find((item) => item.id === ticketTypeId);

  const getGridContent = () => {
    if (loadingGrid && isFetching) {
      return <LinearProgress />;
    }

    if (!ticketType) {
      return (
        <VenueDisplay
          ticketTypes={ticketTypes}
          venueUrl={qVenueUrl.data || ''}
          onSelectTicketType={handleChange}
        />
      );
    }

    if (!ticketType?.isNumbered && fetchedGrid) {
      const { availableTickets, soldTickets, verifiedTickets } =
        fetchedGrid as NotNumberedTicketsTotals;
      return (
        <div>
          <p>
            Boletos disponibles:{' '}
            <span style={{ fontWeight: 'bold' }}>{availableTickets}</span>{' '}
          </p>
          <p>
            Boletos vendidos:{' '}
            <span style={{ fontWeight: 'bold' }}>{soldTickets}</span>
          </p>
          <p>
            Boletos verificados:{' '}
            <span style={{ fontWeight: 'bold' }}>{verifiedTickets}</span>
          </p>
        </div>
      );
    }

    if (ticketType?.isNumbered && fetchedGrid) {
      return (
        <GridModifyTicket
          existingGrid={fetchedGrid as ITicket[][]}
          enableActions={false}
        />
      );
    }
    return null;
  };

  return (
    <Grid display='flex' flexDirection='column'>
      <h2>Mapa de boletos</h2>
      <Grid container flexDirection='column' rowSpacing={2}>
        <Grid item container flexDirection='column'>
          <Grid item container flexDirection='column'>
            <Card>
              <p>Indicadores de estados</p>
              <div style={{ marginBottom: '10px' }}>
                {getColorsIndicatorsContent()}
              </div>
            </Card>
          </Grid>
        </Grid>
        <Grid item container>
          <Card style={{ width: '100%' }}>
            {ticketTypeId > 0 && (
              <div
                style={{
                  marginBottom: '10px',
                }}
              >
                <Button
                  variant='outlined'
                  onClick={() => {
                    setTicketTypeId(0);
                  }}
                >
                  Regresar al venio
                </Button>
                <h2>{ticketType?.name}</h2>
              </div>
            )}
            {getGridContent()}
          </Card>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TicketsMap;
