import React, { useMemo, useState } from 'react';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { fetchSalesByTicketType, queriesKeys } from '../../../http/Queries';
import {
  buildCSV,
  COLUMN_TRANSLATIONS,
  DASHBOARD_REPORT_KEYS,
  TicketTypesSummary,
} from '@soluticket/shared';

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof TicketTypesSummary;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: 'ticketTypeName',
    numeric: false,
    disablePadding: true,
    label: 'Tipo de boleto',
  },
  {
    id: 'price',
    numeric: true,
    disablePadding: true,
    label: 'Precio boleto',
  },
  {
    id: 'amountOfTicketsSold',
    numeric: true,
    disablePadding: false,
    label: 'Vendidos',
  },
  {
    id: 'total',
    numeric: true,
    disablePadding: false,
    label: 'Total',
  },
  {
    id: 'amountTicketsAvailable',
    numeric: true,
    disablePadding: false,
    label: 'Disponibles',
  },
  {
    id: 'amountTicketsBlocked',
    numeric: true,
    disablePadding: false,
    label: 'Bloqueados',
  },
  {
    id: 'amountTicketsReserved',
    numeric: true,
    disablePadding: false,
    label: 'Reservados',
  },
  {
    id: 'amountTicketsCourtesyClaimed',
    numeric: true,
    disablePadding: false,
    label: 'Cortesias Reclamadas',
  },
  {
    id: 'amountTicketsCourtesyPending',
    numeric: true,
    disablePadding: false,
    label: 'Cortesias Pendientes',
  },
  {
    id: 'amountTicketsPicked',
    numeric: true,
    disablePadding: false,
    label: 'Pendientes',
  },
  {
    id: 'amountTicketsWaitingForPayment',
    numeric: true,
    disablePadding: false,
    label: 'Pendientes de pago',
  },
  {
    id: 'amountVerified',
    numeric: true,
    disablePadding: false,
    label: 'Verificados',
  },
];

interface EnhancedTableProps {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof TicketTypesSummary
  ) => void;

  order: Order;
  orderBy: string;
}

const footerStyle: React.CSSProperties = {
  fontWeight: 'bold',
  fontSize: '1.2rem',
};

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler =
    (property: keyof TicketTypesSummary) =>
    (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id.toString()}
            align='center'
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export const buildCurrency = (total?: number | string) => {
  let correctTotal = 0;
  if (typeof total === 'string') {
    correctTotal = parseFloat(total);
  } else if (typeof total === 'number') {
    correctTotal = total;
  } else {
    correctTotal = 0;
  }

  const value = new Intl.NumberFormat('es-MX', {
    style: 'currency',
    currency: 'MXN',
  }).format(correctTotal);
  return value;
};

export const TicketsStatusTable = (props: { email?: string }) => {
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] =
    useState<keyof TicketTypesSummary>('ticketTypeName');
  const qSalesByTicketType = useQuery(
    queriesKeys.fetchSalesByTicketType(),
    fetchSalesByTicketType
  );

  const handleRequestSort = (
    _: React.MouseEvent<unknown>,
    property: keyof TicketTypesSummary
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  // Avoid a layout jump when reaching the last page with empty rows.

  const visibleRows = useMemo(
    () =>
      stableSort(
        qSalesByTicketType?.data?.ticketTypes ?? [],
        getComparator(order, orderBy)
      ),
    [order, orderBy, qSalesByTicketType?.data?.ticketTypes]
  );

  if (qSalesByTicketType.isLoading && qSalesByTicketType.isFetching) {
    return <div>Cargando...</div>;
  }

  if (!!qSalesByTicketType.error) {
    return <div>Error inesperado</div>;
  }

  const downloadCSVHandler = () => {
    const ticketTypes = qSalesByTicketType.data?.ticketTypes ?? [];
    if (!ticketTypes) {
      return;
    }

    const csv = buildCSV(
      ticketTypes,
      DASHBOARD_REPORT_KEYS,
      COLUMN_TRANSLATIONS.REPORTS.DASHBOARD
    );
    const blob = new Blob([csv], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('href', url);
    a.setAttribute('download', 'reporte.csv');
    a.click();
    a.remove();
  };

  return (
    <>
      {props.email === 'fortunato@soluticket.mx' && (
        <Button variant='contained' onClick={downloadCSVHandler}>
          Descargar CSV
        </Button>
      )}
      <TableContainer
        sx={{
          maxHeight: 'calc(100vh - 300px)',
        }}
      >
        <Table
          sx={{ minWidth: 650 }}
          aria-label='Ventas por tipo de boleto'
          stickyHeader
          style={{ position: 'relative' }}
        >
          <EnhancedTableHead
            order={order}
            orderBy={orderBy.toString()}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {visibleRows.length < 1 && (
              <TableRow>
                <TableCell colSpan={6} />
              </TableRow>
            )}
            {visibleRows.map((row) => (
              <TableRow
                key={`${row.ticketTypeId}-${row.price}`}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component='th' scope='row'>
                  {row.ticketTypeName}
                </TableCell>
                <TableCell component='th' scope='row'>
                  {buildCurrency(row.price)}
                </TableCell>
                <TableCell align='center'>{row.amountOfTicketsSold}</TableCell>
                <TableCell align='center'>{buildCurrency(row.total)}</TableCell>
                <TableCell align='center'>
                  {row.amountTicketsAvailable}
                </TableCell>
                <TableCell align='center'>{row.amountTicketsBlocked}</TableCell>
                <TableCell align='center'>
                  {row.amountTicketsReserved}
                </TableCell>
                <TableCell align='center'>
                  {row.amountTicketsCourtesyClaimed}
                </TableCell>
                <TableCell align='center'>
                  {row.amountTicketsCourtesyPending}
                </TableCell>
                <TableCell align='center'>{row.amountTicketsPicked}</TableCell>
                <TableCell align='center'>
                  {row.amountTicketsWaitingForPayment}
                </TableCell>
                <TableCell align='center'>{row.amountVerified}</TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter
            style={{
              left: 0,
              bottom: 0, // <-- KEY
              zIndex: 2,
              position: 'sticky',
              backgroundColor: 'white',
            }}
          >
            <TableRow>
              <TableCell component='th' scope='row' style={footerStyle}>
                Totales
              </TableCell>
              <TableCell component='th' scope='row' style={footerStyle}>
                -
              </TableCell>
              <TableCell align='center' style={footerStyle}>
                {qSalesByTicketType?.data?.summary.ticketsSold}
              </TableCell>
              <TableCell align='center' style={footerStyle}>
                {buildCurrency(qSalesByTicketType?.data?.summary.total)}
              </TableCell>
              <TableCell align='center' style={footerStyle}>
                {qSalesByTicketType?.data?.summary.totalTicketsAvailable}
              </TableCell>
              <TableCell align='center' style={footerStyle}>
                {qSalesByTicketType?.data?.summary.totalTicketsBlocked}
              </TableCell>
              <TableCell align='center' style={footerStyle}>
                {qSalesByTicketType?.data?.summary.totalTicketsReserved}
              </TableCell>
              <TableCell align='center' style={footerStyle}>
                {qSalesByTicketType?.data?.summary.totalTicketsCourtesyClaimed}
              </TableCell>
              <TableCell align='center' style={footerStyle}>
                {qSalesByTicketType?.data?.summary.totalTicketsCourtesyPending}
              </TableCell>
              <TableCell align='center' style={footerStyle}>
                {qSalesByTicketType?.data?.summary.totalTicketsPicked}
              </TableCell>
              <TableCell align='center' style={footerStyle}>
                {
                  qSalesByTicketType?.data?.summary
                    .totalTicketsWaitingForPayment
                }
              </TableCell>
              <TableCell align='center' style={footerStyle}>
                {qSalesByTicketType?.data?.summary.totalVerified}
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  );
};
