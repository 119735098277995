import { Button } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import React, { useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import TextFormField from '../../components/Form/TextFormField';
import { loginSchema } from '../../forms/validation.schemas';
import { login } from '../../http/Mutations';
import { uiActions } from '../../store/ui.slice';
import { useNavigate } from '@tanstack/react-location';

const Signin: React.FC<{}> = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const mutation = useMutation((payload) => login(payload));
  const [error, setError] = useState<string>('');

  const onSubmit = async (values: any) => {
    try {
      const { data } = await mutation.mutateAsync(values);
      if (data.accessToken) {
        dispatch(uiActions.setIsAuth());
        localStorage.setItem('token', data.accessToken);
        navigate({ to: '/create' });
      }
    } catch (err: any) {
      console.log(err.response?.data?.message);
      setError(err?.response?.data?.message ?? 'Favor de intentar de nuevo');
    }
  };

  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
      }}
      onSubmit={(values) => onSubmit(values)}
      validationSchema={loginSchema}
    >
      {() => (
        <Form>
          <Field name='email' label='Correo' component={TextFormField} />
          <Field
            name='password'
            label='Contraseña'
            type='password'
            component={TextFormField}
          />
          {error && <p>{error}</p>}
          <Button color='primary' variant='contained' type='submit'>
            Iniciar sesión
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default Signin;
