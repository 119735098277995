import React, { useRef, useState } from 'react';
import { Button, FormControl, FormHelperText, Typography } from '@mui/material';
import { FieldProps, getIn } from 'formik';
import Thumb from '../Thumb';

type FileType = {
  target: {
    files: Array<File>;
  };
};

const validExtensions = new Set([
  'jpeg',
  'png',
  'jpg',
  'svg',
  'JPEG',
  'PNG',
  'JPG',
  'SVG',
]);

type Props = {
  label: string;
  onChangeCustom: (evt: FileType) => {};
};

const FileFormField: React.FC<FieldProps & Props> = ({
  field,
  form,
  onChangeCustom,
  ...props
}) => {
  const fileRef = useRef<HTMLInputElement>(null);
  const errorText =
    getIn(form.touched, field.name) && getIn(form.errors, field.name);

  const [file, setFile] = useState<File | null>();

  const fileSelectedHandler = (event: FileType) => {
    const [file] = event.target.files;
    if (!file) {
      setFile(null);
      form.setFieldValue(field.name, null);
      return;
    }

    const extension = file.name.split('.').pop();
    const isValid = validExtensions.has(extension!);

    if (isValid) {
      setFile(file);
      form.setFieldValue(field.name, file);
      onChangeCustom(event);
    } else {
      form.setFieldValue(field.name, null);
      setFile(null);
    }
  };

  const removeImageHandler = () => {
    setFile(null);
    form.setFieldValue(field.name, null);
    if (fileRef.current) {
      fileRef.current.value = '';
    }
  };

  return (
    <FormControl fullWidth error={!!errorText}>
      <label htmlFor={field.name}>{props.label}</label>
      <input
        {...props}
        ref={fileRef}
        id={field.name}
        name={field.name}
        type='file'
        onChange={(event: any) => fileSelectedHandler(event)}
        hidden
      />
      {file && <Typography>{file.name}</Typography>}
      <Thumb
        onRemove={removeImageHandler}
        file={file!}
        existingFileUrl={
          typeof field.value === 'string' ? field.value : undefined
        }
      />
      <FormHelperText>{errorText}</FormHelperText>
      <Button
        variant='contained'
        color='secondary'
        type='button'
        onClick={() => fileRef.current?.click()}
      >
        Seleccionar archivo
      </Button>
    </FormControl>
  );
};

export default FileFormField;
