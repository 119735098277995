export enum TransactionStatus {
  SUCCESS = 'SUCCESS',
  CANCELLED = 'CANCELLED', // user selected CANCEL transaction in config.soluticket
  EXPIRED = 'EXPIRED', // The transaction has expired (5 minutes or more(depends on config))
  PENDING = 'PENDING', // Boletos elegidos por el cliente -> antes de redireccionar a la pasarela de pago cambiar a PENDING_PAYMENT
  RESERVATION = 'RESERVATION', // This transaction will be a reservation
  COURTESY = 'COURTESY', // This transaction will be a courtesy
  PENDING_PAYMENT = 'PENDING_PAYMENT', // Boletos en espera de ser pagados en metodos de pagos que no son de 24hrs
  PENDING_PAYMENT_24H = 'PENDING_PAYMENT_24H', // Oxxo pago
  FAILED = 'FAILED',
}

export const transactionStatusTranslations = new Map([
  [TransactionStatus.SUCCESS, 'Exitoso'],
  [TransactionStatus.CANCELLED, 'Cancelada'],
  [TransactionStatus.PENDING, 'Pendiente'],
  [TransactionStatus.FAILED, 'Fallidas'],
]);
