import { Option } from '../types';
import {
  EventTypes,
  PERMISSIONS_TRANSLATIONS,
  TimeZones,
  TransactionStatus,
  transactionStatusTranslations,
} from '../enums';

export const buildCurrency = (amount: number) => {
  try {
    return new Intl.NumberFormat('es-MX', {
      style: 'currency',
      currency: 'MXN',
    }).format(amount);
  } catch (err) {
    return 'invalid number';
  }
};

/**
 * Return string with format dd/MM/yyyy hh:mm
 * @param date Date object
 */
export const formatDate = (date: Date) => {
  const day = date.getDate();
  let month = (date.getMonth() + 1).toString();
  const year = date.getFullYear();
  let hour = date.getHours().toString();
  let min = date.getMinutes().toString();

  const addZero = (datePiece: string) => {
    return datePiece.length > 1 || datePiece.startsWith('0')
      ? datePiece
      : `0${datePiece}`;
  };

  month = addZero(month);
  hour = addZero(hour);
  min = addZero(min);

  return `${day}/${month}/${year} ${hour}:${min}`;
};

export const getEventTypes = (): Array<Option> => {
  const options = [];
  for (const key in EventTypes) {
    const typedKey = key as keyof typeof EventTypes;
    options.push({
      value: key,
      label: EventTypes[typedKey],
    });
  }
  return options;
};

export const getTimeZonesTypes = (): Array<Option> => {
  const options = [];
  for (const key in TimeZones) {
    const typedKey = key as keyof typeof TimeZones;
    options.push({
      value: key,
      label: TimeZones[typedKey],
    });
  }
  return options;
};

export const getTransactionStatus = (): Array<Option> => {
  const options = [];
  for (const key in TransactionStatus) {
    const typedKey = key as keyof typeof TransactionStatus;
    options.push({
      value: key,
      label: transactionStatusTranslations.get(TransactionStatus[typedKey])!,
    });
  }
  return options;
};

export const isInteger = (value: string): boolean => {
  return /^\d+$/.test(value);
};

export const roundToTwo = (num: number): number => {
  return Math.round(num * 1e2) / 1e2;
};

export const getPermissions = (): Array<Option> => {
  return PERMISSIONS_TRANSLATIONS.map((permission) => ({
    label: permission.label,
    value: permission.permission,
  }));
};

export const DASHBOARD_REPORT_KEYS = [
  'ticketTypeName',
  'amountOfTicketsSold',
  'total',
  'price',
  'amountTicketsAvailable',
  'amountTicketsBlocked',
  'amountTicketsReserved',
  'amountTicketsCourtesyClaimed',
  'amountTicketsCourtesyPending',
  'amountTicketsPicked',
  'amountTicketsWaitingForPayment',
  'amountVerified',
];

type Translations = {
  [key: string]: string;
};

export const COLUMN_TRANSLATIONS = {
  REPORTS: {
    DASHBOARD: {
      ticketTypeName: 'Tipo de boleto',
      price: 'Precio',
      amountOfTicketsSold: 'Vendidos',
      total: 'Total',
      amountTicketsAvailable: 'Disponibles',
      amountTicketsBlocked: 'Bloqueados',
      amountTicketsReserved: 'Reservados',
      amountTicketsCourtesyClaimed: 'Cortesías reclamadas',
      amountTicketsCourtesyPending: 'Cortesías pendientes',
      amountTicketsPicked: 'Pendientes',
      amountTicketsWaitingForPayment: 'Pedientes de pago',
      amountVerified: 'Verificados',
    },
  },
};

export const buildCSV = <T extends object>(
  data: Array<T>,
  headers: string[],
  translations?: Translations
) => {
  const translatedHeaders = translations
    ? headers.map((header) => translations[header])
    : headers;

  // Create the CSV string with headers
  let csvString = translatedHeaders.join(',') + '\n';

  data.forEach((row: T) => {
    // Extract values from the object based on the provided headers
    const values = headers.map((header) => row[header]);

    // Append the values to the CSV string
    csvString += values.join(',') + '\n';
  });

  // Returning the array joining with new line
  return csvString;
};

export type ZoneNode = {
  properties: {
    id?: string;
  };
  children: ZoneNode[];
};

export const findRecursive = (
  node: ZoneNode,
  name: string
): ZoneNode | null => {
  if (node.properties?.id === name) {
    return node;
  }
  if (node.children) {
    for (const child of node.children) {
      const found = findRecursive(child, name);
      if (found) {
        return found;
      }
    }
  }
  return null;
};

export const getZonesIds = (node: ZoneNode): Array<string> => {
  const zones = findRecursive(node, 'ZONES');
  if (!zones) {
    return [];
  }
  const zonesIds = zones.children.map((zone) => zone.properties!.id!);
  return zonesIds;
};
