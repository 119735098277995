import { ThemeProvider, StyledEngineProvider } from '@mui/material';
import { Router, ReactLocation, Outlet } from '@tanstack/react-location';
import { QueryClientProvider } from '@tanstack/react-query';
import { theme } from './App.theme';
import NotificationHandler from './components/NotificationHandler';
import { queryClient } from './http/Queries';
import MainRouter from './routing/MainRouter';

const location = new ReactLocation();

function App() {
  return (
    // MaterialUi
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        {/* React Query */}
        <QueryClientProvider client={queryClient}>
          <Router routes={MainRouter} location={location}>
            <Outlet />
            <NotificationHandler />
          </Router>
        </QueryClientProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
