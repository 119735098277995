import { create } from 'zustand';

interface NotificationStore {
  notification: {
    code?: number;
    msg: string;
  };
  handleNotification: (msg: string, code?: number) => void;
  removeNotification: () => void;
}

export const useNotificationStore = create<NotificationStore>((set) => ({
  notification: {
    code: 0,
    msg: '',
  },
  handleNotification: (msg: string, code = 0) => {
    set((state) => ({ ...state, notification: { code, msg } }));
  },
  removeNotification: () => {
    set((state) => ({ ...state, notification: { code: 0, msg: '' } }));
  },
}));
