import { Route } from '@tanstack/react-location';
import Events from '../screens/Events/Events';
import Sales from '../screens/Sales/Sales';
import Tickets from '../screens/Tickets/Tickets';
import CreateVenue from '../screens/Venues/CreateVenue';
import EditZones from '../screens/Venues/EditZones';
// import Workers from '../screens/Workers';

const ScreensRouter: Array<Route> = [
  { path: '/', element: <Events /> },
  {
    path: 'venues/edit/:id',
    element: <EditZones />,
  },
  {
    path: 'venues',
    element: <CreateVenue />,
  },

  // { path: 'workers', element: <Workers /> },
  { path: 'balance', element: <Sales /> },
  { path: 'tickets', element: <Tickets /> },
  { path: 'events', element: <Events /> },
];

export default ScreensRouter;
