export enum EventTypes {
  'ObraDeTeatro' = 'Obra de Teatro',
  'Cine' = 'Cine',
  'EventoDeportivo' = 'Evento Deportivo',
  'Circo' = 'Circo',
  'Conferencia' = 'Conferencia',
  'Congreso' = 'Congreso',
  'Concierto' = 'Concierto',
  'EventoEducativo' = 'Evento Educativo',
  'EventoPolítico' = 'Evento Político',
  'EventoReligioso' = 'Evento Religioso',
  'Expo' = 'Expo',
  'Inauguración' = 'Inauguración',
  'FiestaEvento Social' = 'Fiesta / Evento Social',
  'Boda' = 'Boda',
  'Palenque' = 'Palenque',
  'Otro' = 'Otro',
}

export const EventTypesZod = [
  'ObraDeTeatro',
  'Cine',
  'EventoDeportivo',
  'Circo',
  'Conferencia',
  'Congreso',
  'Concierto',
  'EventoEducativo',
  'EventoPolítico',
  'EventoReligioso',
  'Expo',
  'Inauguración',
  'FiestaEvento Social',
  'Boda',
  'Palenque',
  'Otro',
] as const;
