import React, { useEffect, useRef, useState } from 'react';
import { Button, Grid } from '@mui/material';
import { Card, SimpleList, MyConfirmDialog } from '../../components';
import FieldsBuilder from '../../components/Form/FieldsBuilder';
import { Form, Formik, FormikProps } from 'formik';
import { couponsFormFields } from '../../forms';
import { createCouponType, DiscountType, ICoupon } from '@soluticket/shared';
import { useMutation, useQuery } from '@tanstack/react-query';
import { fetchCouponById, fetchCoupons, queriesKeys } from '../../http/Queries';
import { createCoupon, removeCoupon, updateCoupon } from '../../http/Mutations';
import { queryClient } from '../../http/Queries';
import { couponSchema } from '../../forms/validation.schemas';
import { UI_MESSAGES } from '../../constants';
import { useNotificationStore } from '../../store/notification.store';

const Coupons: React.FC<{}> = () => {
  const ref = useRef<FormikProps<{}>>(null);
  const { handleNotification } = useNotificationStore((state) => state);
  const [editMode, setEditMode] = useState(false);
  const [selectedCouponId, setSelectedCouponId] = useState<number>(0);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  // const { data: ticketTypes = [] } = useQuery(
  //   queriesKeys.fetchTicketTypesOptions(),
  //   fetchTicketTypesOptions
  // );
  const { data: couponList = [], isLoading } = useQuery(
    queriesKeys.fetchCoupons(),
    fetchCoupons
  );
  const { data: couponSelected } = useQuery(
    queriesKeys.fetchCouponById(selectedCouponId),
    () => fetchCouponById(selectedCouponId),
    { enabled: selectedCouponId > 0 }
  );
  const { mutate } = useMutation(editMode ? updateCoupon : createCoupon, {
    onSuccess: () => void resetView(),
  });
  const { mutate: mutateDelete } = useMutation(removeCoupon, {
    onSuccess: () => void resetView(),
  });

  // Populate the form.
  useEffect(() => {
    if (couponSelected && !showConfirmDialog) {
      Object.keys(couponSelected).forEach((key) => {
        const typedKey = key as keyof ICoupon;
        ref.current?.setFieldValue(key, couponSelected[typedKey]);
      });
    }
  }, [couponSelected, showConfirmDialog]);

  const selectListItemHandler = (idx: number) => {
    setSelectedCouponId(idx);
    setEditMode(true);
  };

  const openConfirmDialog = (idx: number) => {
    setSelectedCouponId(idx);
    setShowConfirmDialog(true);
  };

  const deleteCoupon = () => {
    mutateDelete(selectedCouponId!);
    setShowConfirmDialog(false);
  };

  const onSubmit = (payload: createCouponType) => {
    const startDate = new Date(payload.startDate);
    payload.startDate = startDate.toISOString();
    mutate(payload);
  };

  const resetView = () => {
    queryClient.invalidateQueries(queriesKeys.fetchCoupons());
    setSelectedCouponId(0);
    setEditMode(false);
    ref.current?.resetForm();
    handleNotification(UI_MESSAGES.SUCCESS, 200);
  };

  if (isLoading) {
    return <p>Cargando</p>;
  }

  return (
    <Grid container spacing={3}>
      <Grid item md={4} xs={12}>
        <Card>
          <Formik
            innerRef={ref}
            initialValues={{
              code: '',
              ticketTypeId: '',
              discountType: DiscountType.PERCENTAGE,
              discount: 0,
              numberOfCodes: 0,
              startDate: new Date(),
            }}
            validationSchema={couponSchema}
            onSubmit={(values) => onSubmit(values as createCouponType)}
          >
            {() => (
              <Form>
                <Grid container spacing={3}>
                  <FieldsBuilder formFields={couponsFormFields} />
                  <Grid container item spacing={3} md={12}>
                    <Grid item>
                      <Button
                        variant='contained'
                        color='secondary'
                        type='button'
                        onClick={() => {
                          ref.current?.resetForm();
                          setEditMode(false);
                          setSelectedCouponId(0);
                        }}
                      >
                        Cancelar
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button variant='contained' color='primary' type='submit'>
                        {editMode ? 'Actualizar' : 'Guardar'}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Card>
      </Grid>
      <Grid item md={8} xs={12}>
        <Card>
          <SimpleList
            data={couponList.map((coupon) => ({
              idx: coupon.id!,
              primary: `Código: ${coupon.code}`,
              secondary: `Fecha de activación: ${coupon.startDate}`,
            }))}
            onSelect={selectListItemHandler}
            onRemove={openConfirmDialog}
          />
        </Card>
      </Grid>
      <MyConfirmDialog
        title={UI_MESSAGES.CONFIRM_ALERT_TITLE}
        description={UI_MESSAGES.REMOVE_RECORD}
        onApproved={deleteCoupon}
        onClose={() => setShowConfirmDialog(false)}
        open={showConfirmDialog}
      />
    </Grid>
  );
};

export default Coupons;
