export enum Estados {
  'AGS' = 'AGUASCALIENTES',
  'BC' = 'BAJA CALIFORNIA',
  'BCS' = 'BAJA CALIFORNIA SUR',
  'CHI' = 'CHIHUAHUA',
  'CHS' = 'CHIAPAS',
  'CMP' = 'CAMPECHE',
  'CMX' = 'CIUDAD DE MEXICO',
  'COA' = 'COAHUILA',
  'COL' = 'COLIMA',
  'DGO' = 'DURANGO',
  'GRO' = 'GUERRERO',
  'GTO' = 'GUANAJUATO',
  'HGO' = 'HIDALGO',
  'JAL' = 'JALISCO',
  'MCH' = 'MICHOACAN',
  'MEX' = 'ESTADO DE MEXICO',
  'MOR' = 'MORELOS',
  'NAY' = 'NAYARIT',
  'NL' = 'NUEVO LEON',
  'OAX' = 'OAXACA',
  'PUE' = 'PUEBLA',
  'QR' = 'QUINTANA ROO',
  'QRO' = 'QUERETARO',
  'SIN' = 'SINALOA',
  'SLP' = 'SAN LUIS POTOSI',
  'SON' = 'SONORA',
  'TAB' = 'TABASCO',
  'TLX' = 'TLAXCALA',
  'TMS' = 'TAMAULIPAS',
  'VER' = 'VERACRUZ',
  'YUC' = 'YUCATAN',
  'ZAC' = 'ZACATECAS',
}
