export const UI_MESSAGES = {
  SUCCESS: 'Operación exitosa',
  UNAUTHORIZED: 'No tienes permisos para editar',
  CONFIRM_ALERT_TITLE: 'Confirmar',
  REMOVE_RECORD: '¿Esta seguro que desea eliminar el registro?',
  DUPLICATE_RECORD: '¿Esta seguro que desea duplicar el registro?',
  TOGGLE_RECORD: '¿Esta seguro que desea cambiar el estado del registro?',
  NO_RECORDS: 'Sin registros',
  UNEXPECTED_ERROR: 'Error inesperado',
};
