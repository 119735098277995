import { useEffect } from 'react';
import { Button, Grid } from '@mui/material';
import { Form, Formik } from 'formik';
import { Card } from '../../components';
import FieldsBuilder, {
  getInitialFormValues,
  initializeFormFieldsWithSelect,
} from '../../components/Form/FieldsBuilder';
import { generalDataformFields, GeneralDataFormKeys } from '../../forms';
import { useSelector } from 'react-redux';
import { MainState } from '../../store';
import { generalDataSchema } from '../../forms/validation.schemas';
import { useMutation, useQuery } from '@tanstack/react-query';
import { updateEventDescription } from '../../http/Mutations';
import {
  fetchVenuesAsOptions,
  queriesKeys,
  queryClient,
} from '../../http/Queries';
import { UI_MESSAGES } from '../../constants';
import { LoadingButton } from '@mui/lab';
import { format } from 'date-fns';
import { useNotificationStore } from '../../store/notification.store';
import { useQuill } from 'react-quilljs';
import 'quill/dist/quill.snow.css'; // Add css for snow theme
import { formatHttpError } from '../../utils';

// import Map from '../../components/Map'; // Google maps

const formats = [
  'background',
  'bold',
  'color',
  'font',
  'code',
  'italic',
  'link',
  'size',
  'strike',
  'script',
  'underline',
  'blockquote',
  'header',
  'indent',
  'list',
  'align',
  'direction',
  'code-block',
  // 'image'
  // 'video'
];

const GeneralData: React.FC<{}> = () => {
  const { quill, quillRef } = useQuill({
    formats,
    placeholder: 'Escribe aquí tu descripción',
  });
  const handleNotification = useNotificationStore(
    (state) => state.handleNotification
  );
  const { generalInfo } = useSelector((state: MainState) => state.event);
  const { mutateAsync, isLoading } = useMutation(updateEventDescription);
  const { data: venueList = [] } = useQuery(
    queriesKeys.fetchVenuesAsOptions(),
    fetchVenuesAsOptions
  );
  const formFields = initializeFormFieldsWithSelect(
    generalDataformFields,
    ['venueId'],
    { venueId: venueList }
  );
  const initialValues = getInitialFormValues<GeneralDataFormKeys>(
    formFields,
    generalInfo
  );

  useEffect(() => {
    if (generalInfo.description && quill) {
      quill.clipboard.dangerouslyPasteHTML(generalInfo.description);
    }
  }, [quill, generalInfo]);

  const onSumit = async ({
    startDate,
    endDate,
    ...rest
  }: GeneralDataFormKeys) => {
    try {
      const formData = new FormData();
      Object.entries(rest).forEach(([key, value]) => {
        formData.append(key, value);
      });

      // TODO FIX THIS!
      // if I save this, the date will change to my local time!!
      const startDateAsDate = new Date(startDate);
      // update date to ISOString
      formData.append('startDate', startDateAsDate.toISOString());
      formData.append(
        'startDateFormatted',
        format(startDateAsDate, 'dd/MM/yyyy hh:mm a')
      );
      formData.append('description', quill?.root.innerHTML ?? '');

      await mutateAsync(formData);
      queryClient.invalidateQueries(queriesKeys.fetchEventDetails());
      handleNotification(UI_MESSAGES.SUCCESS, 200);
    } catch (error: any) {
      const message = formatHttpError(error);
      handleNotification(message, 400);
    }
  };

  return (
    <Card>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={generalDataSchema}
        onSubmit={onSumit}
      >
        {({ isValid }) => (
          <Form>
            <Grid container spacing={2}>
              <FieldsBuilder formFields={generalDataformFields} />
              <div style={{ height: 200, width: '100%' }}>
                <div ref={quillRef} />
              </div>
              <Grid container item spacing={3} md={12} mt={2}>
                <Grid item>
                  <Button variant='contained' color='secondary' type='button'>
                    Cancelar
                  </Button>
                </Grid>
                <Grid item>
                  <LoadingButton
                    loading={isLoading}
                    disabled={!isValid}
                    variant='contained'
                    color='primary'
                    type='submit'
                  >
                    Guardar
                  </LoadingButton>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Card>
  );
};

export default GeneralData;
