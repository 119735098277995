import React, { useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { SeatsDirectionType } from './GridActions';

type RowActionProps = {
  onAssignIdentifiersToRow: (params: {
    seatsDirection: SeatsDirectionType;
    startNumber: number;
    fullRow: boolean;
  }) => void;
  row: number | string;
  col: number;
  open: boolean;
  onClose: () => void;
};

const RowActionsModal: React.FC<RowActionProps> = ({
  onAssignIdentifiersToRow,
  row,
  col,
  open,
  onClose,
}) => {
  const [seatsDirection, setSeatsDirection] =
    useState<SeatsDirectionType>('leftToRight');
  const [startNumber, setStartNumber] = useState('1');
  const [fullRow, setFullRow] = useState(true);
  const [error, setError] = useState('');

  return (
    <Modal
      open={open}
      onClose={onClose}
      disableAutoFocus
      aria-labelledby='update seats'
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
        }}
      >
        <Grid id='row-actions' container spacing={2}>
          <Grid item>
            <Typography>
              Fila: {row}, Asiento: {col}
            </Typography>
          </Grid>
          <Grid item container direction='column'>
            <label htmlFor='start'>Asignar dirección asientos</label>
            <Select
              onChange={(evt) =>
                setSeatsDirection(evt.target.value as SeatsDirectionType)
              }
              value={seatsDirection}
            >
              <MenuItem selected value='leftToRight'>
                Izquierda a derecha
              </MenuItem>
              <MenuItem value='rightToLeft'>Derecha a izquierda</MenuItem>
            </Select>
          </Grid>
          <Grid item>
            <label htmlFor='startNumber'>Identificador inicial</label>
            <TextField
              error={!!error}
              helperText={error}
              id='startNumber'
              fullWidth
              placeholder='1'
              value={startNumber}
              onChange={(evt) => {
                const { value } = evt.target;
                if (/^\d+$/.test(value)) {
                  setError('');
                } else {
                  setError('El valor debe ser un número entero');
                }
                setStartNumber(value);
              }}
            />
          </Grid>
          <Grid item>
            <FormControlLabel
              control={
                <Checkbox
                  checked={fullRow}
                  onChange={() => void setFullRow((prev) => !prev)}
                />
              }
              label='Asignar toda la fila'
            />
          </Grid>

          <Grid item>
            <Button
              variant='contained'
              color='primary'
              type='button'
              autoFocus
              onClick={() =>
                onAssignIdentifiersToRow({
                  seatsDirection,
                  startNumber: +startNumber,
                  fullRow,
                })
              }
            >
              Asignar
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default RowActionsModal;
