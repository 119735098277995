import { format } from 'date-fns';
import { UI_MESSAGES } from '../constants';

export const formatDate = (date: string | Date) => {
  try {
    if (date instanceof Date) {
      return format(date, 'dd/MM/yyyy hh:mm aa');
    } else {
      return format(new Date(date), 'dd/MM/yyyy hh:mm aa');
    }
  } catch (e) {
    return '';
  }
};

export const formatHttpError = (error: any) => {
  const code = error.response.data.statusCode;

  if (code === 403) {
    return UI_MESSAGES.UNAUTHORIZED;
  } else {
    const message =
      error?.response?.data?.message ?? UI_MESSAGES.UNEXPECTED_ERROR;
    return message;
  }
};
