import { Theme, useMediaQuery } from '@mui/material';
import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import Header from '../components/Layout/Header';
import SideDrawer from '../components/Layout/SideDrawer';
import { fetchEventDetails, queriesKeys } from '../http/Queries';
import { eventActions } from '../store/event.slice';
import { uiActions } from '../store/ui.slice';

const Admin = () => {
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));
  const { data, isLoading } = useQuery(
    queriesKeys.fetchEventDetails(),
    fetchEventDetails
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (data) {
      dispatch(eventActions.addEventDetails(data));
    }
  }, [data, dispatch]);

  useEffect(() => {
    if (isSmall) {
      dispatch(uiActions.toggle(false));
    }
  }, [isSmall, dispatch]);

  if (isLoading) return <div>Cargando...</div>;

  return (
    <>
      <Header hasDrawer={true} />
      <SideDrawer />
    </>
  );
};

export default Admin;
