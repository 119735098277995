import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Modal,
  TextField,
} from '@mui/material';
import React, { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { fetchReservedTickets, queriesKeys } from '../../http/Queries';
import { LoadingButton } from '@mui/lab';
import {
  cancelReservation,
  sendReservationByEmail,
} from '../../http/Mutations';
import { ReservedTicket } from '@soluticket/shared';
import { formatHttpError } from '../../utils';
import { useNotificationStore } from '../../store/notification.store';

type ReservedProps = {
  reservation: ReservedTicket;
  isLoading: boolean;
  onCancelReservation: () => void;
  onSendByEmail: () => void;
};
const Reservation: React.FC<ReservedProps> = ({
  reservation,
  isLoading,
  onCancelReservation,
  onSendByEmail,
}) => {
  const groupedByTicketType = reservation.tickets.reduce((prev, current) => {
    const key = current.ticketTypeName;
    prev[key] = prev[key] ?? [];
    prev[key].push(current);
    return prev;
  }, {} as { [key: string]: Array<ReservedTicket['tickets'][0]> });

  return (
    <Grid item maxWidth='300px'>
      <Card>
        <CardContent>
          <h3>Reservado para: {reservation.reservedFor}</h3>
          <div>
            <div style={{ fontWeight: 'bold' }}>
              Cantidad de boletos: {reservation.tickets.length}
            </div>
            <div>
              {Object.keys(groupedByTicketType).map((key) => {
                const ticketType = groupedByTicketType[key];
                const isNumbered = ticketType[0].isSeat;
                const isTable = ticketType[0].isTable;
                // remove repeated values
                const labelsSet = new Set(
                  ticketType.map((ticket) =>
                    isTable ? ticket.tableLabel || '' : ticket.label
                  )
                );
                const labels = Array.from(labelsSet);
                return (
                  <div
                    key={key}
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      rowGap: '5px',
                    }}
                  >
                    <div>
                      <div>
                        <span style={{ fontWeight: 'bold' }}>{key}</span> | #
                        {ticketType.length}
                      </div>
                      {isNumbered && (
                        <ul>
                          {labels.map((label, idx) => (
                            <li key={idx}>
                              {isTable ? 'Mesa' : 'Asiento'}: {label}
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </CardContent>
        <CardActions>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '5px',
            }}
          >
            <span>{reservation.link}</span>
            <Button
              variant='contained'
              onClick={() => {
                navigator.clipboard.writeText(reservation.link);
              }}
            >
              Copiar enlance
            </Button>
            <Button variant='contained' onClick={onSendByEmail}>
              Enviar por correo
            </Button>
            <LoadingButton
              variant='contained'
              loading={isLoading}
              onClick={onCancelReservation}
            >
              Cancelar reservación
            </LoadingButton>
          </div>
        </CardActions>
      </Card>
    </Grid>
  );
};

const Reservations: React.FC = () => {
  const queryClient = useQueryClient();
  const [transactionId, setTransactionId] = useState(0);
  const handleNotification = useNotificationStore(
    (state) => state
  ).handleNotification;
  const { data, isLoading, isError } = useQuery(
    queriesKeys.fetchReservedTickets(),
    fetchReservedTickets
  );
  const { mutate, isLoading: isLoadingCancel } = useMutation(
    cancelReservation,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queriesKeys.fetchReservedTickets());
      },
      onError: (error: any) => {
        const message = formatHttpError(error);
        handleNotification(message, 400);
      },
    }
  );

  const mReservationByEmail = useMutation(sendReservationByEmail, {
    onSuccess: () => {
      handleNotification('Reservación enviada por correo', 200);
      setTransactionId(0);
    },
    onError: (error: any) => {
      const message = formatHttpError(error);
      handleNotification(message, 400);
    },
  });

  if (isLoading) {
    return <div>Cargando...</div>;
  }

  if (isError) {
    return <div>Algo salio mal</div>;
  }

  const reservedFor = data?.find(
    (reservation) => reservation.id === transactionId
  )?.reservedFor;

  return (
    <Grid container columnGap={2} rowGap={2}>
      {data?.map((reservation) => (
        <Reservation
          key={reservation.reservedFor}
          reservation={reservation}
          isLoading={isLoadingCancel}
          onCancelReservation={() => {
            mutate(reservation.id);
          }}
          onSendByEmail={() => {
            setTransactionId(reservation.id);
          }}
        />
      ))}
      <Modal
        open={transactionId > 0}
        onClose={() => {
          setTransactionId(0);
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
          }}
        >
          <form
            onSubmit={(evt: any) => {
              evt.preventDefault();
              const form = evt.target;
              const formData = new FormData(form);
              const formJson = Object.fromEntries(formData.entries()) as {
                email: string;
              };
              if (!formJson.email?.trim()) {
                return;
              }

              mReservationByEmail.mutate({
                transactionId,
                email: formJson.email,
              });
            }}
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '1rem',
              width: '100%',
            }}
          >
            <h3>Enviar reservación por correo para: {reservedFor}</h3>
            <TextField fullWidth name='email' label='Correo eléctronico' />
            <div style={{ display: 'flex', gap: '1rem' }}>
              <LoadingButton
                loading={mReservationByEmail.isLoading}
                variant='contained'
                color='primary'
                type='submit'
              >
                Enviar
              </LoadingButton>
              <Button
                variant='contained'
                color='secondary'
                onClick={() => {
                  setTransactionId(0);
                }}
              >
                Cerrar
              </Button>
            </div>
          </form>
        </Box>
      </Modal>
    </Grid>
  );
};

export default Reservations;
