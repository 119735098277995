import { LoadingButton } from '@mui/lab';
import { Grid, TextField } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import React, { useState } from 'react';
import { UI_MESSAGES } from '../../constants';
import {
  blockTicketsByAmount,
  createCourtesiesByAmount,
  reserveTicketByAmount,
} from '../../http/Mutations';
import { queriesKeys } from '../../http/Queries';
import { useNotificationStore } from '../../store/notification.store';
import { formatHttpError } from '../../utils';

const emptyState = {
  amount: 0,
  reservedFor: '',
  ticketTypeId: 0,
};

type ActionsNotNumberedProps = {
  ticketTypeId: number;
  availableTickets: number;
  soldTickets: number;
};

const ActionsNotNumbered: React.FC<ActionsNotNumberedProps> = ({
  ticketTypeId,
  availableTickets,
  soldTickets,
}) => {
  const queryClient = useQueryClient();
  const { handleNotification } = useNotificationStore((state) => state);
  const [input, setInput] = useState({ ...emptyState, ticketTypeId });
  const { mutate: mutateReserve, isLoading } = useMutation(
    reserveTicketByAmount,
    {
      onSuccess: handleSuccess,
      onError: (error: any) => {
        const message = formatHttpError(error);
        handleNotification(message, 400);
      },
    }
  );
  const { mutate: mutateCourtesy, isLoading: isLoadingCourtesy } = useMutation(
    createCourtesiesByAmount,
    {
      onSuccess: handleSuccess,
      onError: (error: any) => {
        const message = formatHttpError(error);
        handleNotification(message, 400);
      },
    }
  );
  const { mutate: mBlockTickets, isLoading: isLoadingBlockTickets } =
    useMutation(blockTicketsByAmount, {
      onSuccess: handleSuccess,
      onError: (error: any) => {
        const message = formatHttpError(error);
        handleNotification(message, 400);
      },
    });

  function handleSuccess() {
    queryClient.invalidateQueries(queriesKeys.fetchReservedTickets());
    handleNotification(UI_MESSAGES.SUCCESS, 200);
    setInput(emptyState);
  }

  // TODO EV: ask Fortunato, las cortesias deben ser para todos?
  // I can create a link to accept the tickets!, this way I can regret if
  // I wrote a bad email, once the user clicks on the link, complete the transaction.
  return (
    <Grid container flexDirection='column' rowGap={1}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div>Reservar boletos</div>
        <div>Boletos disponibles: {availableTickets}</div>
        <div>Boletos vendidos: {soldTickets}</div>
      </div>
      <TextField
        id='amount'
        label='Cantidad de boletos'
        variant='outlined'
        value={input.amount}
        onChange={(event) => {
          let value = Number(event.target.value);
          if (!isNaN(value)) {
            setInput({
              ...input,
              amount: value,
            });
          }
        }}
      />
      <TextField
        id='reservedFor'
        label='Reservado para'
        variant='outlined'
        value={input.reservedFor}
        onChange={(event) => {
          setInput({
            ...input,
            reservedFor: event.target.value,
          });
        }}
      />
      <LoadingButton
        variant='outlined'
        loading={isLoading || isLoadingCourtesy}
        onClick={() => {
          mutateReserve(input);
        }}
      >
        Reservar
      </LoadingButton>
      <LoadingButton
        variant='outlined'
        loading={isLoading || isLoadingCourtesy}
        onClick={() => {
          mutateCourtesy(input);
        }}
      >
        Crear cortesía
      </LoadingButton>
      <LoadingButton
        variant='outlined'
        loading={isLoading || isLoadingCourtesy || isLoadingBlockTickets}
        onClick={() => {
          mBlockTickets(input);
        }}
      >
        Bloquear
      </LoadingButton>
    </Grid>
  );
};

export default ActionsNotNumbered;
