import clsx from 'clsx';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {
  CssBaseline,
  Divider,
  List,
  IconButton,
  Drawer,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import EventIcon from '@mui/icons-material/Event';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
// import AccountBoxIcon from '@mui/icons-material/AccountBox';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import FilterHdrIcon from '@mui/icons-material/FilterHdr';
import { useDispatch, useSelector } from 'react-redux';
import { MainState } from '../../store';
import { uiActions } from '../../store/ui.slice';
import { Link, LinkProps, Outlet } from '@tanstack/react-location';
import React from 'react';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

type Menu = {
  name: string;
  icon?: JSX.Element;
  route: string;
  subMenu?: Array<Menu>;
};

const menuList: Array<Menu> = [
  { name: 'Recintos', icon: <FilterHdrIcon />, route: '/admin/venues' },
  {
    name: 'Eventos',
    icon: <EventIcon />,
    route: '/admin/events',
  },
  {
    name: 'Boletos',
    icon: <ConfirmationNumberIcon />,
    route: '/admin/tickets',
  },
  { name: 'Saldos', icon: <ShowChartIcon />, route: '/admin/balance' },
];
// For now: only Fortunato has workers
// { name: 'Trabajadores', icon: <AccountBoxIcon />, route: '/admin/workers' },

const SideDrawer = () => {
  const classes = useStyles();
  const theme = useTheme();
  const uiDispatch = useDispatch();
  const { isDrawerVisible } = useSelector((state: MainState) => state.ui);

  const handleDrawerClose = () => {
    uiDispatch(uiActions.toggle(false));
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Drawer
        className={classes.drawer}
        variant='persistent'
        anchor='left'
        open={isDrawerVisible}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose} size='large'>
            {theme.direction === 'ltr' ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <List>
          {menuList.map((menuItem) => (
            <ListItem
              component={React.forwardRef<
                HTMLAnchorElement,
                Partial<LinkProps>
              >(
                (
                  props,
                  ref // the unused REF fix a warning... react is weird
                ) => (
                  <Link {...props} />
                )
              )}
              to={menuItem.route}
              button
              key={menuItem.name}
              replace
            >
              <ListItemIcon>{menuItem.icon}</ListItemIcon>
              <ListItemText primary={menuItem.name} />
            </ListItem>
          ))}
        </List>
      </Drawer>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: isDrawerVisible,
        })}
      >
        <div className={classes.drawerHeader} />
        <Outlet />
      </main>
    </div>
  );
};

export default SideDrawer;
