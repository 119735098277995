import { useRef, useState } from 'react';
import { Button, Grid } from '@mui/material';
import { Form, Formik, FormikProps } from 'formik';
import FieldsBuilder from '../../components/Form/FieldsBuilder';
import { SimpleList, Card, MyConfirmDialog } from '../../components';
import { createBankAccountType } from '@soluticket/shared';
import { bankAccountFormFields } from '../../forms';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  fetchBankAccounts,
  queriesKeys,
  queryClient,
} from '../../http/Queries';
import {
  createOrUpdateBankAccount,
  removeBankAccount,
} from '../../http/Mutations';
import { UI_MESSAGES } from '../../constants';
import { useNotificationStore } from '../../store/notification.store';
import { formatHttpError } from '../../utils';
import { LoadingButton } from '@mui/lab';

const BankAccounts = () => {
  const innerRef = useRef<FormikProps<createBankAccountType>>(null);
  const handleNotification = useNotificationStore(
    (state) => state
  ).handleNotification;
  const [editMode, setEditMode] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [bankAccountIdToRemove, setBankAccountIdToRemove] = useState<
    number | null
  >(null);
  const { data: bankAccounts = [], isFetching } = useQuery(
    queriesKeys.fetchBankAccounts(),
    fetchBankAccounts
  );
  const deleteMutation = useMutation(removeBankAccount, {
    onSuccess: () => {
      setEditMode(false);
      setShowConfirmDialog(false);
      innerRef.current?.resetForm();
      queryClient.invalidateQueries(queriesKeys.fetchBankAccounts());
      handleNotification(UI_MESSAGES.SUCCESS, 200);
    },
    onError: (error: any) => {
      const message = formatHttpError(error);
      handleNotification(message, 400);
    },
  });
  const createMutation = useMutation(createOrUpdateBankAccount, {
    onSuccess: () => {
      innerRef.current?.resetForm();
      setEditMode(false);
      queryClient.invalidateQueries(queriesKeys.fetchBankAccounts());
      handleNotification(UI_MESSAGES.SUCCESS, 200);
    },
    onError: (error: any) => {
      const message = formatHttpError(error);
      handleNotification(message, 400);
    },
  });

  const selectListItemHandler = (idx: number) => {
    const bankAccount = bankAccounts.find((account) => account.id === idx);
    if (bankAccount) {
      Object.keys(bankAccount).forEach((key) => {
        const typedKey = [key as keyof createBankAccountType][0];
        innerRef.current?.setFieldValue(key, bankAccount[typedKey]);
      });
      setEditMode(true);
    }
  };

  const removeBankAccountHandler = (idx: number) => {
    deleteMutation.mutate(idx);
  };

  const submitHandler = (payload: createBankAccountType) => {
    createMutation.mutate(payload);
  };

  return (
    <Grid container spacing={2}>
      <Grid item md={6} xs={12}>
        <Card>
          <Formik
            innerRef={innerRef}
            initialValues={{
              businessName: '',
              fiscalAddress: '',
              clabe: '',
              accountNumber: '',
              name: '',
            }}
            onSubmit={(values) => submitHandler(values)}
          >
            {() => (
              <Form>
                <Grid container spacing={0}>
                  <FieldsBuilder formFields={bankAccountFormFields} />
                </Grid>
                <Grid container item spacing={3} md={12}>
                  <Grid item>
                    <Button
                      variant='contained'
                      color='secondary'
                      type='button'
                      onClick={() => {
                        innerRef.current?.resetForm();
                        setEditMode(false);
                      }}
                    >
                      Cancelar
                    </Button>
                  </Grid>
                  <Grid item>
                    <LoadingButton
                      loading={createMutation.isLoading}
                      disabled={isFetching}
                      variant='contained'
                      color='primary'
                      type='submit'
                    >
                      {editMode ? 'Actualizar' : 'Guardar'}
                    </LoadingButton>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Card>
      </Grid>
      <Grid item md={6} xs={12}>
        <Card>
          {isFetching && <p>Cargando..</p>}
          {!isFetching && (
            <SimpleList
              data={bankAccounts.map(({ id, name, accountNumber }) => ({
                idx: id!,
                primary: accountNumber,
                secondary: name,
              }))}
              onSelect={selectListItemHandler}
              onRemove={(idx) => {
                setBankAccountIdToRemove(idx);
                setShowConfirmDialog(true);
              }}
            />
          )}
        </Card>
      </Grid>
      <MyConfirmDialog
        title={UI_MESSAGES.CONFIRM_ALERT_TITLE}
        description={UI_MESSAGES.REMOVE_RECORD}
        onApproved={() => removeBankAccountHandler(bankAccountIdToRemove!)}
        onClose={() => setShowConfirmDialog(false)}
        open={showConfirmDialog}
      />
    </Grid>
  );
};

export default BankAccounts;
