import clsx from 'clsx';
import { AppBar, IconButton, Toolbar, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import MenuIcon from '@mui/icons-material/Menu';
import { useDispatch, useSelector } from 'react-redux';
import { uiActions } from '../../store/ui.slice';
import { MainState } from '../../store';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import SwitchCameraIcon from '@mui/icons-material/SwitchCamera';
import { useNavigate } from '@tanstack/react-location';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  title: {
    flexGrow: 1,
  },
}));

type HeaderProps = {
  hasDrawer: boolean;
};

const Header: React.FC<HeaderProps> = ({ hasDrawer }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isDrawerVisible } = useSelector((state: MainState) => state.ui);
  const { eventName, isEventConfirmed, startDate } = useSelector(
    (state: MainState) => state.event
  );

  const handleDrawerOpen = () => {
    dispatch(uiActions.toggle(true));
  };

  const logoutHandler = () => {
    dispatch(uiActions.reset());
    localStorage.removeItem('token');
    localStorage.removeItem('eventid');
    localStorage.removeItem('state');
    navigate({ to: '/', replace: true });
  };

  const switchEventHandler = () => {
    navigate({ to: '/create', replace: true });
  };

  const drawerClasses = hasDrawer
    ? clsx(classes.appBar, {
        [classes.appBarShift]: isDrawerVisible,
      })
    : '';

  return (
    <AppBar position='fixed' className={drawerClasses}>
      <Toolbar>
        <IconButton
          color='inherit'
          aria-label='open drawer'
          onClick={handleDrawerOpen}
          edge='start'
          className={clsx(classes.menuButton, isDrawerVisible && classes.hide)}
          size='large'
        >
          <MenuIcon />
        </IconButton>
        <Typography className={classes.title} variant='h6' noWrap>
          {eventName}
          {startDate && ` | ${startDate}`}
          {isEventConfirmed && ` | Evento confirmado`}
        </Typography>

        <IconButton
          onClick={switchEventHandler}
          title='Cambiar de evento'
          aria-label='switchEvent'
          size='large'
        >
          <SwitchCameraIcon color='secondary' />
        </IconButton>
        <IconButton
          onClick={logoutHandler}
          title='Cerrar sesión'
          aria-label='logout'
          size='large'
        >
          <ExitToAppIcon color='secondary' />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
