import { ITicket } from '@soluticket/shared';

export const emptySeat: Partial<ITicket> = {
  label: '',
  isSeat: false,
};

/**
 * TODO EV: Instead of just saving 1 or 0,
 * save a object with a state (selected: true or false)
 * each ticket will have its own price, id, etc.
 *
 * {
 *    label: string;
 *    selected: boolean;
 *    TODO EV: Define other properties.
 * }
 * @param numRows
 * @param numCols
 * @returns Grid
 */
export const initialGrid = (numRows: number, numCols: number) => {
  // console.log('initialGrid');
  const rows: Partial<ITicket>[][] = [];
  for (let i = 0; i < numRows; i++) {
    rows.push(Array.from(Array(numCols), () => emptySeat));
  }
  return rows;
};

export const convertToNumberingScheme = (number: number) => {
  let baseChar = 'A'.charCodeAt(0);
  let letters = '';

  do {
    number -= 1;
    letters = String.fromCharCode(baseChar + (number % 26)) + letters;
    number = (number / 26) >> 0; // quick `floor`
  } while (number > 0);

  return letters;
};

export const ALPHABET = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');
export const generateLetters = (start = 0, end = 5) => {
  if (end + 1 <= ALPHABET.length) {
    return ALPHABET.slice(start, end);
  }

  const rows: Array<string> = ALPHABET.slice(start, ALPHABET.length - 1);

  for (let i = ALPHABET.length; i <= end; i++) {
    rows.push(convertToNumberingScheme(i));
  }

  return rows;
};

export const generateDuplicatedLetters = (
  start = 0,
  end = 5
): Array<string> => {
  const alphabetDuplicated = [
    'AA',
    'BB',
    'CC',
    'DD',
    'EE',
    'FF',
    'GG',
    'HH',
    'II',
    'JJ',
    'KK',
    'LL',
    'MM',
    'NN',
    'OO',
    'PP',
    'QQ',
    'RR',
    'SS',
    'TT',
    'UU',
    'VV',
    'WW',
    'XX',
    'YY',
    'ZZ',
  ];
  // This works
  if (end + 1 <= alphabetDuplicated.length) {
    return alphabetDuplicated.slice(start, end);
  }

  const rows: Array<string> = alphabetDuplicated.slice(
    start,
    alphabetDuplicated.length - 1
  );

  // TODO EV: This is not working
  for (let i = alphabetDuplicated.length; i <= end; i++) {
    const letter = convertToNumberingScheme(i);
    rows.push(letter);
  }

  return rows;
};

export const generateNumbers = (start = 1, end = 5): Array<number> => {
  console.log({ start, end });
  const numbers: Array<number> = [];
  for (start; start <= end; start++) {
    numbers.push(start);
  }
  return numbers;
};

export const DEFAULT_GRID_ROWS = 5;
export const DEFAULT_GRID_COLUMNS = 5;

export type RowsRangeType = 'abc' | '123' | 'aabbcc';
export type SeatsDirectionType = 'leftToRight' | 'rightToLeft';
