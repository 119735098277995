export class IFilterEvent {
  eventId: number;
  eventName: string;
  dateTime: string;
  // street address, city, state.
  address: string;
}

export interface IFilterModel {
  results: Array<IFilterEvent>;
  total: number;
}
