import { createSlice } from '@reduxjs/toolkit';

export interface UIState {
  isDrawerVisible: boolean;
  isAuth: boolean;
}

const initialState: UIState = {
  isDrawerVisible: true,
  isAuth: false,
};

const uiSlice = createSlice({
  name: 'ui',
  initialState: initialState,
  reducers: {
    toggle(state: UIState, action) {
      state.isDrawerVisible = action.payload;
    },
    setIsAuth(state: UIState) {
      state.isAuth = true;
    },
    reset() {
      return initialState;
    },
  },
});

export const uiActions = uiSlice.actions;
export default uiSlice;
