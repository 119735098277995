import { configureStore } from '@reduxjs/toolkit';
import eventSlice, { EventState } from './event.slice';
import uiSlice, { UIState } from './ui.slice';

// type SliceNames = 'ui' | 'event' | 'ticketsTypes';

export type MainState = {
  ui: UIState;
  event: EventState;
};

const saveToLocalStorage = (state: MainState) => {
  try {
    // Avoid saving ticket tickets
    // and other properties that doesn't need to be preserved on global state.
    const newState = { ui: { ...state.ui }, event: { ...state.event } };
    localStorage.setItem('state', JSON.stringify(newState));
  } catch (e) {
    console.error('saveToLocalStorage', e);
  }
};

const loadFromLocalStorage = () => {
  try {
    const stateStr = localStorage.getItem('state');
    return stateStr ? JSON.parse(stateStr) : undefined;
  } catch (e) {
    console.error('loadFromLocalStorage', e);
    return undefined;
  }
};

const store = configureStore({
  reducer: {
    ui: uiSlice.reducer,
    event: eventSlice.reducer,
  },
  preloadedState: loadFromLocalStorage(),
});

store.subscribe(() => saveToLocalStorage(store.getState()));

export default store;
