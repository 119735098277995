import { z } from 'zod';
import { DiscountType } from '../enums';

export const reservedFor = z.string().min(3, 'Minimo 3 caracteres');
export const ticketsIds = z.array(z.number()).min(1);
export const ticketTypeId = z.number().min(1);

export const createCouponDTO = z.object({
  id: z.number().optional(),
  code: z.string().min(1),
  discountType: z.nativeEnum(DiscountType),
  discount: z.number().min(1),
  numberOfCodes: z.number().min(1),
  startDate: z.string(),
  ticketTypeId,
});
export type createCouponType = z.infer<typeof createCouponDTO>;

export const updateTicketPriceDTO = z.object({
  newPrice: z.number().min(1),
  ticketsIds,
});
export type updateTicketPriceType = z.infer<typeof updateTicketPriceDTO>;

export const reserveTicketByIdDTO = z.object({
  ticketsIds,
  reservedFor,
});

export type reserveTicketByIdType = z.infer<typeof reserveTicketByIdDTO>;

export const reserveTicketByAmountDTO = z.object({
  amount: z.number().min(1),
  ticketTypeId,
  reservedFor,
});
export type reserveTicketByAmountType = z.infer<
  typeof reserveTicketByAmountDTO
>;

export const reserveLinkDTO = z.object({ reservedFor });
export type reserveLinkType = z.infer<typeof reserveLinkDTO>;

export const courtesyLinkDTO = z.object({ giftCode: z.string().min(1) });
export type courtesyLinkType = z.infer<typeof courtesyLinkDTO>;

export const createTicketTypeDTO = z.object({
  id: z.number().optional(),
  name: z.string().min(1).max(50),
  price: z.number().min(1),
  isNumbered: z.boolean().optional(),
  grid: z
    .array(
      z.array(
        z.object({
          label: z.string().optional(),
          isSeat: z.boolean().optional(),
          status: z.string().optional(),
        })
      )
    )
    .optional(),
  totalTicketsAvailable: z.number().min(1),
  isTable: z.boolean().optional(),
  bundle: z.number().min(1).max(30),
});
export type createTicketTypeType = z.infer<typeof createTicketTypeDTO>;

export const createCourtesiesByIdDTO = z.object({
  ticketsIds: z.number().array().min(1),
  reservedFor,
});
export type createCourtesiesByIdType = z.infer<typeof createCourtesiesByIdDTO>;

export const blockTicketsByIdDTO = z.object({
  ticketsIds: z.number().array().min(1),
});
export type blockTicketsByIdType = z.infer<typeof blockTicketsByIdDTO>;

export const createCourtesiesByAmountDTO = z.object({
  amount: z.number().min(1),
  ticketTypeId,
  reservedFor,
});
export type createCourtesiesByAmountType = z.infer<
  typeof createCourtesiesByAmountDTO
>;

export const cancelCourtesyDTO = z.object({
  code: z.string().min(1),
});
export type cancelCourtesyType = z.infer<typeof cancelCourtesyDTO>;

export const transferTicketDTO = z.object({
  phone: z.string().min(10).max(10),
  ticketId: z.number().min(1),
});

export type transferTicketType = z.infer<typeof transferTicketDTO>;

export const blockTicketsByAmountDTO = z.object({
  amount: z.number().min(1),
  ticketTypeId,
});
export type blockTicketsByAmountType = z.infer<typeof blockTicketsByAmountDTO>;

export const selectedTicketsDTO = z.object({
  tickets: z
    .array(
      z.object({
        ticketTypeId: z.string(),
        quantity: z.number().min(1),
        label: z.string().optional(),
      })
    )
    .min(1),
});
export type selectedTicketsType = z.infer<typeof selectedTicketsDTO>;

export const reservationEmailDTO = z.object({
  email: z.string().email(),
  transactionId: z.number().min(1),
});
export type ReservationEmailType = z.infer<typeof reservationEmailDTO>;
