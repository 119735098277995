import React from 'react';
import { Grid, GridSize } from '@mui/material';
import { Field } from 'formik';
import FileFormField from './FileFormField';
import CurrencyFormField from './CurrencyFormField';
import {
  DatePickerFormField,
  DateTimePickerFormField,
} from './DateTimePickerFormField';
import RadioFormField from './RadioFormField';
import SelectFormField from './SelectFormField';
import TextFormField from './TextFormField';
import { Option } from '@soluticket/shared';
import MultiSelectFormField from './MultiSelectFormField';
import CheckboxFormField from './CheckboxFormField';
import NumberFormField from './NumberFormField';

export enum FieldType {
  TEXT = 'text',
  NUMBER = 'number',
  DATETIME = 'datetime',
  DATE = 'date',
  CURRENCY = 'currency',
  SELECT = 'select',
  RADIO = 'radio',
  FILE = 'file',
  MULTI_SELECT = 'multiselect',
  CHECKBOX = 'checkbox',
}

export type DynamicFormField = {
  name: string;
  label: string;
  type?: FieldType;
  options?: Array<Option>;
  md?: GridSize;
  sm?: GridSize;
  xs?: GridSize;
};

type Props = {
  formFields: Array<DynamicFormField>;
};

const getField = (fieldType?: FieldType) => {
  switch (fieldType) {
    case FieldType.TEXT:
      return TextFormField;
    case FieldType.NUMBER:
      return NumberFormField;
    case FieldType.DATETIME:
      return DateTimePickerFormField;
    case FieldType.DATE:
      return DatePickerFormField;
    case FieldType.SELECT:
      return SelectFormField;
    case FieldType.MULTI_SELECT:
      return MultiSelectFormField;
    case FieldType.CURRENCY:
      return CurrencyFormField;
    case FieldType.RADIO:
      return RadioFormField;
    case FieldType.FILE:
      return FileFormField;
    case FieldType.CHECKBOX:
      return CheckboxFormField;
    default:
      return TextFormField;
  }
};

/**
 * Creates an empty object {key: ''} for initial values
 * of a FormikForm
 * T: must be the keys of the form
 * @param dynamicForm
 * @returns
 */
export function getEmptyFormValues<T>(dynamicForm: Array<DynamicFormField>): T {
  const initialValues = {} as any;
  dynamicForm.forEach((item) => {
    let defaultValue: string | Array<any> = '';
    switch (item.type) {
      case FieldType.TEXT:
        defaultValue = '';
        break;
      case FieldType.SELECT:
      case FieldType.MULTI_SELECT:
        defaultValue = [];
        break;
      default:
        defaultValue = '';
        break;
    }
    initialValues[item.name] = defaultValue;
  });
  return initialValues;
}

/**
 * Gets the initial data for the form.
 * It can be an empty form or a form with previous data
 * like when you fetch the state of the form.
 * T: must be the keys of the form
 * @param dynamicForm
 * @param values Pre defined values, e.g. When you fetch the data of the form.
 * @returns
 */
export function getInitialFormValues<T>(
  dynamicForm: Array<DynamicFormField>,
  values?: any
) {
  const formValues = getEmptyFormValues<T>(dynamicForm);
  if (!values) {
    return formValues;
  }
  Object.keys(values).forEach((key) => {
    const typedKey = key as keyof T;
    const currentValue = formValues[typedKey];
    if (currentValue !== undefined) {
      formValues[typedKey] = values[key];
    }
  });
  return formValues;
}

/**
 * Set the OPTIONS of a SELECT or MULTISELECT element
 * is util when options comes from server.
 * @param dynamicForm
 * @param selectKeys
 * @param values
 * @returns
 */
export function initializeFormFieldsWithSelect(
  dynamicForm: Array<DynamicFormField>,
  selectKeys: Array<string>,
  values?: any
): Array<DynamicFormField> {
  if (!values) {
    return [];
  }

  const keysMap = new Set(selectKeys);
  dynamicForm.forEach((item) => {
    // be sure is a SELECT or MULTISELECT
    if (
      keysMap.has(item.name) &&
      (item.type === FieldType.SELECT || item.type === FieldType.MULTI_SELECT)
    ) {
      item.options = values[item.name];
    }
  });

  return dynamicForm;
}

// TODO, for "description" define the best way to use fullWidth as default:

// VALUE will be set by `initialValues` property from Formik
// The best way to use it, is to set the initial state form React
// and get and set the values from API using formReference!
const FieldsBuilder: React.FC<Props> = ({ formFields, children }) => {
  return (
    <>
      {formFields?.map((formField) => (
        <Grid
          key={formField.name}
          item
          xs={formField.xs ?? 12}
          sm={formField.sm ?? 6}
          md={formField.md ?? 4}
        >
          <Field
            name={formField.name}
            label={formField.label}
            options={formField.options ?? ''}
            component={getField(formField.type)}
          />
        </Grid>
      ))}
      {children}
    </>
  );
};

export default FieldsBuilder;
