import { useState } from 'react';
import { Grid, Theme, useMediaQuery } from '@mui/material';
import Signin from './Signin';
// import ForgotPassword from './ForgotPassword';
import Signup from './Signup';
import { Link } from '@tanstack/react-location';
import { makeStyles } from '@mui/styles';

const actionTitle = {
  signin: 'Crea tu evento hoy!',
  signup: 'Registrarse',
  // forgotPassword: 'Recuperar contraseña',
};

const useStyles = makeStyles(() => ({
  link: {
    cursor: 'pointer',
  },
}));

const Login = () => {
  const classes = useStyles();
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));
  const [title, setTitle] = useState(actionTitle.signin);

  const renderForm = (title: string) => {
    if (actionTitle.signin === title) {
      return <Signin />;
    }
    if (actionTitle.signup === title) {
      return <Signup />;
    }

    // TODO: Implement forgot password
    // if (actionTitle.forgotPassword === title) {
    //   return <ForgotPassword />;
    // }
  };

  return (
    <Grid container spacing={0}>
      {/* Left Panel */}
      {!isSmall && (
        <Grid item md={5} bgcolor='#0284c7' minHeight='100vh'></Grid>
      )}
      {/* Login Panel */}
      <Grid item md={isSmall ? 12 : 7} width='100%' minHeight='100vh'>
        <Grid
          container
          spacing={0}
          direction='column'
          alignItems='center'
          justifyContent='center'
          minHeight='100%'
        >
          <Grid item mb={2}>
            Soluticket!
          </Grid>
          <Grid item mb={2}>
            {title}
          </Grid>
          <Grid item width='400px'>
            {renderForm(title)}
            <Grid item container justifyContent='space-between' mt={4}>
              <Link
                className={classes.link}
                onClick={() => {
                  if (title === actionTitle.signup) {
                    setTitle(actionTitle.signin);
                  } else {
                    setTitle(actionTitle.signup);
                  }
                }}
              >
                {title === actionTitle.signup
                  ? 'Iniciar sesión'
                  : 'Registrarse'}
              </Link>
              {/* <Link
                className={classes.link}
                onClick={() => setTitle(actionTitle.forgotPassword)}
              >
                ¿Olvido su contraseña?
              </Link> */}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Login;
