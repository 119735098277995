export enum TicketStatus {
  AVAILABLE = 'AVAILABLE', // Disponible
  BLOCKED = 'BLOCKED', // Bloqueado - no se puede vender
  PENDING = 'PENDING', // Boleto elegido por el usuario, no ha iniciado el pago
  PENDING_PAYMENT = 'PENDING_PAYMENT', // Boleto elegido por el usuario, proceso de pago iniciado
  SOLD = 'SOLD', // Vendido
  RESERVED = 'RESERVED', // Se reservo por sistema (ADMIN) Usualmente se reservan para el artista
  // April 8, 2023: this won't be needed anymore because I have the column "verifiedBy"
  // I'll be using it just to display, but not for calculations, in DB it will never have this status
  VERIFIED = 'VERIFIED', // El cliente ya entro al evento //
  COURTESY = 'COURTESY', // El boleto es cortesia
}
