import { contactType, sponsorType } from '../contracts';
import { EventStatus } from '../enums';

export * from './option.type';
export * from './selected-tickets.type';

export type ExtraCharge = {
  name: string;
  commission: string;
};

export type Commission = {
  paymentMethodId: number;
  name: string;
  title: string;
  description: string;
  total: string;
  subtotal: string;
  serviceCommision: string;
  paymentMethodComission: string;
  extraCharges: Array<ExtraCharge>;
};

export type ReserveTickets = {
  ticketsId: number[];
  modified: number;
  paymentMethods: Array<Commission>;
};

export type PaymentMethodModel = {
  id: number;
  name: string;
  title: string;
  description?: string;
};

export type WebEventDetail = {
  name: string;
  imageUrl: string;
  // street address, city, state.
  address: string;
  description?: string;
  date: string;
  limitedTo: number;
  hasNumberedTickets: boolean;
  contact?: contactType;
  sponsors?: Array<sponsorType>;
  status: EventStatus;
};

export type BaseBanner = {
  eventId: number;
  eventName: string;
  imageUrl: string;
  // City, State
  address: string;
  date: string;
};

export type Home = {
  mainEvents: Array<BaseBanner>;
  mostWanted: Array<BaseBanner>;
  upcomingEvents: Array<BaseBanner>;
};

export type FilterEvent = {
  eventId: number;
  eventName: string;
  dateTime: string;
  // street address, city, state.
  address: string;
};

export type FilterModel = {
  results: Array<FilterEvent>;
  total: number;
};

export type Column = {
  flex?: number;
  headerName: string;
  field: string;
  type?: string;
};
export type ITableModel<T> = {
  columns: Array<Column>;
  data: T;
};

export type EventListModel = {
  id: number;
  name: string;
  startDate?: string;
  isVisible?: boolean;
  isSelling?: boolean;
  isConfirmed?: boolean;
};

export type TicketCourtesy = {
  id: number;
  isSeat: boolean;
  reservedFor?: string;
  label: string;
  tableLabel?: string;
  TicketType: {
    name: string;
    isTable: boolean;
  };
  link: string;
};

export type Courtesy = {
  giftCode: string;
  tickets: Array<TicketCourtesy>;
};

export type ReservedTicket = {
  reservedFor: string;
  id: number; // transacitonId
  uuid: string;
  link: string;
  tickets: Array<{
    isSeat: boolean;
    label: string;
    tableLabel?: string;
    isTable: boolean;
    ticketTypeName: string;
  }>;
};

export type COMISSION_TYPE = 'PERCENTAGE' | 'FIXED';

export type SalesByDayType = {
  total: number;
  ticketsSold: number;
  date: string;
};

export type PhoneCode = 'm+52' | 'u+1' | 'c+1';

export type TicketTypesSummary = {
  ticketTypeId: number;
  ticketTypeName: string;
  price: number;
  total: number;
  amountTicketsAvailable: number;
  amountTicketsBlocked: number;
  amountTicketsReserved: number;
  amountTicketsCourtesyClaimed: number;
  amountTicketsCourtesyPending: number;
  amountTicketsPicked: number;
  amountOfTicketsSold: number;
  amountTicketsWaitingForPayment: number;
  amountVerified: number;
};

export type SalesSummary = {
  ticketTypes: Array<TicketTypesSummary>;
  summary: {
    ticketsSold: number;
    total: number;
    totalTicketsAvailable: number;
    totalTicketsBlocked: number;
    totalTicketsReserved: number;
    totalTicketsCourtesyClaimed: number;
    totalTicketsCourtesyPending: number;
    totalTicketsPicked: number;
    totalTicketsWaitingForPayment: number;
    totalVerified: number;
  };
};

export type NotNumberedTicketsTotals = {
  availableTickets: number;
  soldTickets: number;
  verifiedTickets: number;
};

export type TransactionDetailType = {
  // {"extraCharges": [{"name": "Entrada a la feria", "commission": "200.00"}], "serviceComission": "480.00", "paymentMethodComission": "347.20"}
  extraCharges: Array<ExtraCharge>;
  serviceComission: string;
  paymentMethodComission: string;
  reservation?: boolean;
};
