import { createSlice } from '@reduxjs/toolkit';
import {
  IContact,
  IEventDetail,
  IGeneralData,
  ISponsor,
} from '@soluticket/shared';
import { Action } from './action';

export interface EventState {
  eventName: string;
  startDate?: string;
  error: string | null;
  generalInfo: IGeneralData;
  contact: IContact;
  sponsors: Array<ISponsor>;
  isEventConfirmed: boolean;
  operationStarted: boolean;
}

const initialState: EventState = {
  eventName: '',
  startDate: '',
  generalInfo: {} as IGeneralData,
  contact: {} as IContact,
  sponsors: [],
  error: null,
  isEventConfirmed: false,
  operationStarted: false,
};

const eventSlice = createSlice({
  name: 'event',
  initialState,
  reducers: {
    clearError(state) {
      state.error = null;
    },
    addEventName(state: EventState, { payload }: Action<string>) {
      state.eventName = payload;
    },
    removeEventName(state: EventState) {
      state.eventName = '';
    },
    addEventDetails(state: EventState, { payload }: Action<IEventDetail>) {
      state.startDate = payload.startDate;
      state.generalInfo = payload.generalInfo;
      state.contact = payload.contact;
      state.sponsors = payload.sponsors;
      state.isEventConfirmed = payload.isEventConfirmed;
      state.operationStarted = payload.operationStarted;
    },
  },
});

export const eventActions = eventSlice.actions;
export default eventSlice;
