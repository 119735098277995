import { Route } from '@tanstack/react-location';
import Dashboard from '../screens/Reports/Dashboard/Dashboard';
import SalesByDay from '../screens/Reports/SalesByDay';
import TicketsMap from '../screens/Reports/TicketsMap';

const ReportsRouter: Array<Route> = [
  { path: '/', element: <Dashboard /> },
  { path: '/sales-by-day', element: <SalesByDay /> },
  { path: '/tickets-map', element: <TicketsMap /> },
];

export default ReportsRouter;
