import React, { useState } from 'react';
import {
  Grid,
  IconButton,
  LinearProgress,
  Tooltip,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Field, Form, Formik } from 'formik';
import TextFormField from '../components/Form/TextFormField';
import { useMutation, useQuery } from '@tanstack/react-query';
import { eventActions } from '../store/event.slice';
import { useDispatch } from 'react-redux';
import { IEventList } from '@soluticket/shared';
import { fetchEvents, fetchUserById, queriesKeys } from '../http/Queries';
import { createEvent } from '../http/Mutations';
import { queryClient } from '../http/Queries';
import { LoadingButton } from '@mui/lab';
import { useNavigate } from '@tanstack/react-location';
import SummarizeIcon from '@mui/icons-material/Summarize';
import EditIcon from '@mui/icons-material/Edit';
import { formatHttpError } from '../utils';

const useStyles = makeStyles(() => ({
  separator: {
    backgroundColor: '#ccc',
  },
  item: {
    width: '70%',
    marginTop: '1rem',
  },
}));

const CreateEvent: React.FC<{}> = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [error, setError] = useState('');
  const dispatch = useDispatch();
  const { data = [], isLoading: isLoadingEvents } = useQuery(
    queriesKeys.fetchEvents(),
    fetchEvents
  );
  const qUserId = useQuery(queriesKeys.fetchUserById(), fetchUserById);
  const { mutate, isLoading: isLoadingMutation } = useMutation(createEvent, {
    onSuccess: () => {
      queryClient.invalidateQueries(queriesKeys.fetchEvents());
    },
    onError: (error: any) => {
      const message = formatHttpError(error);
      setError(message);
    },
  });

  const selectListItemHandler = (idx: number, action: 'edit' | 'report') => {
    localStorage.setItem('eventid', '' + idx);
    const event = data.find((event) => event.id === idx);
    if (event) {
      dispatch(eventActions.addEventName(event.name));
    }
    let route = action === 'edit' ? '/admin/events' : '/reports';
    navigate({ to: route });
  };

  return (
    <Grid container minHeight='100vh'>
      <Grid item md={2} sm={1} bgcolor='#ccc'></Grid>
      <Grid
        container
        direction='column'
        alignItems='center'
        item
        md={8}
        sm={10}
        xs={12}
      >
        <Grid item className={classes.item}>
          <h1>Crea tu evento!</h1>
          <h2>Bienvenido {qUserId.data?.name}</h2>
        </Grid>
        <Grid item className={classes.item}>
          <Formik
            initialValues={{
              eventName: '',
            }}
            onSubmit={(values, helpers) => {
              mutate({ eventName: values.eventName! });
              helpers.resetForm();
            }}
          >
            {() => (
              <Form>
                <Field
                  name='eventName'
                  label='Nombre del evento'
                  component={TextFormField}
                />
                {error && <Typography>{error}</Typography>}
                <LoadingButton
                  loading={isLoadingMutation}
                  color='primary'
                  variant='contained'
                  type='submit'
                >
                  Crear
                </LoadingButton>
              </Form>
            )}
          </Formik>
        </Grid>
        <Grid item container rowGap={2} className={classes.item}>
          {isLoadingEvents && <LinearProgress />}
          {!isLoadingEvents &&
            data.map((event: IEventList) => {
              const actions = [
                <IconButton
                  key='event'
                  color='primary'
                  aria-label='Ver reportes'
                  component='span'
                  size='large'
                  onClick={() => selectListItemHandler(event.id, 'report')}
                >
                  <Tooltip title='Reportes'>
                    <SummarizeIcon />
                  </Tooltip>
                </IconButton>,
              ];

              if (event.permission === 'EDIT') {
                actions.push(
                  <IconButton
                    key='reports'
                    color='primary'
                    aria-label='Editar evento'
                    component='span'
                    size='large'
                    onClick={() => selectListItemHandler(event.id, 'edit')}
                  >
                    <Tooltip title='Editar evento'>
                      <EditIcon />
                    </Tooltip>
                  </IconButton>
                );
              }
              return (
                <Grid container key={event.id}>
                  <Grid item md={2}>
                    <img
                      src={event.imageUrl}
                      alt={`${event.id}`}
                      height='100%'
                      width='100%'
                      style={{ borderRadius: '5px', cursor: 'pointer' }}
                      onClick={() => selectListItemHandler(event.id, 'report')}
                    />
                  </Grid>
                  <Grid item md={8}>
                    <h3 style={{ margin: '.5rem 0' }}>{event.name}</h3>
                    <div
                      style={{
                        color: 'rgba(0, 0, 0, 0.6)',
                      }}
                    >
                      {event.address} {event.startDate}
                    </div>
                  </Grid>
                  <Grid item md={2}>
                    {actions}
                  </Grid>
                </Grid>
              );
            })}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CreateEvent;
