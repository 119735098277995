import {
  getEventTypes,
  getTransactionStatus,
  WorkerType,
  getPermissions,
} from '@soluticket/shared';
import { DynamicFormField, FieldType } from '../components/Form/FieldsBuilder';

/**
 * F O R M S   D E F I N I T I O N S!
 */

/**
 * Form definitions.
 */
export const contactFormFields: Array<DynamicFormField> = [
  { name: 'organizer', label: 'Organizador' },
  { name: 'email', label: 'Email' },
  { name: 'phone', label: 'Teléfono' },
  { name: 'celphone', label: 'Celular' },
  { name: 'webpage', label: 'Página web' },
  { name: 'facebook', label: 'Facebook' },
  { name: 'twitter', label: 'Twitter' },
  { name: 'instagram', label: 'Instagram' },
];

// I'm going to fix this one I migrate to nextjs
export type GeneralDataFormKeys = {
  eventName: string;
  eventType: string;
  startDate: string;
  endDate: string;
  state: string;
  city: string;
  address: string;
  venueId: string;
  // videoUrl: string;
};
export const generalDataformFields: Array<DynamicFormField> = [
  { name: 'eventName', label: 'Nombre del evento' },
  {
    name: 'eventType',
    label: 'Tipo de evento',
    type: FieldType.SELECT,
    options: getEventTypes(),
  },
  {
    name: 'startDate',
    label: 'Fecha y hora inicio',
    type: FieldType.DATETIME,
  },
  { name: 'state', label: 'Estado' },
  { name: 'city', label: 'Ciudad' },
  { name: 'address', label: 'Dirección' },
  {
    name: 'venueId',
    label: 'Recinto',
    type: FieldType.SELECT,
    options: [],
  },
  // { name: 'mapCoordinates', label: 'Mapa' },
  // { name: 'videoUrl', label: 'Link del video' },
  { name: 'mainImage', label: 'Imagén principal', type: FieldType.FILE },
  { name: 'smallImage', label: 'Imagén pequeña', type: FieldType.FILE },
];

export const transactionFormFields: Array<DynamicFormField> = [
  { name: 'startDate', label: 'Fecha de inicio', type: FieldType.DATE },
  { name: 'endDate', label: 'Fecha fin', type: FieldType.DATE },
  {
    name: 'status',
    label: 'Estatus',
    type: FieldType.SELECT,
    options: getTransactionStatus(),
  },
  {
    name: 'limit',
    label: 'Cantidad de registros',
    type: FieldType.SELECT,
    options: [
      { label: '10', value: '10' },
      { label: '30', value: '30' },
      { label: '50', value: '50' },
      { label: '100', value: '100' },
    ],
  },
];

export const couponsFormFields: Array<DynamicFormField> = [
  { name: 'code', label: 'Código del cupón', md: 12 },
  {
    name: 'ticketTypeId',
    label: 'Tipo de boleto / Zona',
    md: 12,
    type: FieldType.SELECT,
    options: [],
  },
  {
    name: 'discountType',
    label: 'Tipo de descuento',
    md: 12,
    type: FieldType.RADIO,
    options: [
      { label: 'Porcentaje', value: 'percentage' },
      { label: 'Cantidad fija', value: 'fixed' },
    ],
  },
  { name: 'discount', label: 'Cantidad del descuento', md: 12 },
  { name: 'numberOfCodes', label: 'Cantidad de boletos a promocionar', md: 12 },
  {
    name: 'startDate',
    label: 'Fecha de activación',
    md: 12,
    type: FieldType.DATETIME,
  },
];

export type WorkerFormKeys = {
  username: string;
  password: string;
  type: [];
  ticketTypes: string;
};
export const workerFormFields: Array<DynamicFormField> = [
  { name: 'username', label: 'Nombre de usuario', md: 12 },
  { name: 'password', label: 'Contraseña', md: 12 },
  {
    name: 'type',
    label: 'Tipo de usuario',
    type: FieldType.SELECT,
    md: 12,
    options: [
      { label: 'Vendedor', value: WorkerType.VENDEDOR },
      { label: 'Verificador', value: WorkerType.VERIFICADOR },
    ],
  },
  {
    name: 'ticketTypes',
    label: 'Tipos de boleto',
    type: FieldType.MULTI_SELECT,
    md: 12,
    options: [],
  },
  {
    name: 'permissions',
    label: 'Permisos',
    type: FieldType.MULTI_SELECT,
    md: 12,
    options: getPermissions(),
  },
];

// TODO: Get proper names in english!.
export const bankAccountFormFields: Array<DynamicFormField> = [
  { name: 'businessName', label: 'Razón social', md: 12 },
  { name: 'fiscalAddress', label: 'Dirección físcal', md: 12 },
  { name: 'clabe', label: 'CLABE', md: 12 },
  { name: 'accountNumber', label: 'Número de cuenta', md: 12 },
  { name: 'name', label: 'Nombre del banco', md: 12 },
];
