import React, { useState } from 'react';
import {
  Box,
  Button,
  Grid,
  LinearProgress,
  Modal,
  Typography,
} from '@mui/material';
import {
  ITicket,
  ITicketType,
  NotNumberedTicketsTotals,
  TicketStatus,
} from '@soluticket/shared';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Card } from '../../components';
import GridModifyTicket, {
  TICKET_COLORS_BG,
  TRANSLATIONS,
} from '../../components/GridModifyTicket';
import {
  canModify,
  fetchGrid,
  fetchTicketsTypes,
  fetchVenueUrl,
  queriesKeys,
} from '../../http/Queries';
import ModifyTicketForm from './ModifyTicketForm';
import {
  blockOrUnblockTicketsById,
  createCourtesiesById,
  reserveTicketsById,
  updateTicketPrice,
} from '../../http/Mutations';
import ActionsNotNumbered from './ActionsNotNumbered';
import { useNotificationStore } from '../../store/notification.store';
import { UI_MESSAGES } from '../../constants';
import { formatHttpError } from '../../utils';
import VenueDisplay from './VenueDisplay';

const getColorsIndicatorsContent = () => {
  const colors: React.ReactNode[] = [];
  // @ts-ignore
  for (const [key, value] of TICKET_COLORS_BG) {
    colors.push(
      <Grid item key={key} ml={1} px={1} style={{ backgroundColor: value }}>
        {TRANSLATIONS.get(key as TicketStatus)}
      </Grid>
    );
  }
  return <Grid container>{colors}</Grid>;
};

const ModifyTickets: React.FC = () => {
  const queryClient = useQueryClient();
  const handleNotification = useNotificationStore(
    (state) => state
  ).handleNotification;
  const [ticketTypeId, setTicketTypeId] = useState(0);
  const [selectedTickets, setSelectedTickets] = useState<Array<ITicket>>([]);
  const qVenueUrl = useQuery(queriesKeys.fetchVenueUrl(), fetchVenueUrl, {
    // refetchOnWindowFocus: false,
  });
  const { data: canModifyTickets = false, isLoading } = useQuery(
    queriesKeys.canModify(),
    canModify
  );
  const { data: ticketTypes = [] } = useQuery(
    queriesKeys.fetchTicketsTypes(),
    fetchTicketsTypes,
    { enabled: canModifyTickets }
  );
  const qGrid = useQuery(
    queriesKeys.fetchGrid(ticketTypeId),
    () => fetchGrid(+ticketTypeId),
    { enabled: canModifyTickets && ticketTypeId > 0 }
  );
  const mUpdatePrice = useMutation(updateTicketPrice, {
    onSuccess: () => {
      handleSuccess();
      queryClient.invalidateQueries(queriesKeys.fetchReservedTickets());
    },
    onError: handleError,
  });
  const mReserveTicket = useMutation(reserveTicketsById, {
    onSuccess: () => {
      handleSuccess();
      queryClient.invalidateQueries(queriesKeys.fetchReservedTickets());
    },
    onError: handleError,
  });
  const mGenerateCourtesyCode = useMutation(createCourtesiesById, {
    onSuccess: () => {
      handleSuccess();
      queryClient.invalidateQueries(queriesKeys.fetchCourtesies());
    },
    onError: handleError,
  });
  const mBlockTicketsById = useMutation(blockOrUnblockTicketsById, {
    onSuccess: () => {
      handleSuccess();
      queryClient.invalidateQueries(queriesKeys.fetchCourtesies());
    },
    onError: handleError,
  });

  if (isLoading) {
    return <LinearProgress />;
  }

  if (!isLoading && !canModifyTickets) {
    return (
      <div>
        Modificación de boletos no disponible hasta que el evento este
        confirmado
      </div>
    );
  }

  function handleSuccess() {
    setSelectedTickets([]);
    handleNotification(UI_MESSAGES.SUCCESS, 200);
    queryClient.invalidateQueries(queriesKeys.fetchGrid(ticketTypeId));
  }

  function handleError(error: any) {
    const message = formatHttpError(error);
    handleNotification(message, 400);
  }

  const selectedTicketType = ticketTypes.find(
    (ticketType) => ticketType.id === ticketTypeId
  );

  const handleChange = (ticketType: ITicketType) => {
    setTicketTypeId(ticketType.id!);
    setSelectedTickets([]);
  };

  const getGridContent = () => {
    if ((qGrid.isLoading && qGrid.isFetching) || qVenueUrl.isLoading) {
      return <LinearProgress />;
    }

    const ticketType = ticketTypes.find((item) => item.id === ticketTypeId);
    if (!ticketType) {
      return (
        <VenueDisplay
          ticketTypes={ticketTypes}
          venueUrl={qVenueUrl.data || ''}
          onSelectTicketType={handleChange}
        />
      );
    }

    if (!ticketType?.isNumbered) {
      const availableTickets = qGrid.data as NotNumberedTicketsTotals;
      return (
        <ActionsNotNumbered
          ticketTypeId={ticketType?.id!}
          availableTickets={availableTickets?.availableTickets}
          soldTickets={availableTickets?.soldTickets}
        />
      );
    }

    if (qGrid.data) {
      return (
        <div>
          {selectedTicketType && <h2>{selectedTicketType.name}</h2>}
          <GridModifyTicket
            existingGrid={qGrid.data as ITicket[][]}
            onTicketSelected={setSelectedTickets}
          />
        </div>
      );
    }
  };

  return (
    <>
      <Grid container flexDirection='column' rowSpacing={2}>
        <Grid item container flexDirection='column'>
          <Grid item container flexDirection='column'>
            <Card>
              <Typography component='p'>Instrucciones</Typography>
              <Typography component='p'>
                1. Dar click izquierdo para seleccionar 1 boleto, o dejar
                presionado el botón y arrastrar el cursor para seleccionar
                multiples boletos
              </Typography>
              <Typography component='p'>
                2. Dar click derecho para mostrar menú de reserva de boletos o
                actualización de precios
              </Typography>
              <p>Indicadores de estados</p>
              <div style={{ marginBottom: '10px' }}>
                {getColorsIndicatorsContent()}
              </div>
            </Card>
          </Grid>
        </Grid>
        <Grid item container>
          <Card
            style={{
              position: 'relative',
            }}
          >
            {ticketTypeId > 0 && (
              <div
                style={{
                  marginBottom: '10px',
                }}
              >
                <Button
                  variant='outlined'
                  onClick={() => {
                    setTicketTypeId(0);
                    setSelectedTickets([]);
                  }}
                >
                  Regresar al venio
                </Button>
              </div>
            )}
            {getGridContent()}
          </Card>
        </Grid>
      </Grid>
      <Modal
        open={!!selectedTickets.length}
        onClose={() => setSelectedTickets([])}
        aria-labelledby='update seats'
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
          }}
        >
          <ModifyTicketForm
            isLoading={
              mUpdatePrice.isLoading ||
              mReserveTicket.isLoading ||
              mGenerateCourtesyCode.isLoading ||
              mBlockTicketsById.isLoading
            }
            tickets={selectedTickets}
            onCloseForm={() => setSelectedTickets([])}
            onModifyPrice={(newPrice: number) => {
              mUpdatePrice.mutate({
                ticketsIds: selectedTickets.map((ticket) => ticket.id!),
                newPrice,
              });
            }}
            onReserve={(payload) => {
              mReserveTicket.mutate({
                ticketsIds: selectedTickets.map((ticket) => ticket.id!),
                reservedFor: payload.reservedFor,
              });
            }}
            onBlockTickets={() => {
              mBlockTicketsById.mutate({
                ticketsIds: selectedTickets.map((ticket) => ticket.id!),
                block: true,
              });
            }}
            onUnblockTickets={() => {
              mBlockTicketsById.mutate({
                ticketsIds: selectedTickets.map((ticket) => ticket.id!),
                block: false,
              });
            }}
            onGenerateCourtesyCode={(reservedFor: string) => {
              mGenerateCourtesyCode.mutate({
                ticketsIds: selectedTickets.map((ticket) => ticket.id!),
                reservedFor,
              });
            }}
          />
        </Box>
      </Modal>
    </>
  );
};

export default ModifyTickets;
