import { Grid, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { fetchSalesByType, queriesKeys } from '../../../http/Queries';
import { buildCurrency } from './TicketsStatusTable';

export const SalesByType = () => {
  const qSalesByType = useQuery(
    queriesKeys.fetchSalesByType(),
    fetchSalesByType
  );

  if (qSalesByType.isLoading && qSalesByType.isFetching) {
    return <div>Cargando...</div>;
  }

  if (!!qSalesByType.error) {
    return <div>Error inesperado</div>;
  }

  return (
    <Grid
      container
      direction='column'
      alignItems='center'
      justifyContent='center'
    >
      <Grid item container alignItems='center' justifyContent='center'>
        <Typography variant='h5' padding={0}>
          Ventas web:{' '}
        </Typography>
        <Typography variant='h5'>
          {buildCurrency(qSalesByType.data?.salesWeb)}
        </Typography>
      </Grid>
      <Grid item container alignItems='center' justifyContent='center'>
        <Typography variant='h5' padding={0}>
          Ventas Taquilla Total:{' '}
        </Typography>
        <Typography variant='h5'>
          {buildCurrency(qSalesByType.data?.salesBoxOffice)}
        </Typography>
      </Grid>
      <Grid item container alignItems='center' justifyContent='center'>
        <Typography variant='h5' padding={0}>
          Ventas Efectivo:{' '}
        </Typography>
        <Typography variant='h5'>
          {buildCurrency(qSalesByType.data?.salesBoxOfficeCash)}
        </Typography>
      </Grid>
      <Grid item container alignItems='center' justifyContent='center'>
        <Typography variant='h5' padding={0}>
          Ventas Tarjeta:{' '}
        </Typography>
        <Typography variant='h5'>
          {buildCurrency(qSalesByType.data?.salesBoxOfficeCard)}
        </Typography>
      </Grid>
    </Grid>
  );
};
