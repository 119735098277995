import { Grid } from '@mui/material';
import { Outlet } from '@tanstack/react-location';
import { useQuery } from '@tanstack/react-query';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Header from '../../components/Layout/Header';
import { fetchEventDetails, queriesKeys } from '../../http/Queries';
import { eventActions } from '../../store/event.slice';
import ResponsiveAppBar from './ResponsiveAppBar';

const Reports: React.FC = () => {
  const { data } = useQuery(queriesKeys.fetchEventDetails(), fetchEventDetails);
  const dispatch = useDispatch();

  useEffect(() => {
    if (data) {
      dispatch(eventActions.addEventDetails(data));
    }
  }, [data, dispatch]);

  return (
    <>
      <Header hasDrawer={false} />
      <Grid mt='65px'>
        <ResponsiveAppBar />
        <Outlet />
      </Grid>
    </>
  );
};

export default Reports;
