import React from 'react';
import { Grid } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { fetchSalesByDay, queriesKeys } from '../../http/Queries';
import { format } from 'date-fns';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Rectangle,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

type ChartType = {
  name: string;
  tickets: number;
};

const SalesByDay: React.FC = () => {
  const qSalesByDay = useQuery(queriesKeys.fetchSalesByDay(), fetchSalesByDay, {
    refetchInterval: 1000 * 10,
  });

  let mappedSticketsSold: Array<ChartType> = [];
  let mappedTotals: Array<ChartType> = [];

  if (qSalesByDay.isError) {
    return <div>Algo salio mal</div>;
  }

  if (qSalesByDay.isLoading) {
    return <div>Cargando...</div>;
  }

  if (qSalesByDay.data?.length) {
    mappedSticketsSold = qSalesByDay.data.map((item) => ({
      name: format(new Date(item.date), 'dd/MM/yyyy'),
      tickets: +item.ticketsSold,
    }));

    mappedTotals = qSalesByDay.data.map((item) => ({
      name: format(new Date(item.date), 'dd/MM/yyyy'),
      tickets: +item.total,
    }));
  }

  return (
    <Grid display='flex' flexDirection='column'>
      <h2>Ventas por diá </h2>
      <Grid display='flex' flexWrap='wrap'>
        <Grid width='100%' height={300}>
          <h3>Boletos vendidos</h3>
          {!!mappedSticketsSold.length && (
            <ResponsiveContainer width='100%' height='100%'>
              <BarChart data={mappedSticketsSold}>
                <CartesianGrid strokeDasharray='3 3' />
                <XAxis dataKey='name' />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar
                  name='Boletos vendidos'
                  dataKey='tickets'
                  fill='#8884d8'
                  activeBar={<Rectangle fill='pink' stroke='blue' />}
                />
              </BarChart>
            </ResponsiveContainer>
          )}
        </Grid>
        <Grid width='100%' height={300}>
          <h3>Totales</h3>
          {!!mappedTotals.length && (
            <ResponsiveContainer width='100%' height='100%'>
              <BarChart data={mappedTotals}>
                <CartesianGrid strokeDasharray='3 3' />
                <XAxis dataKey='name' />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar
                  name='Totales'
                  dataKey='tickets'
                  fill='#82ca9d'
                  activeBar={<Rectangle fill='pink' stroke='blue' />}
                />
              </BarChart>
            </ResponsiveContainer>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SalesByDay;
