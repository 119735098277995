import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  card: {
    display: 'flex',
    flexDirection: 'column',
    boxShadow: '0 7px 30px -10px rgba(150, 170, 180, 0.5)',
    borderRadius: '1rem',
    padding: '1rem 2rem',
    backgroundColor: 'white',
  },
}));

type CardProps = {
  title?: string;
};

const Card: React.FC<React.AllHTMLAttributes<HTMLDivElement> & CardProps> = ({
  title,
  children,
  ...props
}) => {
  const classes = useStyles();

  return (
    <div {...props} className={`${classes.card}`}>
      {title && <h2>{title}</h2>}
      {children}
    </div>
  );
};

export default Card;
